import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { SuggestionsService } from '../../../../services/suggestions.service'
import {Router} from "@angular/router";
import { debounceTime, filter, switchMap, tap, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
})
export class SearchComponent implements OnInit , OnDestroy {
  suggestions: any = null;
  searchText: string = 'Buscar';

  searchInput = new FormControl('');

  searchSub = new Subscription;

  constructor(
    private router: Router,
    private suggestionsService: SuggestionsService
  ) { }

  ngOnInit() {
    this.searchSub = this.searchInput.valueChanges
    .pipe(
      tap(r => {
        if (r.length < 4) this.suggestions = null;
      }),
      debounceTime(500),
      filter(q => q.length > 3),
      map(r => this.sanitize_string(r)),
      switchMap((q) => this.suggestionsService.init(q)
      ),
      tap(r => {
        this.suggestions = r;
      })
    )
    .subscribe();
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  searchCatalog() {
    this.router.navigate(['/catalog'], {queryParams: {query: this.sanitize_string(this.searchInput.value)}});
  }

  sanitize_string(string: string) {
    this.searchText = string.replace(/%/g, '');

    let a = string.replace(/á/g, 'a' );
    let A = a.replace(/Á/g, 'A' );
    let e = A.replace(/é/g, 'e' );
    let E = e.replace(/É/g, 'E' );
    let i = E.replace(/í/g, 'i' );
    let I = i.replace(/Í/g, 'I' );
    let o = I.replace(/ó/g, 'o' );
    let O = o.replace(/Ó/g, 'O' );
    let u = O.replace(/ú/g, 'u' );
    let U = u.replace(/Ú/g, 'U' );
    let enie = U.replace(/ñ/g, 'n' );
    let ENIE = enie.replace(/Ñ/g, 'N');
    let scape = ENIE.replace(/%/g, '' );

    return scape;

  }

  hideSuggestions() {
    this.suggestions = null
  }
}
