<swiper
      [spaceBetween]="5"
      [centeredSlides]="!mobile ? true : false"
      [slidesPerView]="!mobile ? 5 : 2"
      [slidesPerGroup]="1"
      [loop]="true"
      [resizeObserver]="true"
      [navigation]="true"
      class="variants-list"
      >


  <ng-container *ngFor="let variant of getImages(); let i = index">

    <ng-template swiperSlide class="swiperImg">
      <app-variant-card class="item"  [variant]="variant" [slug]="slug" [position]="i" ></app-variant-card>
    </ng-template>

  </ng-container>


</swiper>
