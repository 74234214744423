import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ModelService } from './model.service';
import { RelationService } from './model.service';
import { Bin } from '../models/models.model';

@Injectable()

export class BinesService extends ModelService<Bin> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'bines', Bin);
  }
}
