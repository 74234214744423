import { Injectable } from '@angular/core';

@Injectable()

export class PaginationService {
  pages = [];
  
  constructor() {}

  fetchPrev(current_page: number) {
    if (this.hasPrev(current_page)) {
      return {p: current_page - 1}
    }

    return null;
  }

  fetchNext(current_page: number, total_pages: number) {
    if (this.hasNext(current_page, total_pages)) {
      return {p: current_page + 1}
    }

    return null;
  }

  setPage(page: number) {
    return {p: page}
  }

  hasPrev(current_page: number) {
    return current_page > 1;
  }

  hasNext(current_page: number , total_pages: number) {
    return current_page < total_pages;
  }

  buildPages(current_page: number, total_pages: number) {
    this.pages = [];
    let i;

    if (current_page > 1) {
      for (i = current_page - 1; i > (current_page - 5); i--) {
        if (i >= 1) {
          this.pages.unshift({
            isPoint: false,
            page: i,
            currentPage: false,
          })
        }
      }

      if (i > 1) {

        if (i > 2) {
          this.pages.unshift({
            isPoint: true,
            page: null,
            currentPage: null,
          });
        }

        this.pages.unshift({
          isPoint: false,
          page: 1,
          currentPage: false,
        })
      }
    }

    // Show current and next 4 pages
    for (i = current_page; i < (current_page + 5); i++) {
      if (i <= total_pages) {
        this.pages.push({
          isPoint: false,
          page: i,
          currentPage: i === current_page,
        });
      }
    }

    // Check if there are remaining pages
    if (i < total_pages) {

      if (i < (total_pages - 1)) {
        this.pages.push({
          isPoint: true,
          page: null,
          currentPage: null,
        });
      }

      this.pages.push({
        isPoint: false,
        page: total_pages,
        currentPage: false,
      });
    }

    return this.pages;
  }

}
