import { Injectable } from '@angular/core';
// Services
import { HttpService } from './http.service';
import { PointsService } from './points.service';
import { CustomerService } from './customer.service';
import { RelationService } from './model.service';
import { LocalStorage } from './local_storage.service';
import { ProfileService } from './account/profile.service';

@Injectable({
  providedIn: 'root'
})
export class BancoCiudadService extends CustomerService {

  constructor(http: HttpService,
              localstorage: LocalStorage,
              pointsService: PointsService,
              profileService: ProfileService,
              relationService: RelationService,
    ) {
    super (http, localstorage, pointsService, profileService, relationService)
   }

  getHash(hash) {
    let parameters = {};

    hash.subscribe(params => { parameters = params });

    this.http.get('ciudad/hash_validator/validate', parameters)
      .subscribe(
        (response: any) => {
          console.log('le resposta', response)
            //this.userBna.setBnaUser(response)
            //this.setBnaCustomer();
        },
        (error) => {
            console.log(error);
        }
    )

  }
}
