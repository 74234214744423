//Angular
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
//Services
import { NewsletterService } from '../../../services/newsletter.service';
import { ProfileService } from '../../../services/account/profile.service';
//Models
import { Customer } from '../../../models/models.model';
//Others
import { environment } from '../../../environments/environment';
import { MktService } from '../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-updateprofile.dialog',
  templateUrl: './updateprofile.dialog.component.html',
  styleUrls: ['./updateprofile.dialog.component.sass']
})
export class UpdateProfileDialogComponent implements OnInit {

  customer: Customer = new Customer;
  profileForm: FormGroup;
  errors: any;
  store = environment.name;
  isFirstTime: boolean;
  tienda: string
  docTypes = ["DNI", "DU", "LC", "LE", "PAS", "CUIT", "CUIL"];
  success = false

  constructor(
    private _mktService: MktService,
    private profileService: ProfileService,
    private dialog: MatDialogRef<UpdateProfileDialogComponent>,
    ) { }


  ngOnInit() {

    (environment.name === "Macro") ? this.tienda = 'Macro Premia' : this.tienda = environment.name;

    if (environment.name === "Macro") {
      this.docTypes = ["DNI", "DU", "LC", "LE", "PAS"];
    }

    this.buildForm();

    this._mktService.setMetaTags('Mi cuenta de email registrada', { name: "description", content: 'Opciones para ver o modificar mi email asociado a mi cuenta.' });

    this.profileService.show().subscribe(
      (response) => {
        this.customer = response;
        this.profileForm.patchValue({
          'email': '',
          'doc_type': this.customer.doc_type ? this.customer.doc_type : 'DNI',
          'doc_number': this.customer.doc_number ? this.customer.doc_number : ''
        })
      }
    );
  }

   buildForm() {
    this.profileForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email,
      ]),

      'doc_number': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]{4,8}$/i),
        Validators.minLength(4),
        Validators.maxLength(8),
      ]),
      'doc_type': new FormControl('DNI', [
        Validators.required,
      ])

    });
  }

  submit() {
    if (this.profileForm.valid) {
      this.profileForm.disable();
      const {email, doc_type, doc_number} = this.profileForm.value
      this.customer.doc_type = doc_type
      this.customer.doc_number = doc_number
      this.customer.email = email

      this.profileService.edit(this.customer).subscribe(
        (response) => {
            this.customer.email = response.email;

            this.success = true
            setTimeout(() => {
              if(this.dialog) this.closeDialog()
            }, 2000);
        }
      );

      this.profileForm.enable();
    }
  }

  closeDialog() {
    this.dialog.close();
  }

}
