<!-- FOR LANDINGS -->
<div class="container" *ngIf="type === 'landing'">
  <div class="item w-100" id="skeleton-landing">
    <div class="row mb-2">

      <div class="col-12 px-2 d-none d-md-block skeleton-banner">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="col-12 col-md-6 px-2 skeleton-minibanner">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="col-12 col-md-6 d-none d-md-block px-2 skeleton-minibanner">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-none d-md-block col-md-3 px-2 skeleton-product">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-none d-md-block col-md-3 px-2 skeleton-product">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-none d-md-block col-md-3 px-2 skeleton-product">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-none d-md-block col-md-3 px-2 skeleton-product">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>

      <div class="d-block d-sm-none col-6 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>

      <div class="d-block d-sm-none col-6 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>

      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="d-block d-sm-none col-4 px-1 skeleton-product-mobile">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>

    </div>
  </div>
</div>

<!-- FOR CATALOG -->
<div class="container px-4" *ngIf="type === 'catalog'">
  <div class="item w-100" id="skeleton-catalog">
    <div class="row my-3">
      <div class="col-md-3 col-sm-12 skeleton-sidebar">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="col-md-9 col-sm-12 pt-3">
        <div class="row">
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="col-md-3 col-6 px-2 skeleton-product">
              <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- FOR PRODUCT LIST -->
<div class="container px-4" *ngIf="type === 'products'">
    <div class="item w-100" id="skeleton-catalog">
      <div class="row">
        <div class="col-md-12 col-sm-12 pt-2">
          <div class="row">
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
            <div class="col-md-3 col-6 px-2 skeleton-product">
                <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  </div>

<!-- FOR TABLES -->
<div class="item w-100" *ngIf="type === 'table'" id="skeleton-table">
  <div class="row mb-2">
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12">
      <ngx-skeleton-loader count="2" appearance=""> </ngx-skeleton-loader>
      <h6 class="text-center" style="background-color: #eff1f6; margin-top: -14px;">Cargando Pedidos</h6>
      <ngx-skeleton-loader count="4" appearance=""> </ngx-skeleton-loader>
    </div>
  </div>
</div>

<!-- FOR WISHLIST -->
<div class="item w-100" *ngIf="type === 'wishlist'" id="skeleton-wishlist">
  
  <div class="row mb-2">    
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-9 loader-text">
        <div class="w-100">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-75">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-50">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
    </div>
  </div>

  <div class="row mb-2">    
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-9 loader-text">
        <div class="w-100">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-75">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-50">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
    </div>
  </div>

  <div class="row mb-2">    
    <div class="col-3">
        <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
    </div>
    <div class="col-9 loader-text">
        <div class="w-100">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-75">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
        <div class="w-50">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
        </div>
    </div>
  </div>

</div>

<!-- FOR ADRRESS -->
<div class="item w-100" *ngIf="type === 'address'" id="skeleton-wishlist">
  
    <div class="row mx-0 mb-2">    
      <div class="col-3 px-0">
          <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
      </div>
      <div class="col-9 loader-text">
          <div class="w-100">
            <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="w-75">
            <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="w-50">
            <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
          <div class="w-75">
            <ngx-skeleton-loader count="1" appearance=""> </ngx-skeleton-loader>
          </div>
      </div>
    </div>
  
  </div>