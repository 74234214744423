<swiper
      [spaceBetween]="1"
      [centeredSlides]="false"
      [autoplay]="{
        delay: 3000,
        disableOnInteraction: false
        }"
      [slidesPerView]="!mobile ? 8 : 3"
      [slidesPerGroup]="1"
      [loop]="true"
      [resizeObserver]="true"
      [navigation]="true"
      class="mini-logo-component"
      >

  <ng-container *ngFor="let logo of getImages(); let i = index">

    <ng-template swiperSlide class="swiperImg">
      <a *ngIf="logo.image" (click)="navigate(logo)">
        <img class="coverImg" [src]="logo.image" alt="covering" />
      </a>
    </ng-template>

  </ng-container>


</swiper>
