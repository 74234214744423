import { HttpHeaders } from '@angular/common/http';

export class GatewayService {
  protected headers: HttpHeaders;
  private _apikey: string;
  private _endpoint: string;
  private _custom_js: string;

  constructor() { }

  get apikey(): string {
    return this._apikey;
  }

  set apikey(value: string) {
    this._apikey = value;
  }

  get endpoint(): string {
    return this._endpoint;
  }

  set endpoint(value: string) {
    this._endpoint = value;
  }

  dontRun() {
    return !this.endpoint || !this.apikey
  }

  get custom_js(): string {
    return this._custom_js;
  }

  set custom_js(value: string) {
    this._custom_js = value;
  }
}
