import {Subject} from "rxjs";
import {TermsState} from "../interfaces/terms_state.interface";
import { Injectable } from "@angular/core";

@Injectable()
export class TermsService {
  private termsSubject = new Subject<TermsState>();

  constructor() { }

  accept() {
    this.termsSubject.next(<TermsState>{accept: true});
  }

  reject() {
    this.termsSubject.next(<TermsState>{accept: false});
  }

  get() {
    return this.termsSubject;
  }
}
