import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BreadcrumbComponent} from "../app/layout/breadcrumb/breadcrumb.component";
import {RouterModule} from "@angular/router";

@NgModule({
  imports: [
    RouterModule,
    CommonModule
  ],
  exports: [
    CommonModule,
    BreadcrumbComponent,
  ],
  declarations: [
    BreadcrumbComponent
  ]
})

export class BreadcrumbModule { }
