//Angular
import {Router} from "@angular/router";
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, ElementRef, ViewChild, Inject } from '@angular/core';
//Service
import { SuggestionsService } from '../../../../services/suggestions.service'

@Component({
  selector: 'app-hidden-search-bar',
  templateUrl: './hidden-search.component.html',
  styleUrls: ['./hidden-search.component.sass'],
})
export class HiddenSearchComponent implements OnInit {
  @ViewChild('searcher', { static: true }) searcher: ElementRef;
  @ViewChild('searcherContainer', { static: true }) searcherContainer: ElementRef;
  hiddenSearchRef: ElementRef
  suggestions: any;
  hiddenSuggestions: any;

  constructor(private dialogRef: MatDialogRef<HiddenSearchComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { hiddenSearchRef: ElementRef },
              private router: Router,
              private suggestionsService: SuggestionsService) {
                this.hiddenSearchRef = data.hiddenSearchRef;
               }

  ngOnInit() {
    const matDialogConfig = new MatDialogConfig()
    const rect: DOMRect = this.hiddenSearchRef.nativeElement.getBoundingClientRect();
    const searchRect: DOMRect = this.searcherContainer.nativeElement.getBoundingClientRect();
    matDialogConfig.position = { top: `${rect.top - (rect.height / 2) }px`, left: `${rect.left - searchRect.width}px` }
    this.dialogRef.updatePosition(matDialogConfig.position);
  }

  searchCatalog() {
    this.router.navigate(['/catalog'], {queryParams: {query: this.sanitize_string(this.searcher.nativeElement.value)}});
    this.hideSuggestions();
    this.closeDialog();
  }

  sanitize_string(string: string) {
    return string.replace("%", "");
  }

  hideSuggestions() {
    this.hiddenSuggestions = this.suggestions;
    this.suggestions = null;
  }

  /*searchSuggestions(){
    if(!this.hiddenSuggestions) {
      var query = this.sanitize_string(this.searcher.nativeElement.value);
      if (query.length > 3){
        this.suggestionsService.init(query).subscribe(
          (response) => {
            this.suggestions = response;
          }
        );
      }else{
        this.suggestions = null;
      }
    }else{
      this.suggestions = this.hiddenSuggestions;
      this.hiddenSuggestions = null;
    }
  }*/

  closeDialog() {
    this.dialogRef.close();
  }
}
