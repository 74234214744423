<div class="container">
  <div class="featured-brands-cmpnt" *ngIf="component">
    <div class="users-with-cover">
      <article *ngFor="let brand of component.data.brands">
        <a href="">
          <div class="cover" [lazyLoad]="brand.profile.cover" [offset]="100">
            <span></span>
          </div>
          <div class="avatar-wrapper">
            <div class="avatar simple" [lazyLoad]="brand.profile.avatar" [offset]="100">
              <img [lazyLoad]="brand.profile.avatar" alt="" [offset]="100" alt="image brand">
              <div class="fallback">
                <span>{{ brand.profile.text }}</span>
              </div>
            </div>
          </div>
          <h2>{{ brand.full_name }}</h2>
        </a>
      </article>
    </div>
  </div>
</div>
