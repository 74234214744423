// Angular
import { Injectable } from '@angular/core';
// Angular Material
import { MatDialog } from '@angular/material/dialog';
// Utils
import { HttpService } from './http.service';
import { LocalStorage } from './local_storage.service';
import { BehaviorSubject } from 'rxjs';
// Components
import {GeolocationDialogComponent} from '../app/dialogs/geolocation/geolocation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  latitude: number
  longitude: number
  isLocated = new BehaviorSubject<boolean>(false);
  location: any = {}
  state = new BehaviorSubject<any>({});

  constructor(
    private localstorage:LocalStorage,
    private dialog:MatDialog,
    private http:HttpService,
  ) { }

  initGeolocation() {
    let is_located = this.localstorage.get('is_located');
    if (is_located) {
      let location = this.localstorage.get('location');
      this.state.next({located: true, location: location})
    }
  }

  getLocation(options?: PositionOptions): Promise<any> {
    return new Promise((resolve, reject) => 
        navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
  }

  setLocationLatLong(lat:number, long:number) {
    this.latitude = lat
    this.longitude = long
    this.localstorage.set('latitude', lat)
    this.localstorage.set('longitude', long)
  }

  setLocatedLocal() {
    this.localstorage.set('is_located', true)
    this.localstorage.set('location', this.location)
  }

  fireGeolocationPopUp(error?: boolean) {

    this.http.get('available_locations',null,false).subscribe(
      (response) => {
        var data = {
          available_locations:response,
          error: (error) ? error : false
        }
        const dialogRef = this.dialog.open(GeolocationDialogComponent, {
          width: '500px',
          height: 'auto',
          data: data
        });
        dialogRef.afterClosed().subscribe(() => {
          if (dialogRef.componentInstance.browserLocate) {
            this.locateUser();
          } else {
            let data = dialogRef.componentInstance.geolocationForm.value
            if (data.localidad) {
              this.location = {
                "city": data.localidad,
                "province": data.provincia
              }
              this.setLocatedLocal()
              this.state.next({located: true, location: this.location})
            }
          }
        })
      }
    )
  }

  locateUser() {
    this.getLocation()
      .then((position) => {
        let lat = position.coords.latitude
        let long = position.coords.longitude
        this.setLocationLatLong(lat,long)
        this.getLocationAPI(lat, long)
      })
      .catch((err) => {
        this.fireGeolocationPopUp(true)
      });
  }

  getLocationAPI(lat:number, long:number) {
    let params = {
      latitude: lat,
      longitude: long
    }
    
    this.http.get('current_location/', params, false).subscribe(
      (response) => {
        this.location = {
          "city": response.city,
          "province": response.province
        }
        this.isLocated.next(true);
        this.setLocatedLocal();
        this.state.next({located: true, location: this.location})

      }, (error) => {
        this.fireGeolocationPopUp(true)
      }
    )
  }

}
