<section id="section_login" class="login-block login-block-desktop">
  <div class="container py-5">
    <div class="row mx-2">

      <div class="col-sm-12 col-md-4" [ngClass]="{ 'offset-md-4': !env.register_form, 'offset-md-2': env.register_form }">
        <h4>{{ checkTitle() }}</h4>
        <div app-login (isRecovery)="checkIsRecovery($event)"></div>
      </div>

      <div class="border-right mx-5" data-context-show="sign-up-input"  *ngIf="env.register_form"></div>

      <div class="col-sm-12 col-md-4" *ngIf="env.register_form">
        <h4>Crear cuenta</h4>

        <div app-register ></div>

      </div>

    </div>
  </div>
</section>
