import { Banner } from '../../../../../interfaces/banner.interface';
import { ComponentLanding } from "../../../../../models/models.model";
import { Component, Input, OnInit, HostListener } from '@angular/core';
import { UrlService } from "../../../../../services/url.service";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation, Swiper } from "swiper/core";
import { MktService } from '../../../../new-core/core/services/mkt.service';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation]);
@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.sass']
})

export class HeroComponent implements OnInit {
  component: ComponentLanding;
  mobile: boolean = false;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'hero';
  }

  constructor(
    private _urlService: UrlService,
    private _mktService: MktService
  ) { }

  ngOnInit(): void {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }

  getImages(){
    let banners: Banner[] = []
    this.component.data.banners.forEach((element) => {
      if (element.image || element.mobile_image) banners.push(element);
    });
    return banners
  }

  navigate(item) {
    this._mktService.collect('CLICK-IN-BANNER', item);
    this._urlService.navigate(this._urlService.linkSelector(item));
  }

}
