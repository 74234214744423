import {Component, OnInit, HostListener} from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";
import * as _ from '../../../../../assets/javascript/slider';
import {UrlService} from "../../../../../services/url.service";
import { MktService } from '../../../../new-core/core/services/mkt.service';

declare const lory: any;

@Component({
  selector: 'app-hero-banner',
  templateUrl: './hero-banner.component.html',
  styleUrls: ['./hero-banner.component.sass']
})
export class HeroBannerComponent implements OnInit {
  component: ComponentLanding;
  mobile: boolean = false;
  slides: any[] = [];

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'hero_banner';
  }

  constructor(
    private _urlService: UrlService,
    private _mktService: MktService
  ) { }

  ngOnInit() {
    this.mobile = window.innerWidth >= 425 ? false : true;
    this.component.data.slides.forEach(element => {
      if (element.image || element.mobile_image) this.slides.push(element);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }

  navigate(item) {
    this._mktService.collect('CLICK-IN-BANNER', item);
    this._urlService.navigate(this._urlService.linkSelector(item));
  }
}
