import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FloatParsePipe } from './float-parse.pipe';

@NgModule({
  declarations: [
    FloatParsePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FloatParsePipe
  ]
})
export class FloatParseModule { }
