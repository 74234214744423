<div class="cart">
  <h3 class="empty-msg" *ngIf="loaded && checkoutCart && checkoutCart.noItems()">Tu carrito se encuentra vacío.</h3>
  <h2 *ngIf="checkoutCart !== undefined">Tu carrito</h2>

  <div class="list" *ngIf="loaded && !checkoutCart.noItems()">
    <div *ngFor="let item of checkoutCart.checkout_items" [attr.data-item-id]="item.variant.id" class="item item-variant-{{ item.variant.id }}">
      <div class="img" [ngStyle]="{backgroundImage: 'url(' + item.variant.picture_thumb + ')'}">
        <span></span>
      </div>
      <div [attr.data-tracking-id]="store === 'BancoNacion' ? 'boton-eliminar-producto' : null" class="i-icon-trash-bin" (click)="removeVariant(item)"></div>
      <div class="title"><a [routerLink]="item.product.getUrl()">{{ item.title }}</a></div>
      <div class="attrs">
        <ng-container *ngFor="let property of item.product.available_properties">
          <div *ngIf="property !== 'noproperty'">
            {{ property }} {{ isObject(item.variant.properties[p]) ? item.variant.properties[p]["name"] : item.variant.properties[p] }}
          </div>
        </ng-container>
      </div>
      <div class="qty">Cantidad: <span class="val">{{ item.quantity }}</span></div>
      <div class="buy-info">
        <div class="price">
          <span *ngIf="item && item.points > 0">Total puntos: <strong>{{ floatParser(item.points) }}</strong></span><br>
          <span>Total pesos: $<strong>{{ floatParser(item.total) }}</strong></span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="installmentsError && store !== 'BancoColumbia'" class="alert alert-info">
    <button type="button" class="mfp-close" (click)="closeInstallmentMessege()"><i class="fa fa-times"></i></button>
    <p>Al haber realizado una compra de productos con diferente financiación, el sistema tomará el menor número de cuotas para el pago final de la compra.</p>
    <p>¡Te recomendamos hacer compras separadas!</p>
  </div>
  <div *ngIf="installmentsError && !checkoutCart.noItems() && store != 'Macro'" class="alert alert-info">
    <button type="button" class="mfp-close" (click)="closeInstallmentMessege()"><i class="fa fa-times"></i></button>
    <p>Dependiendo la combinación de productos en tu carrito podrías no visualizar todos los planes de cuotas. En caso de buscar un plan espécifico podrá con un solo producto</p>
  </div>
  <div class="actions" *ngIf="loaded && !checkoutCart.noItems()">
    <div class="total">
      <span class="text">Total pesos:&nbsp;</span><span class="value">${{ floatParser(checkoutCart.subTotal()) }}</span><br>
      <span class="text" *ngIf="subTotalPoints > 0">Total puntos:&nbsp;</span><span *ngIf="subTotalPoints > 0" class="value">{{ floatParser(subTotalPoints) }}</span>
    </div>
    <div class="col-md-12 mt-2">
      <button [attr.data-tracking-id]="store === 'BancoNacion' ? 'boton-continuar-comprando' : null" class="btn btn-outline-primary mr-2" (click)="closeDialog()">Continuar Comprando</button>
      <button [attr.data-tracking-id]="store === 'BancoNacion' ? 'boton-finalizar-compra' : null" class="btn btn-primary" (click)="goToCheckout()">Finalizar Compra</button>
    </div>
  </div>
  <button title="Close (Esc)" type="button" class="mfp-close" (click)="closeDialog()"><i class="fa fa-times"></i></button>
  <mat-progress-spinner
    *ngIf="!loaded"
    color="accent"
    mode="indeterminate">
  </mat-progress-spinner>
</div>
