import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";

@Component({
  selector: 'app-cols2-generic-v1',
  templateUrl: './cols2-generic-v1.component.html'
})
export class Cols2GenericV1Component implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols2_generic_v1';
  }

  constructor() {}

  ngOnInit() { }
}
