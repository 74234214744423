<div class="jumbotron mb-0 align-middle" style="height: 60vh;">
  <div class="container py-5">
    <div class="row">
      <div class="col-md-7 col-sm-12 offset-md-2">
        
        <h1 class="mb-2">Su sesión en {{site_title}}<br> ha expirado</h1>
        <p class="lead mb-4 text-muted" style="line-height: 30px;">Ha expirado su tiempo de sesión en {{site_title}}, ingresá nuevamente para continuar.</p>
        
          <div class="btn-group">
            <button class="btn btn-primary px-4" (click)="redirectToLogin()" role="button">Iniciar sesión</button>
            <button class="btn btn-link text-muted" (click)="backToHome()" role="button">Volver al inicio</button>
          </div>
        
      </div>
    </div>
  </div>
</div>