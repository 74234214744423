import { FormControl, Validators } from '@angular/forms';
import {
  Component, ComponentFactoryResolver, OnInit,
  ViewEncapsulation
} from '@angular/core';

import {ActivatedRoute, Params, Router} from "@angular/router";
import { OrdersService } from "../../services/orders.service";
import { environment } from '../../environments/environment';
import { first, tap } from 'rxjs/operators';
import { MktService } from '../new-core/core/services/mkt.service';

@Component({
  selector: 'app-home',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class TrackingComponent implements OnInit{
  trackingNumber = new FormControl('', { validators: [Validators.required, Validators.minLength(3)] });
  error: string;
  data: any;
  env = environment;
  public isLoading = false;

  constructor(private ordersService: OrdersService,
              private _mktService: MktService,
              private route: ActivatedRoute) {  }

  ngOnInit() {
    this.readParam();
  }

  readParam(){
    this.route.params
    .pipe(
      first(),
      tap((params: Params) => {
        if ( params['tracking_number'] ) {
          this.trackingStatus(params['tracking_number']);
        }
      })
    ).subscribe()
  }

  trackingStatus(trackingNumber: string){
    console.log(trackingNumber);
    this.isLoading = true;
    this.data = null;
    this.error = null;
    this._mktService.setMetaTags('tracking');
    this.ordersService.tracking(trackingNumber).subscribe(
      (response) => {
        if (response.error) {
          console.log(response.error)
          this.error = response.error;
          this.data = null;
        } else {
          this.error = null;
          this.data = response;
        }
        this.isLoading = false
      },
      (error) => {
        console.log(error.error.error);
        this.error = error.error.error;
        this.isLoading = false
      })
  }

}
