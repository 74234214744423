//Angular
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-terms-of-services',
  templateUrl: './terms-of-services.component.html',
  styleUrls: ['./terms-of-services.component.sass']
})
export class TermsOfServicesComponent implements OnInit {
  termsOfUseForm: FormGroup
  textSize: number;
  termsOfUseText: String;
  error: string;


  constructor(private dialogRef: MatDialogRef<TermsOfServicesComponent>) { }

  ngOnInit() {
    this.termsOfUseText = "zxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtytzxcbnmasdoasefgbn,kcvo;hasdfvo;hsvkhasvhoas;dvjhasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfasdfqwerehyujetyuadfhtyt";
    this.formCreator();
  }

  formCreator() {
    this.termsOfUseForm =  new FormGroup({
      'termsText': new FormControl(this.termsOfUseText, [
      ]),
      'doAccept': new FormControl(null, [
        Validators.required,
      ]),
    });
  }

  submit() {
    this.clearErrors();
    if(this.termsOfUseForm.valid) {
      this.closeDialog()
    } else {
      this.error = "Debe aceptar lso terminos y condiciones de uso para loguearse."
    }
  }

  clearErrors() {
    if (this.error) this.error = null;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
