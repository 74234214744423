import { QualtricsDataService } from './../../../services/third-party/qualtrics-data.service';
import { distinctUntilChanged, concatMap, findIndex } from 'rxjs/operators';
//Angular
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
//Models
import { Store } from "../../../models/models.model";
//Services
import { AerolineasArgentinasService } from '../../../services/aerolineas-argentinas.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { RelationService } from '../../../services/model.service';
import { CustomerService } from '../../../services/customer.service';
import { ProfileService } from '../../../services/account/profile.service';
import { PointsService } from '../../../services/points.service';
import { StoreService } from '../../../services/store.service';
import { MacroService } from '../../../services/macro.service';
import { UrlService } from '../../../services/url.service';
//Other
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { MktService } from '../../new-core/core/services/mkt.service';
import { filter, first, tap } from 'rxjs/operators';
import { ProgramaVolarService } from '../../../services/programa-volar.service';

@Component({
  selector: 'app-programa-volar',
  templateUrl: './programa-volar.component.html',
  styleUrls: ['./programa-volar.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class ProgramaVolarComponent implements OnInit, OnDestroy {
  macroLogo = '/assets/stores/current_store/images/logo-macropremia.png';
  confirmEmail = new FormControl(null, [Validators.required, Validators.email]);
  selectMilesGroup: FormGroup;

  transaction: boolean;
  processData: boolean;
  buttonLoader: boolean;
  multiplo = true;
  availablePoints: number;

  docTypes = [
    {slug: 'DNI', value: 'DNI'},
  ];

  docTypesAerolineas = [
    {slug: 'DNI', value: 'DNI'},
    {slug: 'PASAPORTE', value: 'PAS'},
    {slug: 'PASAJERO FRECUENTE', value: 'SOC'}
  ];

  fecha: Date;

  error: string | null;
  email: string;
  socio: string;
  nombre: string = "";
  documento: string;
  operacion: string;

  public store: Store;
  private logged = false;
  public ready = false;

  close = false;

  private _subs: Subscription[] = []

  //Programa Volar
  bannerUrl = '/assets/stores/current_store/images/programa-volar-logos/banner-desktop.svg'
  arrayAirlines : any[] = [];
  arrayAirlinesTransform : any[] = [];

  isSpinnerCardsActivate: boolean = true

  nameAirlineSelected: string = '';

  airlineSelected = {
    id: 0,
    image_url: '',
    legal_terms: '',
    miles_equivalent: 0,
    name: '',
    points_equivalent: 0,
    disclaimer: ''
  }

  airlineNotSelected: boolean = true;
  userInvalid: boolean = false;

  membership_number_socio: string;
  needFidelixationNumber: boolean = false;
  confirmFidelixationNumber = new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]*$/), Validators.minLength(9), Validators.maxLength(10)]);

  constructor(private router: Router,
              private _formBuilder: FormBuilder,
              private storeService: StoreService,
              private macroService: MacroService,
              private _mktService: MktService,
              private customerService: CustomerService,
              private profileService: ProfileService,
              private pointsService: PointsService,
              private authenticationService: AuthenticationService,
              private _aerolineasArgentinasService: AerolineasArgentinasService,
              private _programaVolarService: ProgramaVolarService,
              private _qualtricsDataService: QualtricsDataService) {

              }

  ngOnInit() {

    this.checkHour();
    this.setStore();

    this._mktService.setMetaTags('Programa Volar');

    this.getData();

  }

  checkHour(){
    let date = new Date();
    let hour = date.getHours();

    if( Number( hour ) <= 6 ) {
      this.close = true;
      this.airlineNotSelected = false;
    }else{
      this.close = false;
    }
  }

  setStore() {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    if (environment.name === 'Macro') {
      this._subs.push(
        this.storeService.getStore()
        .pipe(
          tap((store) => {
            this.store = store;

            this.setConfig();

            if (isLoggedIn) this.macroSessionCheck();

          }))
        .subscribe()
      )
    }

  }

  macroSessionCheck() {

    this._subs.push(
      this.profileService.show()
      .pipe(
        tap((customer) => {
          if (customer.temporary_email || !customer.doc_number) this.macroService.updateProfile();
        }),
        concatMap(() => this.macroService.sessionStatus()),
        tap((valid) => valid ? this.logged = true : this.router.navigate(['session-expired']))
      )
      .subscribe()
    )

  }

  setConfig() {
    this.availablePoints = this.pointsService.getPoints();
    this.processData = false;
    this.formsGenerator();
  }

  formsGenerator() {

    this.selectMilesGroup = this._formBuilder.group({
      selectPoints: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[1-9][0-9]*$/),
        this.logged ? Validators.max(+this.pointsService.getPoints()) : Validators.required,
        //Validators.min(this.store.miles_equivalent) // EDIT MIN INPUT MACRO
      ]),
      selectDocType: new FormControl('DNI', [
        Validators.required
      ]),
      selectAerolineasPlus: new FormControl(null, [
        Validators.required,
        Validators.minLength(6)
      ])
    });

    this.ready = true;
  }

  plurals(word: string, quantity: number){
    return quantity > 1 ? word+'S' : word;
  }

  pointsToMiles(): number {
    const points = this.selectMilesGroup.get('selectPoints')?.value;

    return Math.floor(
      points > 0
        ? (points / this.airlineSelected.points_equivalent) * this.airlineSelected.miles_equivalent
        : 0
      );
  }

  getParams() {
    const params = {
      docType: this.selectMilesGroup.get('selectDocType')?.value,
      docNumber: this.selectMilesGroup.get('selectAerolineasPlus')?.value,
      email: this.confirmEmail.value,
      points: +this.selectMilesGroup.get('selectPoints')?.value,
      airline_id: this.airlineSelected.id
    }
    return params;
  }



  userValidator(stepper: MatStepper) {
    this.buttonLoader = true;
    this.error = null;
    if (this.logged) {
      this._subs.push(
        this._programaVolarService.getClient(this.getParams())
        .pipe(
          tap((response) => {
            if (response[0].lv_cod_error === 1){
              this.nombre = (response[0].lv_nombre === '') ? '' : (response[0].lv_nombre + ' ' + response[0].lv_apellido);
              switch(this.selectMilesGroup.get('selectDocType')?.value){
                case 'SOC': {
                  this.documento = response[0].lv_nume_doc;
                  break;
                }
                case 'DNI': {
                  this.documento = this.selectMilesGroup.get('selectAerolineasPlus')?.value;
                  break;
                }
                case 'PAS': {
                  this.documento = this.selectMilesGroup.get('selectAerolineasPlus')?.value;
                  break;
                }
              }
              if(response[0].membership_number){
                this.membership_number_socio = response[0].membership_number;
                this.needFidelixationNumber = false;
              }else{
                this.needFidelixationNumber = true;
              }
              stepper.next();
              this.airlineNotSelected = false;
            }else {
              this.error = response[0].lv_mensaje_error;
              this.showMsgUserInvalid();
            }
            this.buttonLoader = false;
          },
          (error) => {
            this.buttonLoader = false
            this.showMsgUserInvalid();
          }
          )
        )
        .subscribe()
      )
    } else {
      this.macroService.redirectToLogin();
    }

  }

  getParamsAcreditMiles() {
    let mermbership_number_params_value = '';
    if(this.needFidelixationNumber){
      mermbership_number_params_value = this.confirmFidelixationNumber.value.trim();
    }else{
      mermbership_number_params_value = this.membership_number_socio;
    }
    const params = {
      docType: 'SOC',
      docNumber: this.selectMilesGroup.get('selectAerolineasPlus')?.value,
      email: this.confirmEmail.value,
      points: +this.selectMilesGroup.get('selectPoints')?.value,
      airline_id: this.airlineSelected.id,
      membership_number: mermbership_number_params_value,
    }
    return params;
  }


  acreditMiles(stepper: MatStepper) {

    if (this.confirmEmail.valid) {
      stepper.next();
      this.processData = false;
      this._subs.push(
        this._programaVolarService.acredit(this.getParamsAcreditMiles())
        .pipe(
          tap(
            (response) => {
              this.processData = true;
              if(response.success){
                this.error = null;
                this.email = this.confirmEmail.value;
                this.fecha = new Date();
                this.operacion = response.order_id;
                this.pointsService.updatePoints(-this.selectMilesGroup.get('selectPoints')?.value)

                //Send event to Google Analytics
                this._mktService.collect('MILLAS-AEROLINEAS-PLUS', {millas_aerolineas_data: this.getParamsAcreditMiles()} )
                //Añadir evento Qualtrics
                this._qualtricsDataService.concatRecarga(`MILLAS-${this.airlineSelected.name.toUpperCase()}-MP-OK`);
              } else {
                this.error = response.message;
                this.processData = true
              }
            },
            (error) => {
              this.error = "No fue posible completar la transacción"
              this.processData = true
              //Añadir evento Qualtrics
              this._qualtricsDataService.concatRecarga(`MILLAS-${this.airlineSelected.name.toUpperCase()}-MP-FAIL`);
            })
        )
        .subscribe()
      )

    }

  }

  restart(stepper: MatStepper){
    this.error = null;
    this.airlineNotSelected = true;
    stepper.reset()
  }

  cleanInvalidCharacter(event: KeyboardEvent){

    if (['.',',','e','E', '+', '-'].includes(event.key)){

      event.preventDefault();

    }

    const valuePoints = String(this.selectMilesGroup.get('selectPoints')?.value);

    if(valuePoints !== valuePoints.replace(/^(0+)/g, '')){
      event.preventDefault();
    }

  }

  pointsValidator(event?){

    if (this.selectMilesGroup.get('selectPoints')?.value < 0 || this.selectMilesGroup.get('selectPoints')?.value === 0) {
      this.selectMilesGroup.patchValue({'selectPoints': 0});
    }

    if (this.selectMilesGroup.get('selectPoints')?.value % this.airlineSelected.points_equivalent != 0) {
      this.multiplo = false
      let miles = Math.floor(this.selectMilesGroup.get('selectPoints')?.value/this.airlineSelected.points_equivalent)*this.airlineSelected.points_equivalent;
      this.selectMilesGroup.patchValue({'selectPoints': miles});
    }else{
      this.multiplo = true;
    }

    if(this.logged) {
      const points = this.selectMilesGroup.get('selectPoints')?.value;
      if (points >= this.availablePoints){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': this.availablePoints});
      }
      if (points < 0){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': 0});
      }
    }
  }

  ngOnDestroy() {

    this._subs.forEach( sub => sub.unsubscribe());

  }

  backToForm(stepper: MatStepper) {
    stepper.previous();
    this.airlineNotSelected = true;
  }

  trackByItems(idenx: number, item: any): number {return item.id};

  getData(){
    this._programaVolarService.getAirlines().subscribe(
      res => {
        this.arrayAirlines = res.airlines;
        this.transformData();
        if (res.background_url) this.bannerUrl = res.background_url;
      },
      error => {
        console.error(error)
      }
    )
  }

  transformData(){
    this.arrayAirlinesTransform = this.arrayAirlines.map((airline:any, index) => {
      if(index === 0) {
        this.setFirstAirline(airline);
        return {...airline, is_activated: true}
      }
      return {...airline, is_activated: false}
    })
    this.isSpinnerCardsActivate = false;
  }

  selectAirline(id: number){

    if(this.airlineNotSelected){
      this.arrayAirlinesTransform = this.arrayAirlinesTransform.map( (airline:any, index) =>{
        if(id === index){
          airline.is_activated = true;
          this.airlineSelected = airline;
          return {...airline}
        }else{
          airline.is_activated = false;
          return {...airline}
        }
      })
    }

    this.selectMilesGroup.patchValue({'selectAerolineasPlus': null});
    this.selectMilesGroup.patchValue({'selectDocType': 'DNI'});

  }

  setFirstAirline(airline: any){
    this.airlineSelected = airline;
    this.nameAirlineSelected = airline.name;
  }

  showMsgUserInvalid(){
    this.userInvalid = true;
    this.selectMilesGroup.patchValue({'selectAerolineasPlus': null});
    this.selectMilesGroup.patchValue({'selectDocType': 'DNI'});
    setTimeout(()=>{
      this.userInvalid = false;
    },5000)
  }

  goHome(){
    this.router.navigate(['/'])
  }

}
