import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";
import {UrlService} from "../../../../../services/url.service";

@Component({
  selector: 'app-cols4-links-v1',
  templateUrl: './cols4-links-v1.component.html'
})
export class Cols4LinksV1Component implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols4_links_v1';
  }

  constructor(private urlService: UrlService) { }

  ngOnInit() {
  }

  navigate(link) {
    this.urlService.navigate(link);
  }
}
