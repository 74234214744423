<main id="errors-show">
  <div class="error-box container"><p class="background-error">404</p>
    <h1>{{ env.name === 'Macro' ? 'No hay resultados para la búsqueda ingresada' : '¡Ups! nada por aquí'}}</h1>
    <p class="description">Puedes realizar una nueva búsqueda o conocer alguno de los siguientes productos</p>
    <div class="offers" *ngIf="catalog">
      <section class="catalog-content">
        <div class="variants-list d-block">
          <div class="row">
            <div class="col-md-3 col-6 px-1 mb-2" *ngFor="let variant of catalog.variants; let i = index">
              <app-variant-card [variant]="variant"></app-variant-card>
            </div>
          </div>
        </div>
      </section>
    </div>
    <a routerLink="/" class="home-button">Ir al inicio</a>
  </div>
</main>
