<div class="banners horizontal">
  <div class="banners vertical">
    <app-image [item]="component.data['items'][0]" appRatio="620:214"></app-image>
    <div class="separator"></div>
    <app-image [item]="component.data['items'][1]" appRatio="620:214"></app-image>
  </div>
  <div class="separator"></div>
  <div class="banners vertical">
    <app-image [item]="component.data['items'][2]" appRatio="620:214"></app-image>
    <div class="separator"></div>
    <app-image [item]="component.data['items'][3]" appRatio="620:214"></app-image>
  </div>
</div>
