<div class="main-attachment" [ngStyle]="{backgroundImage: 'url(' + store.popup_image + ')'}">
  <span class="close-me-maybe" (click)="closeDialog()">
    X
  </span>
  <div *ngIf="this.store.name == 'avenida'">
    <input #email type="text" placeholder="Dejanos tu e-mail" *ngIf="!code">
    <div class="friendly-code" *ngIf="code">
      Cupón: <b>{{code}}</b>
      <p>
        El cupón tiene 15 días de vigencia, copialo y aprovechalo en tu próxima compra.
      </p>
    </div>
    <div class="friendly-button" (click)="saveNewsletter()" *ngIf="!code">
      ❯ Quiero mi cupón
    </div>
  </div>
</div>
