<app-loader></app-loader>
<app-header *ngIf="!isBnaXml"></app-header>

<!-- The whole content of the application -->
<main>
  <router-outlet></router-outlet>
</main>
<ng-template #spinner>
  <div class="text-center">
    <mat-progress-spinner
      color="accent"
      mode="indeterminate">
    </mat-progress-spinner>
  </div>
</ng-template>

<!-- Printable footer -->
<app-footer *ngIf="!isBnaXml"></app-footer>
