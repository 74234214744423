import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html'
})
export class DefaultComponent implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return true;
  }

  constructor() { }

  ngOnInit() {
  }

}
