// Angular
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-geolocation-dialog',
  templateUrl: './geolocation-dialog.component.html',
  styleUrls: ['./geolocation-dialog.component.sass']
})
export class GeolocationDialogComponent implements OnInit {

  geolocationForm: FormGroup;
  browserLocate: boolean
  provincias = []
  localidades = []

  constructor(
    private formBuilder:FormBuilder,
    private dialogRef: MatDialogRef<GeolocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.buildForm()
    if (this.data.available_locations) {
      Object.entries(this.data.available_locations.locations).forEach(([key, value]) => {
        this.provincias.push(key)
      });
    }
  }

  buildForm() {
    this.geolocationForm = this.formBuilder.group({
      provincia: ['', [
        Validators.required
      ]],
      localidad: ['', [
        Validators.required
      ]],
      browserLocate: [false],
    });

    this.geolocationForm.valueChanges.subscribe( (val) => {
      if (val.browserLocate) {
        this.browserLocate = val.browserLocate;
        this.geolocationForm.reset()
        this.dialogRef.close();
      }
    });
  }

  setLocadidades(provincia) {
    this.localidades = []
    this.geolocationForm.patchValue({localidad:''})
    this.data.available_locations.locations[provincia].forEach(localidad => {
      this.localidades.push(localidad)
    });
  }

  confirmGeolocation() {
    this.dialogRef.close()
  }

}
