import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, tap } from 'rxjs/operators';
import { LocalStorage } from '../../../../services/local_storage.service';
import { Router } from '@angular/router';

declare var ModoSDK: any;

@Injectable({
  providedIn: 'root'
})
export class ModoService {

  private _mostrarPagoModoSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mostrarPagoModo$ = this._mostrarPagoModoSource.asObservable();

  private _isSelectedModoSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSelectedModo$ = this._isSelectedModoSource.asObservable();

  private _spinnerIsActivated: BehaviorSubject<boolean> = new BehaviorSubject(false)
  spinnerIsActivated$ = this._spinnerIsActivated.asObservable();

  isModoDistributed: boolean = false;
  distributedText: string = '';

  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    protected localstorage: LocalStorage,
    private _router: Router,
  ) { }

  setMostrarPagoModo (value: boolean){
    this._mostrarPagoModoSource.next(value);
  }

  setIsSelectedModo (value: boolean){
    this._isSelectedModoSource.next(value);
  }

  setSpinnerActivate (value: boolean){
    this._spinnerIsActivated.next(value);
  }

  setIsModoDistributed (value: boolean){
    this.isModoDistributed = value;
    if (this.isModoDistributed) this.distributedText = 'distributed';
  }

  getHeaders() {
    const auth_token = this.localstorage.get('auth_token');
    const headerObject = {
      'Content-Type': 'application/json',
      'Api-Key': environment.token,
    };
    if (auth_token) {
      headerObject['auth-token'] =  auth_token.toString();
    }
    this.headers = new HttpHeaders(headerObject);
  }

  openModal(purchaseId: string){
    this.localstorage.set('purchase_id', purchaseId);
    this.setSpinnerActivate(true);
    this.getHeaders();

    this.http.post(`${environment.endpoint}/checkout/payment_intent/modo${this.distributedText}`, {purchase_id: purchaseId }, {headers: this.headers})
    .pipe(
      tap((res: any) => {
        if(res.error){
          this.goToModoError();
        }
      }),
      filter((res: any) => !res.error),
      map((res: any) => {
        const orderId = res.order;
        return {
          qrString: res.payment.gateway.qr,
          checkoutId: res.payment.gateway.id,
          deeplink: {
            url: res.payment.gateway.deeplink,
            callbackURL: environment.name === 'BancoNacion' ? `${environment.domain}/checkout/modo-error-mobile` : `${environment.domain}/checkout/modo-error`,
            callbackURLSuccess: environment.name === 'BancoNacion' ? `${environment.domain}/checkout/modo-done-mobile` : `${environment.domain}/checkout/modo-done`,
          },
          onSuccess: function () { console.log('onSuccess'); window.location.href = `${environment.domain}/checkout/modo-done?order=${orderId}`},
          onFailure: function () { console.log('onFailure'); window.location.href = `${environment.domain}/checkout/modo-error`; },
          onCancel: function () { console.log('onCancel') },
          refreshData: function() {console.log('refreshData')},
          //callbackURL: `${environment.domain}/checkout/modo-done`,
          //callbackURL: `http://localhost:4200/checkout/modo-done`,
          }
      }),
      tap(modalObject => {
        this.setSpinnerActivate(false);
        ModoSDK.modoInitPayment(modalObject);
      }),
    )
    .subscribe()
  }

  processPoints(purchaseLocal: string){
    this.getHeaders()
    return this.http.post(`${environment.endpoint}/checkout/process_points`, {purchase_id: purchaseLocal }, {headers: this.headers});
  }

  goToModoError(){
    this._router.navigate(['/checkout/modo-error']);
  }

}
