import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Store} from "../models/models.model";
import { first, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()

export class StoreService {
  private _storeSource: BehaviorSubject<Store> = new BehaviorSubject(new Store());

  constructor(private httpService: HttpService) {}

  gateway() {
    return this.httpService.get('stores/gateway');
  }

  currentStore(): Observable<Store>{
    return this.httpService.get('stores/current_store')
    .pipe(
      first(),
      tap((store: Store) => { this.setStore( store) })
    )
  }

  setStore(store: Store) {
    this._storeSource.next(store);
  }

  getStore(): Observable<Store> {
    return this._storeSource.asObservable();
  }

}
