
export const environment = {
  production: true,
  /* API */
  token: 'u28F65NXGP4M-_wuDSNswQ',
  endpoint: 'https://api-columbia.avenida.com/api/angular_app',
  session: "23de1a9be3f7004c999d6197b40555a8e9a71dbe08f369cf231defca9a19f7e88e57eab99e92634d139f788064e4e7da37383d128b1f95569f34e5c5bedec86a",
  /* Site */
  site_title: 'TiendaColumbia.com.ar',
  name: 'BancoColumbia',
  domain: 'tiendacolumbia.com.ar',
  friendly_domain: 'TiendaColumbia.com.ar',
  email: 'ayuda@tiendacolumbia.zendesk.com',
  phone: '0800-222-0224',
  whatsapp: null,
  gateway: "Decidir",
  cft: "0.00%",
  checkout_image: null,
  cards: true,
  /* Promos */
  cybermonday: false,
  hotsale: false,
  /* Transaction */
  cart: true,
  oneClick: true,
  installments: 24,
  taxAddress: false,
  google_conversion_id: null,
  google_conversion_label: "",
  google_remarketing_only: false,
  facebook_app_id: "",
  installments_without_interest: true,
  dataFiscal: 'http://qr.afip.gob.ar/?qr=j1a_dsbQ8Avo7KZjasWEvw,,',
  regret_buy: 'https://tiendacolumbia.zendesk.com/hc/es/requests/new?ticket_form_id=360006523273',
  consumer_defense: 'https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario',
  /* Account */
  login: true,
  external_login: false,
  register_form: true,
  checkoutUserValidation: false,    // Key de config para activar la validacion de Renaper y Equifax al momento de realizar la compra
  recovery_password: false,
  reviews: false,
  /* Social */
  social: false,
  instagram: null,
  facebook: null,
  twitter: "bancocolumbia",
  /* Layout */
  header_version: 0,
  footer: 0,
  variantCard: 0,
  buyBox: 0,
  checkout_version: 0,
  sizeChart: false,
  imagePreview: false,
  genericCheckoutError: false,
  showRealtedProducts: true,
  /* Geolocation */
  geolocation: false,
  /* Pre load localities and Zip-codes */
recaptcha_key:'',
  loadZipcodes: false,
  zendesk_key:'cba47573-5538-4b1c-9343-3c59e4fb1be7',
  emblue_code:'',
  zoho_url:'',
  gtm_id:['GTM-MMDBBF9','G-D3WY34E890'],
  facebook_pixel_id: ''
};

