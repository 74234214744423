//Angular
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, Input, OnInit} from '@angular/core';
//Services
import { UrlService } from "../../../../services/url.service";
import { PointsService } from "../../../../services/points.service";
import { CustomerService } from "../../../../services/customer.service";
import { MacroService } from "../../../../services/macro.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { PriceService } from '../../../../services/price.service';
//Models
import { Customer } from "../../../../models/models.model";
//Components
import { LoginDialogComponent } from '../../../dialogs/login/login.dialog.component';
//Other
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-account-nav',
  templateUrl: './account-nav.component.html',
  styleUrls: ['./account-nav.component.sass'],
})

export class AccountNavComponent implements OnInit {
  @Input('isMobile') isMobile: boolean = false;
  customer: Customer;
  isLogged: boolean;
  env: any;
  points: any;
  customer_name: any;
  store = environment.name;
  external_login = false;

  constructor(private urlService: UrlService,
              private authenticationService: AuthenticationService,
              private customerService: CustomerService,
              private macroService: MacroService,
              private pointsService: PointsService,
              private dialog: MatDialog,
              private router: Router,
              private priceService: PriceService ) {}


  ngOnInit() {
    this.env = environment;
    this.external_login = this.authenticationService.isExternalLogin() ? this.authenticationService.isExternalLogin() : false;
    this.isLogged = this.authenticationService.isLoggedIn() ? this.authenticationService.isLoggedIn() : false;
    this.customer = this.customerService.getCustomer() ? this.customerService.getCustomer() : new Customer();
    this.customer_name = this.isLogged ? this.customerService.name() : '';
    this.points = this.pointsService.getPoints();
    this.subscribe();

    if (environment.name === 'Macro' && this.authenticationService.isLoggedIn()) {
      this.macroService.isSelecta()
    }

  }

  floatParse(attr) {
    return PriceService.humanize(attr);
  }

  subscribe() {
    this.authenticationService.authentication.subscribe(
      (value: boolean) => {
        this.isLogged = value;
        this.customer_name = this.isLogged ? this.customerService.name() : '';
      }
    );

    this.customerService.customerUpdate.subscribe(
      (customerValue: boolean) => {
        if (customerValue) {
          this.customer = this.customerService.getCustomer();
        }
    });

    this.pointsService.status.subscribe(
      (response) => {
        this.points = this.pointsService.getPoints();
      }
    )
  }

  navigate() {
    environment.name == 'Macro'? this.urlService.navigate("https://www.macro.com.ar/bancainternet/") : this.router.navigate(['/login']);
  }

  navigateGetYourCard() {
    window.open("https://sacatutarjeta.macro.com.ar/peditutarjeta?utm_source=MacroPremia&utm_medium=Web&utm_campaign=peditutarjeta_Performance_TC_MA&utm_content=DemandGen_TP_NotSet_NotSet", '_blank');
  }

  navigateHowToAddPoints() {
    window.location.href = "https://macropremia.com.ar/about";
  }

  isCheckout() {
    return this.router.url === '/checkout';
  }

  openDialog() {
    this.env.name == 'Macro' ? this.router.navigate(['https://www.macro.com.ar/bancainternet/']) : this.dialog.open(LoginDialogComponent).updateSize('768px', '450px');
  }

}
