//Angular
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
//Services
import { MacroService } from '../../../services/macro.service';
import { MenusService } from '../../../services/menus.service';
import { CheckoutService } from '../../../services/checkout.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { GeolocationService } from '../../../services/geolocation.service';
import { UrlService } from '../../../services/url.service';
//Models
import { Menu } from '../../../models/models.model';
//Components
import { CartComponent } from '../../dialogs/cart/cart.component';
import { HiddenSearchComponent } from './hidden-search/hidden-search.component'
import { PhoneDialogComponent } from '../../dialogs/phone-dialog/phone-dialog.component';
//Other
import { Subscription } from "rxjs";
import { environment } from '../../../environments/environment';
import { map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('categories', { static: false }) categories: ElementRef;
  @ViewChild('hiddenSearch', { static: false }) hiddenSearchRef: ElementRef;

  fullMenu: Menu[] = [];
  menus: Menu[] = [];
  headerVersion = environment.header_version;
  cartSubscription: Subscription;
  variantSubscription: Subscription;
  authSubscription: Subscription;
  quantity = 0;
  container: any;
  env: any = environment;
  timer: any;
  primium: boolean;
  selecta: boolean;

  // geolocation
  geolocation: boolean;
  is_located: boolean;
  location: any = {}

  arrayMenus$ = [];

  //Chancheada de aquellas
  arrayTagsStyles = [
    {
      color: '#007894',
      backgroundImage: '/assets/stores/current_store/images/icons-tags-topbar/tag-strong-blue.svg',
      icon: '/assets/stores/current_store/images/icons-tags-topbar/isologo-tienda-bna-blanco.svg',
      zIndex: 40,
      left: 0,
      height: '50',
      width: '170',
      targetSelf: false
    },
    {
      color: '#FDFDFD',
      backgroundImage:  '/assets/stores/current_store/images/icons-tags-topbar/tag-blue.svg',
      icon: '/assets/stores/current_store/images/icons-tags-topbar/bna-viajes.png',
      zIndex: 30,
      left: '-35px',
      height: '26',
      width: '113',
      targetSelf: true
    },
  ]

  constructor(private menusService: MenusService,
              private authenticationService: AuthenticationService,
              private dialog: MatDialog,
              private checkoutService: CheckoutService,
              private router: Router,
              private macroService: MacroService,
              private urlService: UrlService,
              private geolocationService: GeolocationService,
            ) { }

  ngOnInit() {
    this.env = environment;
    this.geolocation = environment.geolocation;
    (environment.geolocation) ? this.setupLocation() : null;

    this.menusService.index().subscribe(
      (all) => {
        this.fullMenu = all;
        this.fullMenu.forEach((element) => {
          if (environment.name == "Macro" && (element.isDespegar() || element.isAerolineas())) {
            if (this.isDespegarMacro(element)) {this.menus.push(element)}
            if (this.isAerolineasActiveHour(element)) {this.menus.push(element)}
          } else {
            this.menus.push(element)
          }
        });
    });

    this.checkoutService.cartQuantity().subscribe(
      (response) => {
        if (response.quantity) {
          this.quantity = response.quantity;
        }
      }
    );


    if ( this.env.name === 'Macro' ) {
      this.macroService.selecta.subscribe((response) => {
        this.selecta = response;
      });
    }

    this.subscribers();

    this.container = (this.env.name === 'Macro' || this.env.name === 'BancoCiudad') ? 'container' : 'container-fluid' ;

    if (this.env.name === 'BancoNacion') this.getDataMenuTopBar();
  }

  subscribers() {

    this.cartSubscription = this.checkoutService.cartUpdated.subscribe(
      (value: boolean) => {
        this.quantity = this.checkoutService.getCart().countItems();
      },
    );

    this.variantSubscription = this.checkoutService.variantRemoved.subscribe(
      (value: boolean) => {
        if (value) {
          this.quantity = 0;
        } else if (this.quantity > 0) {
          this.quantity--;
        }

      }
    );

    this.authSubscription = this.authenticationService.authentication.subscribe(
      (value: boolean) => {
        this.menus = []
        this.fullMenu.forEach((element) => {
          if (environment.name == "Macro" && (element.isDespegar() || element.isAerolineas())) {
            if (this.isDespegarMacro(element)) {this.menus.push(element)}
            if (this.isAerolineasActiveHour(element)) {this.menus.push(element)}
          } else {
            this.menus.push(element)
          }
        });
    });

  }

  ngOnDestroy() {
    if (this.cartSubscription) this.cartSubscription.unsubscribe();
    if (this.variantSubscription) this.variantSubscription.unsubscribe();
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }

  openCart() {
    this.dialog.open(CartComponent, {
      panelClass: 'cart-dialog-container',
      width: '700px',
      height: '650px'
    });
  }

  openPhone() {
    this.dialog.open(PhoneDialogComponent, {
      panelClass: 'phone-dialog-container',
      width: '400px',
      height: '250px'
    });
  }

  openHiddenSearch() {
    this.dialog.open(HiddenSearchComponent, {
      panelClass: 'hidden-search-dialog-container',
      data: {
        hiddenSearchRef: this.hiddenSearchRef,
        width: '400px'
      }
    });
  }

  openSide() {
    this.categories.nativeElement.classList = ["active"];
  }

  closeSide() {
    this.categories.nativeElement.classList = [];
  }

  isCheckout() {
    return this.router.url === '/checkout';
  }

  showMenu(event) {
    clearTimeout(this.timer);
    const menu = event.currentTarget.querySelector("ul");
    this.timer = setTimeout(() => {
      if (menu) {
        menu.style.visibility = 'visible';
        menu.style.opacity = '1';
      }
    }, 200);

  }

  hideMenu(event) {
    const menu = event.currentTarget.querySelector("ul");
    if (menu) {
      menu.style.visibility = 'hidden';
      menu.style.opacity = '0';
    }
    clearTimeout(this.timer);
  }

  isDespegarMacro(menu) {
    return (menu.isDespegar() && !this.authenticationService.isLoggedIn());
  }

  isAerolineasActiveHour(menu) {
    var now = new Date().getHours();
    return (menu.isAerolineas() && now >= 6);
  }

  goToMacroAbout() {
    (this.selecta) ? this.router.navigate(['/selecta-about']) : this.router.navigate(['/about']);
  }

  goToGetMacroCard() {
    window.open('https://sacatutarjeta.macro.com.ar/peditutarjeta?utm_source=MacroPremia&utm_medium=Web&utm_campaign=peditutarjeta_Performance_TC_MA&utm_content=DemandGen_TP_NotSet_NotSet', '_blank');
  }

  goToHome() {
    this.env.name != 'MiMoto' ? this.router.navigate(['/']) : this.urlService.navigate('https://tiendabna.com.ar');
  }

  toggleMenu() {
    let element = document.getElementById('header-v2-collapse');
    element.classList.toggle('open');
  }

  setupLocation() {
    this.geolocationService.state.subscribe((response) => {
      this.is_located = response.located
      this.location = response.location
    })
  }

  geolocate() {
    this.geolocationService.fireGeolocationPopUp();
  }

  navigate(url: string, targetSelf: boolean = true) {
    this.urlService.navigate(url, undefined, targetSelf);
  }

  getDataMenuTopBar() {
    // arrayMenus$= this.menusService.getMenuTopbar()
    //   .pipe(
    //     map(r=> r),
    //     shareReplay(1)
    //   )
    this.menusService.getMenuTopbar()
    .pipe(
      tap(res => this.arrayMenus$ = res),
    ).subscribe()
  }
}
