import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

@Injectable()

export class NewsletterService {

	constructor(private http: HttpService) { }

	subscription(email: string, listName?: string) {
		let data = { email: email };

		if (listName){
			data['list_name']= listName;
		}

		return this.http.post('newsletters', data)
	}
}
