import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Sitemap} from '../models/models.model';

@Injectable()

export class SitemapsService extends ModelService<Sitemap> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'menus', Sitemap);
  }
}
