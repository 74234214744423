//Angular
import { MatDialog, throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
//Models
import { Store } from "../../../models/models.model";
import { Metatag } from '../../../models/models.model';
import { Offer } from "../../../models/models.model";
import { Pagination } from "../../../models/models.model";
//Services
import { StoreService } from '../../../services/store.service';
import { DespegarService } from '../../../services/despegar.service';
import { RelationService } from '../../../services/model.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { PriceService } from '../../../services/price.service';
//Components
import { OfferComponent } from '../../../app/dialogs/offer/offer.component';
//Others
import { Subscription, combineLatest} from 'rxjs';
import { UrlService } from '../../../services/url.service';
import { MktService } from '../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-despegar',
  templateUrl: './despegar.component.html',
  styleUrls: ['./despegar.component.sass'],
  providers: [DespegarService]
})
export class DespegarComponent implements OnInit, OnDestroy {
  pagination: Pagination = new Pagination;
  routeSubscription: Subscription;
  metatags: Metatag = new Metatag;
  query: string;
  order: string;
  error: boolean;

  offers = [];
  offerPromise: Offer;
  offerResponse: Offer[];

  offersCatalog: Offer[] = [];

  catalog_name: string;
  pointsEquivalence: number;
  optionValue: string;

  macroPoint: number;

  offerType = [
    {name: 'Actividades', value: 'activities'},
    {name: 'Automoviles', value: 'cars'},
    {name: 'Hoteles', value: 'hotels'},
    {name: 'Paquetes', value: 'packages'},
    {name: 'Vuelos', value: 'flights'}
  ];
  url = 'https://macro.despegar.com.ar/wl-home-services/offers/';

  constructor(private dialog: MatDialog,
              private despegarService: DespegarService,
              private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private urlService: UrlService,
              private storeService: StoreService,
              private relationService: RelationService,
              private _mktService: MktService) { }

  ngOnInit() {
    this.catalog_name = "Despegar";
    this.subscribers();
    this.setMetatags();
    this.doPagination();
    setTimeout(() => {
    }, 3000)
  }

  subscribers() {
    //this.storeService.currentStore();
    this.storeService.getStore()
      .subscribe(
        res => {
          this.pointsEquivalence = res.visa_puntos_equivalence;
        }
      )

    this.offerType.forEach(element => {
      this.getOffers(element.value);
    });
  }

  getOffers(option) {
    this.despegarService.despegarGet(this.url + (option ? option : ''))
      .then((response: any) => {
        response[option].forEach((element) => {element ? this.offers.push(element) : false });
      }).catch((error) => {
        this.error = true;
      });
  }

  filterOffers(option) {
    this.offers = [];
    if (option === 'all') {
      this.subscribers()
    } else {
        this.despegarService.despegarGet(this.url + (option ? option : ''))
        .then((response: any) => {
          response[option].forEach((element) => {element ? this.offers.push(element) : false });
        }).catch((error) => {
          this.error = true;
        });
    }
  }

  sortOffers(asc: boolean) {
    const sorted = this.offers.sort((a, b) => {
      if (asc) {
        this.optionValue = "Precio bajo a alto";
        return a.price_detail.total - b.price_detail.total;
      } else {
        this.optionValue = "Precio alto a bajo";
        return b.price_detail.total - a.price_detail.total;
      }
    });
    this.offers = sorted;
  }

  setMetatags() {
    this.metatags.title = "Despegar";
    this.metatags.description = "Catalogo Despegar";
    this._mktService.setMetaTags(this.metatags.title, this.metatags);
  }

  doPagination() {
    if(this.offers){
      this.pagination.current_page = 1;
      this.pagination.total_pages = 1;
      this.pagination.buildPages();
    }
  }

  ngOnDestroy() {
    if (this.routeSubscription) this.routeSubscription.unsubscribe();
  }

  openOffer(offer) {
    this.dialog.open(OfferComponent, {
      data: offer,
      panelClass: 'offer-dialog-container',
      width: '1050px',
      height: '600px'});
  }

  clearFilter() {
    this.router.navigateByUrl(window.location.href);
  }

  ceilPoints(price){
    return Math.ceil(price / this.pointsEquivalence);
  }

  floatParse(attr) {
    return PriceService.humanize(attr);
  }

  getAmountRelation( points: number , price: number) {
    const pricePercent = Math.ceil(price * 0.8);
    const pointsPercent = Math.ceil(points * 0.2);
    return '$' + this.floatParse(pricePercent) + ' + ' + this.floatParse(pointsPercent) + ' Puntos';

  }

}
