import {Component, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {NewsletterService} from "../../../services/newsletter.service";
import {HttpService} from "../../../services/http.service";
import {Store} from "../../../models/models.model";
import {StoreService} from "../../../services/store.service";

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.sass']
})
export class NewsletterComponent implements OnInit {
  store: Store;
  code: '';

  constructor(private dialogRef: MatDialogRef<NewsletterComponent>,
              private newsletterService: NewsletterService,
              private storeService: StoreService,
              private httpService: HttpService,
              private element: ElementRef,
              private renderer: Renderer2
            ) { }

  ngOnInit() {
    this.storeService.getStore().subscribe(res => this.store = res);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  saveNewsletter() {
    const emailField = this.element.nativeElement.querySelector('#email');
    const emailValue = emailField.value;

    if (emailValue === "") {
      this.renderer.addClass( emailField, 'redy' );
      return false;
    }

    this.httpService.post('coupons', { amount: 150, days_to_expire: 14 }).subscribe(
      (response) => {
        this.code = response.code;
      }, (error) => {
        console.log(error);
      }
    );

    this.newsletterService.subscription(emailValue).subscribe(
      (response) => {
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
