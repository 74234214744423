import { Injectable } from '@angular/core';
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable()
export class AerolineasCanActivateGuardService implements CanActivate {

  constructor(
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(environment.name === 'Macro'){
      return true;
    }else{
      this.router.navigate(['/']);
      return false
    }
  }
}
