import { distinctUntilChanged, concatMap } from 'rxjs/operators';
//Angular
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
//Models
import { Store } from "../../../models/models.model";
//Services
import { AerolineasArgentinasService } from '../../../services/aerolineas-argentinas.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { RelationService } from '../../../services/model.service';
import { CustomerService } from '../../../services/customer.service';
import { ProfileService } from '../../../services/account/profile.service';
import { PointsService } from '../../../services/points.service';
import { StoreService } from '../../../services/store.service';
import { MacroService } from '../../../services/macro.service';
import { UrlService } from '../../../services/url.service';
//Other
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { MktService } from '../../new-core/core/services/mkt.service';
import { filter, first, tap } from 'rxjs/operators';

@Component({
  selector: 'app-aerolineas-argentinas',
  templateUrl: './aerolineas-argentinas.component.html',
  styleUrls: ['./aerolineas-argentinas.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class AerolineasArgentinasComponent implements OnInit, OnDestroy {
  macroLogo = '/assets/stores/current_store/images/logo-macropremia.png';
  aerolineasArgentinasLogo = '/assets/stores/current_store/images/aerolineas-plus.png';
  confirmEmail = new FormControl(null, [Validators.required, Validators.email]);
  selectMilesGroup: FormGroup;

  transaction: boolean;
  processData: boolean;
  buttonLoader: boolean;
  formValid: boolean;
  multiplo = true;
  availablePoints: number;

  docTypes = [
    {slug: 'DNI', value: 'DNI'},
    {slug: 'PASAPORTE', value: 'PAS'},
    {slug: 'PASAJERO FRECUENTE', value: 'SOC'}
  ];

  fecha: Date;

  error: string;
  email: string;
  socio: string;
  nombre: string;
  documento: string;
  operacion: string;
  puntosString = "PUNTO";
  milesString = 'MILLA'
  dateRange: boolean = false;

  public store: Store;
  private logged = false;
  public ready = false;

  close = false;

  private _subs: Subscription[] = []

  constructor(private router: Router,
              private _formBuilder: FormBuilder,
              private storeService: StoreService,
              private macroService: MacroService,
              private _mktService: MktService,
              private customerService: CustomerService,
              private profileService: ProfileService,
              private pointsService: PointsService,
              private authenticationService: AuthenticationService,
              private _aerolineasArgentinasService: AerolineasArgentinasService) {

              }

  ngOnInit() {

    this.checkHour();
    this.setStore();

    this._mktService.setMetaTags('Aerolineas Argentinas');

  }

  checkHour(){
    let date = new Date();
    let hour = date.getHours();

    if( Number( hour ) <= 6 ) {
      this.close = true;
    }else{
      this.close = false;
    }
  }

  setStore() {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    if (environment.name === 'Macro') {
      this._subs.push(
        this.storeService.getStore()
        .pipe(
          tap((store) => {
            this.store = store;

            this.setConfig();

            if (isLoggedIn) this.macroSessionCheck();

          }))
        .subscribe()
      )
    }

  }

  macroSessionCheck() {

    this._subs.push(
      this.profileService.show()
      .pipe(
        tap((customer) => {
          if (customer.temporary_email || !customer.doc_number) this.macroService.updateProfile();
        }),
        concatMap(() => this.macroService.sessionStatus()),
        tap((valid) => valid ? this.logged = true : this.router.navigate(['session-expired']))
      )
      .subscribe()
    )

  }

  setConfig() {
    this.availablePoints = this.pointsService.getPoints();
    this.processData = false;
    this.formsGenerator();
  }

  formsGenerator() {

    this.selectMilesGroup = this._formBuilder.group({
      selectPoints: new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[1-9][0-9]*$/),
        Validators.max(+this.pointsService.getPoints()),
        Validators.min(this.store.miles_equivalent) // EDIT MIN INPUT MACRO
      ]),
      selectDocType: new FormControl(this.docTypes[0].value, [
        Validators.required
      ]),
      selectAerolineasPlus: new FormControl(null, [
        Validators.required
      ])
    });

    this.ready = true;
  }

  plurals(word: string, quantity: number){
    return quantity > 1 ? word+'S' : word;
  }

  pointsToMiles(): number {
    const points = this.selectMilesGroup.get('selectPoints').value;

    return Math.floor(
      points > 0
        ? (points / this.store.points_equivalent) * this.store.miles_equivalent
        : 0
      );
  }

  getParams() {
    const params = {
      id_cobis: +this.macroService.getCobis(),
      docType: this.selectMilesGroup.get('selectDocType').value,
      docNumber: this.selectMilesGroup.get('selectAerolineasPlus').value,
      email: this.confirmEmail.value,
      points: +this.selectMilesGroup.get('selectPoints').value,
      store_id: this.store.id,
    }
    return params;
  }



  userValidator(stepper: MatStepper) {
    this.buttonLoader = true;
    this.error = null;
    if (this.logged) {
      this._subs.push(
        this._aerolineasArgentinasService.getClient(this.getParams())
        .pipe(
          tap((response) => {
            if (response[0].lv_cod_error === 1){
              this.nombre = response[0].lv_nombre + ' ' + response[0].lv_apellido;
              this.socio = response[0].membership_number;
              switch(this.selectMilesGroup.get('selectDocType').value){
                case 'SOC': {
                  this.documento = response[0].lv_nume_doc;
                  break;
                }
                case 'DNI': {
                  this.documento = this.selectMilesGroup.get('selectAerolineasPlus').value;
                  break;
                }
                case 'PAS': {
                  this.documento = this.selectMilesGroup.get('selectAerolineasPlus').value;
                  break;
                }
              }
              stepper.next();
            } else {
               this.error = response[0].lv_mensaje_error;
            }
            this.buttonLoader = false;
          },
          (error) => this.buttonLoader = false)
        )
        .subscribe()
      )
    } else {
      this.macroService.redirectToLogin();
    }

  }

  getParamsAcreditMiles() {
    const params = {
      id_cobis: +this.macroService.getCobis(),
      docType: 'SOC',
      docNumber: this.socio,
      email: this.confirmEmail.value,
      points: +this.selectMilesGroup.get('selectPoints').value,
      store_id: this.store.id,
    }
    return params;
  }


  acreditMiles(stepper: MatStepper) {

    if (this.confirmEmail.valid) {
      stepper.next();
      this.processData = false;
      this._subs.push(
        this._aerolineasArgentinasService.acredit(this.getParamsAcreditMiles())
        .pipe(
          tap(
            (response) => {
              this.processData = true;
              if(response.success){
                this.error = null;
                this.email = this.confirmEmail.value;
                this.fecha = new Date();
                this.operacion = response.order_id;
                this.pointsService.updatePoints(-this.selectMilesGroup.get('selectPoints').value)

                //Send event to Google Analytics
                this._mktService.collect('MILLAS-AEROLINEAS-PLUS', {millas_aerolineas_data: this.getParamsAcreditMiles()} )
              } else {
                this.error = response.message;
                this.processData = true
              }
            },
            (error) => {
              this.error = "No fue posible completar la transacción"
              this.processData = true
            })
        )
        .subscribe()
      )

    }

  }

  restart(stepper: MatStepper){
    this.error = null;
    stepper.reset()
  }

  cleanInvalidCharacter(event: KeyboardEvent){

    if (['.',',','e','E', '+', '-'].includes(event.key)){

      event.preventDefault();

    }

    const valuePoints = String(this.selectMilesGroup.get('selectPoints').value);

    if(valuePoints !== valuePoints.replace(/^(0+)/g, '')){
      event.preventDefault();
    }

  }

  pointsValidator(event?){

    if (this.selectMilesGroup.get('selectPoints').value < 0 || this.selectMilesGroup.get('selectPoints').value === 0) {
      this.selectMilesGroup.patchValue({'selectPoints': 0});
    }

    if (this.selectMilesGroup.get('selectPoints').value % this.store.points_equivalent != 0) {
      this.multiplo = false
      let miles = Math.floor(this.selectMilesGroup.get('selectPoints').value/this.store.points_equivalent)*this.store.points_equivalent;
      this.selectMilesGroup.patchValue({'selectPoints': miles});
    }else{
      this.multiplo = true;
    }

    if(this.logged) {
      const points = this.selectMilesGroup.get('selectPoints').value;
      if (points >= this.availablePoints){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': this.availablePoints});
      }
      if (points < 0){
        if (event) event.preventDefault()
        this.selectMilesGroup.patchValue({'selectPoints': 0});
      }
    }
  }

  ngOnDestroy() {

    this._subs.forEach( sub => sub.unsubscribe());

  }

  backToForm(stepper: MatStepper) {
    stepper.previous();
  }

}
