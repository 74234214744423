import {Inject, Injectable, Optional} from '@angular/core';
import {ILocalStorageServiceConfig, LocalStorageService} from 'angular-2-local-storage';
import {LocalStoragePolyfill} from "./local_storage_polyfill";
import { environment } from '../environments/environment';

@Injectable()

export class LocalStorage {
  private client: LocalStorageService | LocalStoragePolyfill;
  private session: string;
  constructor(
    private localStorageService: LocalStorageService,
    private polyfill: LocalStoragePolyfill
            ) {
    this.client = localStorageService.isSupported ? localStorageService : polyfill;
    this.session = environment.session;
  }

  set(key: string, value: any | any[]) {
    this.client.set( this.generate_hash(key), value);
  }

  get(key: string) {
    return this.client.get( this.generate_hash(key) );
  }

  remove(key: string): void {
    this.client.remove( this.generate_hash(key) );
  }

  setObject(key: string, value: string[]) {
    this.set(key, value);
  }

  getObject(key: string) {
    const elements = this.client.get<any>(this.generate_hash(key));
    return elements !== null ? elements : [];
  }

  getKeyFromArray(key: string, primaryKey: string, value: any) {
    const found = this.getObject(key).find((item) => item[primaryKey] === value);
    return found ? found : false;
  }

  pushArray(key: string, value: any) {
    const elements = this.getObject(key);

    if (elements === null) {
      this.setObject(key, [value]);
    } else if (Array.isArray(elements) && !(elements.indexOf(value) >= 0)) {
      elements.push(value);
      this.setObject(key, elements);
    }
  }

  removeFromArray(key: string, value: any) {
    const elements = this.getObject(key);

    if (elements === null || !Array.isArray(elements)) {
      return false;
    }

    if (elements.indexOf(value) >= 0) {
      elements.splice(elements.indexOf(value), 1);
      this.setObject(key, elements);

      return true;
    }

    return false;
  }

  removeKeyFromArray(key: string, primaryKey: string, value: any) {
    const elements = this.getObject(key);

    if (elements === null) {
      return false;
    }

    elements.forEach((el, index) => {
      if (el[primaryKey] === value) {
        elements.splice(index, 1);
        this.setObject(key, elements);
        return true;
      }
    });

    return false;
  }

  generate_hash(key) {
    return this.session + '_' + key
  }
}
