import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";

@Component({
  selector: 'app-cols4-brands-v1',
  templateUrl: './cols4-brands-v1.component.html'
})
export class Cols4BrandsV1Component implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols4_brands_v1';
  }

  constructor() {}

  ngOnInit() { }
}
