//Angular
import { Injectable } from '@angular/core';
//Models
import { Product } from '../models/models.model';
import { CheckoutItem } from '../models/models.model';
//Services
import { LocalStorage } from './local_storage.service';
//Other
import { Subject } from 'rxjs';
import { CheckoutCart } from '../models/models.model';

@Injectable()

export class PointsService {

  status = new Subject<boolean>();
  cart = new CheckoutCart;

  constructor(private localstorage: LocalStorage) { }

  getPoints():number {
    return Number(this.localstorage.get('points'));
  }

  setPoints( newPoints: number ) {

    const points = newPoints;

    this.localstorage.set('points', points);

    this.status.next(true);
  }

  updatePoints( newPoints: number ) {

    const points = +this.getPoints() + newPoints;

    this.setPoints(points);
  }

  destroyPoints() {

    this.localstorage.remove('points');

  }

  pointsEquivalence( product: Product ) {

    return Math.ceil( ( product.points_equivalence ? product.points_equivalence : 0 ) );

  }

  creditValue( product: Product, points: number ) {

    if ( product.points_equivalence < 1 ) {

      return Math.ceil( ( product.points_equivalence ? product.points_equivalence : 0 ) * points );

    } else {

      return Math.floor( ( product.points_equivalence ? product.points_equivalence : 0 ) * points );
    }
  }

  subTotal( checkoutItem: CheckoutItem[] ) {

    let total: number = 0;

    checkoutItem.forEach(

      element => { total += element.points }

    );

    return total;
  }

  inCreditAmount( item ) {

    if ( item ) {

      const limit = item.total - this.creditValue( item.product, item.points ) * item.quantity;

      if ( limit <= 0 ) return 0;

      return Math.ceil(limit);

    }

    return 0;
  }

}
