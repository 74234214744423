<section class="tracking">
  <div class="container">
    <h1 class="title">Segu&iacute; tu Pedido</h1>
    <input class="track-data" placeholder="Ingres&aacute; tu n&uacute;mero de Tracking" [formControl]="trackingNumber" required>
    <button [ngClass]=" trackingNumber.valid ? 'track-button' : 'track-button-disabled'"
            (click)="trackingStatus(trackingNumber.value)"
            [disabled]="!trackingNumber.valid">
      Seguir pedido
    </button>
  </div>

  <div class="container" *ngIf="isLoading">
    <mat-spinner class="mx-auto my-5"></mat-spinner>
  </div>

  <div class="error" *ngIf="error">
    <h1>{{error}}</h1>
    <h3 *ngIf="env.phone">Por cualquier consulta llama al {{ env.phone }}</h3>
  </div>

  <div id="courier" class="container" *ngIf="data">
    <div class="title">
      <h3>Logistica: {{ data.courier }}</h3>
    </div>
    <table class="table table-striped">
      <thead>
        <td><span>Descripción</span></td>
        <td><span>Fecha</span></td>
      </thead>
      <tbody>
        <tr *ngFor="let event of data.events">
          <td>{{ event.description }}</td>
          <td>{{ event.date }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</section>

