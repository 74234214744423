import { Injectable, OnInit } from '@angular/core';
//Services
import { UrlService } from './url.service';
import { HttpService } from './http.service';
import { StoreService } from './store.service';
import { AuthenticationService } from './authentication.service';
import { CardBrand } from 'models/models.model';
import { Subject } from 'rxjs';

@Injectable()
export class SubeService implements OnInit{
  url = 'integration/sube_cards';
  storeId;

  constructor(
    private urlService: UrlService,
    private http: HttpService,
    private storeService: StoreService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.storeService.getStore().subscribe(res => this.storeId = res.id);
  }

  notLoggedIn() {
    if (!this.authenticationService.isLoggedIn()) this.urlService.navigate("https://www.macro.com.ar/bancainternet/");
  }

  getStoreId(): number {
    return this.storeId;
    //this.storeService.currentStore();
  }

  acredit(pars) {

    return this.http.post('integration/points_exchange', {
      exchange: {
        full_name: pars.name,
        document_type: pars.document_type,
        document_number: pars.document_number,
        amount: pars.amount,
        card_number: pars.card_number,
        email: pars.email,
        store_id: pars.store_id,
      },
      gateway: {
        name: "sube"
      }
    });
  }

  isValidCard(subeCard) {
    return this.http.get(this.url + '/verify', {card_number: subeCard});
  }

  getCards() {
    return this.http.get(this.url);
  }

  createCard(card) {
    const sube_card = {
      number: card.number,
      alias: card.alias
    }
    return this.http.post(this.url, {sube_card});
  }

  selectCard(id) {
    return this.http.get(this.url, id);
  }

  editCard(card_id: any, card_data: any) {
    const sube_card = {
      number: card_data.number,
      alias: card_data.alias
    }
    return this.http.put(this.url + '/' + card_id, sube_card);
  }

  deleteCard(id: any) {
    return this.http.delete(this.url + '/' + id, id);
  }

}
