import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export type GtmData = {[key: string]: any};

@Injectable()
export class GtmService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

    //console.log('El objecto en cuestion', Object)
    //console.log('El objecto de platformID',this.platformId)


    // if (isPlatformBrowser(this.platformId) && ! Object.hasOwnProperty.call(window, 'dataLayer')) {
    //   throw new Error('`window.dataLayer` is not defined.');
    // }
  }

  public push(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      window['dataLayer'].push(data);
    }
  }

  public trigger(event: string, data?: GtmData) {
    this.push({
      event, data
    });
  }
}
