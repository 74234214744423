import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

@Injectable()

export class OrdersService {
  private _order_id: string;

  constructor(private http: HttpService) { }

  tracking(orderId) {
    return this.http.post('orders/tracking', { id: orderId })
  }

  get order_id(): string {
    return this._order_id;
  }

  set order_id(value: string) {
    this._order_id = value;
  }
}
