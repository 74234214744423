import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, Inject, PLATFORM_ID} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import { UrlService } from '../../../services/url.service';

@Component({
  selector: 'app-tije',
  templateUrl: './tije.component.html',
  styleUrls: ['./tije.component.sass']
})
export class TijeComponent implements OnInit, AfterViewInit {
  timeOut: any;
  constructor(private dialogRef: MatDialogRef<TijeComponent>,
              private element: ElementRef,
              private renderer: Renderer2,
              private urlService: UrlService,
              @Inject(PLATFORM_ID) private platformId: Object
            ) {}

  ngOnInit() {
    this.timeOut = setTimeout(() => {
      this.urlService.navigate("https://avenida.tije.travel");
     }, 4000);
  }


  ngAfterViewInit() {
    const contanier = this.element.nativeElement.querySelectorAll('mat-dialog-container');
    this.renderer.setStyle( contanier[0], 'height', 'auto');
    this.renderer.setStyle( contanier[0], 'overflow', 'hidden');
    this.renderer.setStyle( contanier[0], 'background', 'none');
  }

  closeDialog() {
    clearTimeout(this.timeOut);
    this.dialogRef.close();
  }

}
