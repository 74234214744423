// Angular
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';

import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
// Services
import { AuthenticationService } from '../../../services/authentication.service';
import { PointsService } from '../../../services/points.service';
import { MacroService } from '../../../services/macro.service';
import { SubeService } from '../../../services/sube.service';
import { StoreService } from '../../../services/store.service';
import { PriceService } from '../../../services/price.service';
import { ProfileService } from '../../../services/account/profile.service';
// Components
import { NewCardComponent } from '../../../app/dialogs/sube/new-card/new-card.component';
import { SelectCardComponent } from '../../../app/dialogs/sube/select-card/select-card.component';
import { EditCardComponent } from '../../../app/dialogs/sube/edit-card/edit-card.component';
// Other
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { Customer } from '../../../models/models.model';
import { MktService } from '../../new-core/core/services/mkt.service';
import { QualtricsService } from '../../../services/third-party/qualtrics.service';
import { QualtricsDataService } from '../../../services/third-party/qualtrics-data.service';

@Component({
  selector: 'app-sube',
  templateUrl: './sube.component.html',
  styleUrls: ['./sube.component.sass'],
  encapsulation: ViewEncapsulation.None
})

export class SubeComponent implements OnInit, OnDestroy {
  env = environment;
  selectAmountGroup: FormGroup;
  macroSession: Subscription;
  customer: Customer;

  isLoggedIn: boolean;
  externalLogIn: boolean;
  formInvalid: boolean;
  processData: boolean;

  promotions = [
    {amount: 500},
    {amount: 1000},
    {amount: 2000},
    {amount: 4000},
    {amount: 6000}
  ]

  subeEquivalence: number;
  pointsEquivalence: number;

  cardNumber: number;
  montoCarga: number;
  puntosMacro: number;
  availablePoints: number;
  orderId: any;

  error: string;
  email: string;
  success: boolean;
  showPoints: boolean;
  validCard = true;

  storeId: any;
  math = Math;


  constructor(private router: Router,
              private dialog: MatDialog,
              private subeService: SubeService,
              private _formBuilder: FormBuilder,
              private macroService: MacroService,
              private pointsService: PointsService,
              private storeService: StoreService,
              private _mktService: MktService,
              private authenticationService: AuthenticationService,
              private profileService: ProfileService,
              private _qualtricsService: QualtricsService,
              private _qualtricsDataService: QualtricsDataService
              ) { }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.externalLogIn = this.authenticationService.isExternalLogin();

    this.formsGenerator();
    this._mktService.setMetaTags('Recarga SUBE');

    this.availablePoints = +this.pointsService.getPoints();
    this.getStoreData();

    if (this.isLoggedIn && environment.name === 'Macro') {

      if (!this.macroService.isValidSession()) {
        this.router.navigate(['session-expired']);
      }

      this.macroSession = this.macroService.sessionStatus().subscribe(
        (response) => {
          if (!response) {
            this.router.navigate(['session-expired']);
          }
        }
      )

      this.profileService.show().subscribe(
        (response) => {

          (response.temporary_email || !response.doc_number) ? this.macroService.updateProfile() : null ;

          this.customer = response;
          this.selectAmountGroup.patchValue({
            'confirmEmail': response.email
          });

        }
      );

    }

  }

  getStoreData() {
    //this.storeService.currentStore().subscribe(
    this.storeService.getStore()
      .subscribe(
        store => {
          this.storeId = store.id;
          this.subeEquivalence = store.visa_puntos_sube_equivalence;
          this.showPoints = true;
        }
      )
  }

  formsGenerator() {
    this.selectAmountGroup = this._formBuilder.group({
      'confirmCard': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
      'confirmEmail': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'selectAmount': new FormControl('', [
        Validators.required
      ]),
    });

    this.selectAmountGroup.get('confirmCard').valueChanges.subscribe(() => {
      this.formInvalid = false;
      if (this.selectAmountGroup.get('confirmCard').valid) {
        this.validateCard();
      }
    });
  }

  getCardNumber() {
    return this.selectAmountGroup.getRawValue().confirmCard;
  }

  getEmail() {
    return this.selectAmountGroup.getRawValue().confirmEmail;
  }

  getPointsAmount() {
    return this.selectAmountGroup.getRawValue().selectAmount;
  }

  hasPoints(stepper: MatStepper) {
   (this.isLoggedIn) ? stepper.next() : this.macroService.redirectToLogin();
  }

  updateForm(card_id) {
    this.subeService.getCards().subscribe(
      async (res) => {
        const subeCard = await res.filter((x) => { return x.id === card_id; });

          this.selectAmountGroup.patchValue({
            confirmCard: subeCard[0].number
          });

      });
  }

  esCanjeable(monto: number) {
    if ( monto >= this.availablePoints ) {
      return true;
    } else {
      return false;
    }
  }

  validateCard() {
    const subeCard = this.getCardNumber();

    return this.subeService.isValidCard(subeCard).subscribe(
      (response) => {
        if (response.success === true) {
          this.validCard = true;
        } else {
          this.validCard = false;
        }
      }, (error) => {
        console.log(error);
      });

  }

  validateTransaction(stepper: MatStepper) {
    if (this.selectAmountGroup.valid && this.validCard) {
      this.cardNumber = this.getCardNumber();
      this.montoCarga = this.getPointsAmount();
      this.pointsEquivalence = this.montoCarga / this.subeEquivalence;
      stepper.next();
    } else {
      this.formInvalid = true;
    }
  }

  getParams() {
    const params = {
      name: this.customer.first_name + ', ' +  this.customer.last_name,
      document_type: this.customer.doc_type,
      document_number: this.customer.doc_number,
      amount: this.getPointsAmount(),
      card_number: this.getCardNumber(),
      email: this.getEmail(),
      store_id: this.storeId,
      id_cobis: +this.macroService.getCobis(),
    }
    return params;
  }

  async acreditSube(stepper: MatStepper) {
    stepper.next()
    this.processData = true;

    await this.subeService.acredit(this.getParams()).subscribe(
      (response) => {
        if (response.success) {
          this.processData = false;
          this.success = true;
          this.orderId = response.order_id;
          this.email = this.getEmail();
          this.pointsService.updatePoints(-Math.ceil(this.pointsEquivalence))

          //Send event to Google Analytics
          this._mktService.collect('RECARGA-SUBE', {recarga_sube_data: this.getParams()});
          //Añadir evento a Qualtrics
          this._qualtricsDataService.concatRecarga('RECARGA-SUBE-MP-OK')
        } else {
          this.error = 'No fue posible completar la transacción';
          this.processData = false;
          this.success = false;
          //Añadir evento a Qualtrics
          this._qualtricsDataService.concatRecarga('RECARGA-SUBE-MP-FAIL')
        }
      }, (error) => {
        this.error = 'No fue posible completar la transacción';
        this.processData = false;
        this.success = false;
        //Añadir evento a Qualtrics
        this._qualtricsDataService.concatRecarga('RECARGA-SUBE-MP-FAIL')
      }
    )

  }

  openNewCard() {
    const dialogRef = this.dialog.open(NewCardComponent, {
      width: '500px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(() => {
      if (dialogRef.componentInstance.selectedCard) {
        const card_number = dialogRef.componentInstance.selectedCard;
        this.selectAmountGroup.patchValue({
          confirmCard: card_number
        });
      }
    })
  }

  openSelectCard(type) {
    const dialogRef = this.dialog.open(SelectCardComponent, {
      data: type,
      width: '500px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(() => {
      if (dialogRef.componentInstance.selectedCard) {
        const card_id = dialogRef.componentInstance.selectedCard;
        this.updateForm(card_id);
      }
      if (dialogRef.componentInstance.newCard) {
        this.openNewCard()
      }
    })
  }

  openEditCard() {
    const dialogRef = this.dialog.open(EditCardComponent, {
      width: '500px',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(() => {
      if (dialogRef.componentInstance.newCard) {
        this.openNewCard()
      }
    })
  }

  restartStepper(stepper: MatStepper){
    stepper.reset()
  }

  ngOnDestroy() {
    if (this.macroSession) {
      this.macroSession.unsubscribe();
    }
  }
}
