import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class SkeletonLoaderComponent implements OnInit {

  @Input() type: string;

  constructor() { }

  ngOnInit() { }

}
