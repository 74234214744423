<form *ngIf="isLoaded" [formGroup]="selectACard">

    <div class="col-md-12 selectCard-title">
      <h3 *ngIf="type == 'select'">Seleccionar tarjeta</h3>
      <h3 *ngIf="type == 'delete'">Eliminar tarjeta</h3>
    </div>
    <hr>
    <div class="col-12 fields py-3">
      <mat-form-field appearance="outline" *ngIf="!newCard">
        <mat-label>Selecciona la Tarjeta SUBE</mat-label>
        <mat-select formControlName="selectionCard" required>
          <mat-option *ngFor="let card of cards" [value]="card.id">
            {{ card.alias }} <small class="text-muted"><span class="badge badge-secondary">{{ card.number | mask: '0000 0000 0000 0000'}}</span></small>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="newCard">
        <h6 class="mb-2">No tenés tarjetas registradas</h6>
        <p>Registra tus tarjetas SUBE para que puedas cargarlas cuando lo desees.</p>
      </div>

    </div>
    <div *ngIf="errors" class="alert alert-danger">
      <span>{{ errors }}</span>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-6 text-right">
        <button class="btn btn-seccondary btn-block" (click)="closeDialog()">CANCELAR</button>
      </div>
      <div class="col-md-6 text-right">
        <button *ngIf="type == 'select' && !newCard" class="btn btn-primary btn-block" (click)="selectCard()">SELECCIONAR</button>
        <button *ngIf="newCard" class="btn btn-primary btn-block" (click)="addNewCard()">AGREGAR TARJETA</button>
        <button *ngIf="type == 'delete' && !newCard" class="btn btn-danger text-white btn-block" (click)="deleteCard()">ELIMINAR</button>
      </div>
    </div>
  
</form>
<mat-progress-spinner
  *ngIf="!isLoaded"
  color="accent"
  mode="indeterminate">
</mat-progress-spinner>