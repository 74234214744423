<div class="gray-color override-with-white-color" id="catalog-aerolineas">
  <main>
    <div class="container pt-4 pb-5">

      <div class="row">

        <div class="col-lg-7 text-center d-flex flex-column align-items-center justify-content-center mb-4">
          <ng-container *ngIf="isSpinnerCardsActivate; then spinnerTemplate else cardsTemplate"></ng-container>

          <ng-template #spinnerTemplate>
            <mat-spinner></mat-spinner>
          </ng-template>

          <ng-template #cardsTemplate>
            <div id="promo-aerolineas-argentinas">
              <img class="img-fluid mb-3 rounded" [src]="bannerUrl" alt="banner-programa-volar" height="60"/>
            </div>
            <div class="container-cards-logos-aerolineas" [ngClass]="{'container-cards-grid-row' : arrayAirlinesTransform.length>4}">
              <ng-container *ngFor="let airline of arrayAirlinesTransform; let i=index; trackBy: trackByItems">
                <article class="card-aerolinea d-flex flex-column justify-content-end rounded" (click)="selectAirline(i)" [ngClass]="{'card-aerolinea-activated': airline.is_activated}">
                  <section class="section-logo d-flex align-items-center justify-content-center px-4">
                    <img class="img-fluid" [src]="airline.image_url" alt="logo-aerolinea">
                  </section>
                  <section class="section-text py-1">
                    <span>
                      {{airline.points_equivalent}} {{ plurals('PUNTO', airline.points_equivalent) }} = {{ airline.miles_equivalent | floatparse }} {{ plurals('MILLA', airline.miles_equivalent) }}
                    </span>
                  </section>
                </article>
              </ng-container>

            </div>
          </ng-template>


        </div>

        <div class="col-lg-5 mt-2">
          <div class="card" *ngIf="ready">

            <div id="checkout-aerolineas-argentinas">
              <!--<div *ngIf="!isLoggedIn" class="alert alert-danger" role="alert">
                Inicia sesión para obtener este beneficio.
              </div>-->
              <mat-horizontal-stepper linear='true' #aerolineasStepper>

                <!-- Points and user / First Step-->
                <mat-step [stepControl]="selectMilesGroup.valid">

                  <form [formGroup]="selectMilesGroup" *ngIf="!close" class="sub-title-macro">

                    <div class="puntos-millas col-md-12 text-center py-3">
                      <span>{{ airlineSelected.points_equivalent }} {{ plurals('PUNTO', airlineSelected.points_equivalent) }} </span>
                      <img [src]="macroLogo" class="macro-logo" alt="macro-logo"/>
                      <span> = {{ airlineSelected.miles_equivalent | floatparse }} {{ plurals('MILLA', airlineSelected.miles_equivalent) }} </span>
                      <img [src]="airlineSelected.image_url" class="AA-logo" alt="AA-logo">
                    </div>

                    <div class="canje-millas col-md-12 text-left py-3">

                      <div class="row mx-0">
                        <p><strong>Ingresá los puntos que querés canjear:</strong></p>
                        <mat-form-field appearance="outline" class="col-md-6 text-left w-100">
                          <input  matInput type="number"
                                  formControlName="selectPoints"
                                  autocomplete="off"
                                  (change)="pointsValidator($event)"
                                  (blur)="pointsValidator($event)"
                                  (keypress)="cleanInvalidCharacter($event)"
                                  step="{{airlineSelected.points_equivalent}}"
                                  placeholder="Ej. 1000"
                                  required
                                  >
                        </mat-form-field>

                        <span class="text-left ml-2 align-self-center">= {{ pointsToMiles() | floatparse}} Millas</span>

                        <br>
                      </div>
                      <div class="mb-2 text-danger" *ngIf="selectMilesGroup.controls['selectPoints'].errors?.pattern">
                        (*) Valor inválido.
                      </div>
                      <p class="mb-4 text-muted" *ngIf="!multiplo">Debe ingresar un múltiplo de {{ airlineSelected.points_equivalent }}</p>

                      <div class="row mx-0">
                        <p><strong>Cargá los datos para acreditar las Millas:</strong></p>
                        <mat-form-field appearance="outline" class="col-md-6 text-left px-1">
                          <mat-select matSelect formControlName="selectDocType" required>
                            <ng-container *ngIf="airlineSelected.name === 'Aerolineas Argentinas';then docTypeAerolineas else docTypeDefault"></ng-container>
                            <ng-template #docTypeAerolineas>
                              <mat-option *ngFor="let docType of docTypesAerolineas" [value]="docType.value">{{ docType.slug }}</mat-option>
                            </ng-template>
                            <ng-template #docTypeDefault>
                              <mat-option *ngFor="let docType of docTypes" [value]="docType.value" selected>{{ docType.slug }}</mat-option>
                            </ng-template>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-6 text-left px-1">
                          <input matInput formControlName="selectAerolineasPlus" placeholder="Ej. 123456789" required>
                        </mat-form-field>
                        <div class="mb-2 text-danger" *ngIf="selectMilesGroup.controls['selectAerolineasPlus'].errors && selectMilesGroup.controls['selectAerolineasPlus'].touched">
                            (*) Número de documento requerido.
                        </div>

                      </div>

                    </div>
                    <div *ngIf="userInvalid" class="error alert alert-danger mt-3 mb-4" role="alert">
                      <span class="alert-heading">{{ error }}</span>
                    </div>
                    <div>
                      <button class="btn btn-lg btn-primary btn-block" [disabled]="selectMilesGroup.controls['selectPoints'].errors?.pattern || selectMilesGroup.invalid" (click)="userValidator(aerolineasStepper)">
                        <mat-icon *ngIf="buttonLoader && selectMilesGroup.valid" style="padding-top: 6px;">
                          <mat-spinner color="light" diameter="20"></mat-spinner>
                        </mat-icon>
                        Continuar
                      </button>
                    </div>

                    <div class="my-3">
                        <span class="text-muted span-disclaimer sub-title-macro" [innerHtml]="airlineSelected.disclaimer"></span>
                    </div>
                    <!-- <div class="values my-3">
                      <ul>
                        <li class="mb-2">· Conocé la Tabla de Millas Aerolíneas Plus <strong><a href="http://www.aerolineas.com.ar/es-ar/viajerosfrecuentes/disfrutatusmillas" target="_blank">aquí</a></strong></li> -->
                        <!-- <li class="mb-2">· Y además descubrí la opción Millas + Pesos Argentina de Aerolíneas Plus <strong><a href="http://www.aerolineas.com.ar/es-ar/aerolineasplus/promociones/2" target="_blank">aquí</a></strong></li> -->
                        <!-- <li class="mb-2">· Si no sos cliente de Aerolíneas Plus asociate <strong><a href="http://www.aerolineas.com.ar/es-ar/aerolineasplus/acercade?Inscripcion_Plus=si" target="_blank">aquí</a></strong></li>
                      </ul>
                    </div> -->

                  </form>
                  <div class="puntos-millas col-md-12 text-center py-3" *ngIf="close">
                    <img src="/assets/stores/current_store/images/logo-macropremia.png" class="macro-logo" alt="macro-logo"/>
                    <br>
                    <br>
                    <br>
                    <span class="mb-3"> No es posible canjear puntos en este momento.</span>
                  </div>
                </mat-step>

                <mat-step [stepControl]="confirmEmail.valid">
                  <div class="sub-title-macro">
                    <h5>Confirmar canje de Millas</h5>

                    <div class="values mt-3 mb-5">
                      <div class="mb-3" style="font-size: 14px;">
                        <span><b>{{ this.selectMilesGroup.get('selectPoints').value | floatparse}} PUNTOS</b> MACRO PREMIA POR <b>{{ pointsToMiles() | floatparse}} MILLAS</b></span><br><br>
                        <span *ngIf="nombre.length">Acreditados a la siguiente cuenta de:</span>
                      </div>
                      <ng-container *ngIf="nombre.length">
                        <div class="mb-3">
                          <span>NOMBRE Y APELLIDO: </span><strong>{{ nombre }}</strong>
                        </div>
                      </ng-container>
                      <div class="mb-3">
                        <span>NÚMERO DE DOCUMENTO: </span><strong>{{ documento }}</strong>
                      </div>
                      <div class="mb-3" *ngIf="!needFidelixationNumber">
                        <span>NÚMERO DE FIDELIZACIÓN: </span><strong>{{ membership_number_socio }}</strong>
                      </div>
                      <span>Las millas estarán disponibles dentro de las proximas 72hs. hábiles</span><br>
                    </div>

                    <ng-container *ngIf="needFidelixationNumber;then inputForm else defaultForm"></ng-container>

                    <ng-template #defaultForm>
                      <div class="mb-2">
                        <small><strong>Completá tu email para que te informemos en cuanto estén disponibles:</strong></small>
                      </div>
                    </ng-template>

                    <ng-template #inputForm>
                      <div class="mb-2">
                        <small><strong>Completá los siguientes datos para que te informemos en cuanto estén disponibles:</strong></small>
                      </div>
                      <label for="confirmFidelixationNumber">Nro de fidelización(*)</label>
                      <input placeholder="Ej: Número de tarjeta Air Europa SUMA (10 dígitos)" class="form-control" [formControl]="confirmFidelixationNumber"
                                [ngClass]="{'is-invalid':(confirmFidelixationNumber.invalid && confirmFidelixationNumber.touched)}" />
                      <div class="invalid-feedback" *ngIf="confirmFidelixationNumber.invalid">
                        <div [hidden]="!confirmFidelixationNumber.errors.required">El nro de fidelización es <strong>requerido</strong>.</div>
                        <div [hidden]="!confirmFidelixationNumber.errors.minlength">Debe ser 10 dígitos.</div>
                      </div>
                    </ng-template>

                    <label for="confirmEmail">E-mail(*)</label>
                    <input placeholder="Ingresá tu correo electrónico" class="form-control mt-2" [formControl]="confirmEmail"
                          [ngClass]="{'is-invalid':(confirmEmail.invalid && confirmEmail.touched)}">
                    <div class="invalid-feedback" *ngIf="confirmEmail.invalid">
                      <div [hidden]="!confirmEmail.errors.required">El e-mail es <strong>requerido</strong>.</div>
                      <div [hidden]="!confirmEmail.errors.email">Debe ser un e-mail válido.</div>
                    </div>
                    <hr>
                    <button class="btn btn-lg btn-primary btn-block" [disabled]="confirmEmail.invalid || (confirmFidelixationNumber.invalid && needFidelixationNumber)" (click)="acreditMiles(aerolineasStepper)">CONFIRMAR</button>
                    <button class="btn btn-sm btn-link btn-block" (click)="backToForm(aerolineasStepper)">Volver</button>
                  </div>
                </mat-step>
                <mat-step>
                  <div class="col-lg-12 float-center text-center">
                    <div id="transaction-aerolineas-argentinas" class="row px-0">
                      <div class="w-100" *ngIf="!processData">
                        <div class="procesando col-md-12 text-center py-3">
                          <h4 class="my-2">Su operación está<br>siendo procesada</h4>
                          <h6 class="mx-auto mb-3"></h6>
                          <mat-spinner class="mx-auto my-5"></mat-spinner>
                        </div>
                      </div>
                      <div *ngIf="processData" class="w-100">
                        <div class="procesando col-md-12 text-center p-4 py-5 mb-3">
                          <p class="mt-3">
                            <span class="span-info">
                              !
                            </span>
                          </p>
                          <h4 *ngIf="error" class="mt-4">{{error}}</h4>
                          <h4 *ngIf="!error" class="mt-4">Solicitud en proceso</h4>

                          <div *ngIf="!error" class="values my-4 text-center">
                            <p>Estamos gestionando tu solicitud <strong>{{operacion}}.</strong></p>
                            <p>
                              Te estaremos enviando novedades a tu correo <span class="span-mail">{{email}}</span>.<br> Si no lo encontrás en la bandeja de entrada, chequeá la carpeta SPAM o correo no deseado.
                            </p>

                          </div>
                        </div>

                        <div *ngIf="!error">
                          <button class="mt-3 btn btn-lg btn-primary btn-block" (click)="goHome()">
                            CONTINUAR NAVEGANDO
                          </button>
                        </div>

                        <div *ngIf="error">
                          <button class="btn btn-primary btn-lg btn-block" (click)="restart(aerolineasStepper)">REINTENTAR</button>
                          <button class="btn btn-lg btn-block" (click)="goHome()">VOLVER</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>

          </div>
        </div>
      </div>

      <!-- Legal Row -->
      <div class="row">
        <div class="col-12 py-4">
          <p class="text-justify format-p">
            <!--Legales viejos-->
            <small class="text-muted">
              <div class="text-muted" [innerHtml]="airlineSelected.legal_terms"></div>
            </small>
          </p>
        </div>
      </div> <!-- End Legal Row -->

    </div>
  </main>
</div>
