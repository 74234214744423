import { Component, OnInit, HostListener } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";
import {UrlService} from "../../../../../services/url.service";
import { MktService } from '../../../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-cols1-pics1-v1',
  templateUrl: './cols1-pics1-v1.component.html',
  styleUrls: ['./cols1-pics1-v1.component.sass']
})

export class Cols1Pics1V1Component implements OnInit {
  component: ComponentLanding;
  innerWidth: any;
  mobile: boolean = false;
  item: any ;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols1_pics1_v1';
  }

  constructor(
    private _urlService: UrlService,
    private _mktService: MktService
  ) { }

  ngOnInit() {
    this.mobile = window.innerWidth <= 992 ? true : false;
    this.item = this.component.data.items["0"];
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth <= 992 ? false : true;
  }

  navigate(item) {
    this._mktService.collect('CLICK-IN-BANNER', item);
    this._urlService.navigate(this._urlService.linkSelector(item));
  }
}
