export const PROVINCIAS = [
    { id: '1' , value: 'capital federal', display: 'Capital Federal'},
    { id: '2' , value: 'buenos aires', display: 'Buenos Aires'},
    { id: '4' , value: 'catamarca', display: 'Catamarca'},
    { id: '5' , value: 'chaco', display: 'Chaco'},
    { id: '6' , value: 'chubut', display: 'Chubut'},
    { id: '7' , value: 'cordoba', display: 'Córdoba'},
    { id: '8' , value: 'corrientes', display: 'Corrientes'},
    { id: '9' , value: 'entre rios', display: 'Entre Ríos'},
    { id: '10', value: 'formosa', display: 'Formosa'},
    { id: '11', value: 'jujuy', display: 'Jujuy'},
    { id: '12', value: 'la pampa', display: 'La Pampa'},
    { id: '13', value: 'la rioja', display: 'La Rioja'},
    { id: '14', value: 'mendoza', display: 'Mendoza'},
    { id: '15', value: 'misiones', display: 'Misiones'},
    { id: '16', value: 'neuquen', display: 'Neuquén'},
    { id: '17', value: 'rio negro', display: 'Río Negro'},
    { id: '18', value: 'salta', display: 'Salta'},
    { id: '19', value: 'san juan', display: 'San Juan'},
    { id: '20', value: 'san luis', display: 'San Luis'},
    { id: '21', value: 'santa cruz', display: 'Santa Cruz'},
    { id: '22', value: 'santa fe', display: 'Santa Fe'},
    { id: '23', value: 'santiago del estero', display: 'Santiago del Estero'},
    { id: '24', value: 'tierra del fuego', display: 'Tierra del Fuego'},
    { id: '25', value: 'tucuman', display: 'Tucumán'},
]