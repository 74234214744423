import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Catalog} from '../models/models.model';

@Injectable()

export class CatalogService extends ModelService<Catalog>  {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'search', Catalog);
  }
}

