import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Offer } from '../../../models/models.model';
import { UrlService } from '../../../services/url.service';
import { PriceService } from '../../../services/price.service';
import { StoreService } from '../../../services/store.service';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class OfferComponent implements OnInit {
  offer = new Offer;
  isLoaded = false;
  description: string;
  pointsEquivalence: number

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<OfferComponent>,
              private urlService: UrlService,
              private storeService: StoreService) { }

  ngOnInit() {
    this.offer = this.data;
    this.isLoaded = true;
    this.description = this.data.description ? this.data.description : '';
    document.getElementById("description").innerHTML = this.description
    //this.storeService.currentStore();
    this.storeService.getStore().subscribe(res => this.pointsEquivalence = res.visa_puntos_equivalence);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  goDespegar() {
    this.dialogRef.close();
    this.urlService.navigate('https://www.macro.com.ar/bancainternet/');
  }

  ceilPoints(price){
    return Math.ceil(price / this.pointsEquivalence);
  }

  floatParse(attr) {
    return PriceService.humanize(attr);
  }

}
