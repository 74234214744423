import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-bna-xml',
  templateUrl: './bna-xml.component.html',
  styleUrls: ['./bna-xml.component.scss']
})
export class BnaXmlComponent {

  isLoading = true;
  xmlText = '';
  xmlSub: Subscription;

  constructor(public http: HttpClient) {}

  ngOnInit() {
    this.xmlSub = this.http.get(
      'https://api-bna.avenida.com/api/angular_app/products.xml?store_id=41', 
      {
        headers: {
          'api-key': 'J0NdKyZt9Kf96Eny96RMHQ',
          'auth-token': 'eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJleHAiOjE2NDQ5NTkwNTgsImlzcyI6ImF2ZW5pZGEiLCJhdWQiOiJjbGllbnQifQ.q5ACKjPTn4C13SfJXcBVZFOrqxwF2aO_RPAlFhSskwI',
        },
        responseType: 'text'
      }
    )
    .pipe(
      tap(r => {
        this.isLoading = false;
        this.xmlText = r
      }),
    )
    .subscribe();
  }

  ngOnDestroy() {
    if (this.xmlSub) this.xmlSub.unsubscribe();
  }
}
