//Angular
import { Injectable } from '@angular/core';
//Services
import { HttpService } from './http.service';
import { PointsService } from './points.service';
import { CustomerService } from './customer.service';
import { RelationService } from './model.service';
import { LocalStorage } from './local_storage.service';
import { ProfileService } from './account/profile.service';
import { AuthenticationService } from './authentication.service';
//Models
import { CheckoutCart } from '../models/models.model';
import { TechnisysUser } from '../models/models.model';
import { map } from 'rxjs/operators';

@Injectable()
export class TechnisysService extends CustomerService{
  checkoutCart: CheckoutCart;
  sessionExpires: number;
  userTechnisys: TechnisysUser = new TechnisysUser;

  constructor(http: HttpService,
              localstorage: LocalStorage,
              pointsService: PointsService,
              profileService: ProfileService,
              relationService: RelationService,
              private authenticationService: AuthenticationService)
              {super(http,localstorage,pointsService,profileService,relationService)}

  login(params, purchase_id) {
    return this.http.basePost('sessions/technisys_login',
      JSON.stringify({
        technisys_login: params,
        purchase_id: purchase_id })
    ).pipe(
      map(
        (response: any) => {
          if (response.success) {
            this.setTechnisysUser(response)
          }
          return response;
        }
      )
    );
  }

  setTechnisysUser(technisisUser: TechnisysUser) {
    this.localstorage.set('auth_token', technisisUser.token);
    this.http.addHeader('auth-token', technisisUser.token);
    this.userTechnisys.setTechnisysUser(technisisUser);
  }

  getTechnisysUser(): TechnisysUser {
    return this.userTechnisys;
  }

  authentication() {
    this.authenticationService.broadcastLogin();
  }

}
