import { AfterViewInit, Component, OnInit, HostListener } from '@angular/core';
import { ComponentLanding } from "../../../../../models/models.model";
import { GtmBuilderService } from '../../../../../services/gtm_builder.service';
import { UrlService } from "../../../../../services/url.service";
import { Variant } from '../../../../../models/models.model';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation, Swiper } from "swiper/core";
@Component({
  selector: 'app-cols4-variants-v1',
  templateUrl: './cols4-variants-v1.component.html',
  styleUrls: ['./cols4-variants-v1.component.sass']
})
export class Cols4VariantsV1Component implements OnInit {
  component: ComponentLanding;
  slug: string;
  mobile: boolean = false;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols4_variants_v1';
  }

  constructor(private gtmBuilderService: GtmBuilderService,
              private _urlService: UrlService
              ) {}

  ngOnInit() {
    this.slug = 'landing-featured-products-' + this.component.id;
    this.mobile = window.innerWidth <= 434
    this.gtmBuilderService.builderHome(this.component);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth >= 434 ? false : true;
  }

  getImages(){
    let variants: Variant[] = []
    this.component.data.variants.forEach((element) => {
      if (element.url) variants.push(element);
    });
    return variants
  }

  navigate(item) {
    this._urlService.navigate(this._urlService.linkSelector(item));
  }
}
