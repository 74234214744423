import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
@Injectable({
  providedIn: 'root'
})
export class ReservableService {

  constructor(
    private http: HttpService
  ) { }

  confirmarReserva( params: any ) {
    return this.http.post('integration/reservable_purchase', params );
  }

}
