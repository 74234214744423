// Angular
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Other
import { Offer } from 'models/models.model';

@Injectable()

export class DespegarService {
    offers: any;

    constructor(private http: HttpClient) {}

    despegarGet(url) {
        if (typeof this.offers === 'undefined') {
            const headers = new HttpHeaders({'Content-Type': 'application/json'});
            return this.http.get(url, { headers: headers }).toPromise().then(data => data as Offer);
        }
    }
}
