<swiper
      [spaceBetween]="1"
      [centeredSlides]="false"
      [autoplay]="{
        delay: 3000,
        disableOnInteraction: false
        }"
      [slidesPerView]="2"
      [slidesPerGroup]="1"
      [loop]="true"
      [resizeObserver]="true"
      [navigation]="true"
      class="slider-component"
      >

  <ng-container *ngFor="let slide of getImages()">

    <ng-template swiperSlide class="swiperImg">
      <a *ngIf="slide.image" (click)="navigate(slide)">
        <img class="coverImg" [src]="slide.image" alt="covering" />
      </a>
    </ng-template>

  </ng-container>

</swiper>
<!--
<div class="slider-component row no-gutters" *ngIf="component">

  <div class="col col-md-8">
    <div class="slider multislides js_multislides-{{component.id}}">
      <div class="frame js_frame">
        <ul class="slides js_slides">
          <li class="js_slide" *ngFor="let slide of component.data.slides">
            <a class="pointer" (click)="navigate(slide)">
              <img [lazyLoad]="slide.image" alt="" [offset]="100">
            </a>
          </li>
        </ul>
        <span class="js_prev prev">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
        </span>
        <span class="js_next next">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
      </div>
    </div>
  </div>

  <div class="col col-md-4 banner-panel d-none d-sm-none d-md-inline-block">
    <a class="pointer" (click)="bannerClick()"><img [lazyLoad]="component.data.banner.image" [alt]="component.data.banner.title" [offset]="100"></a>
  </div>

</div>
<div class="clearfix"></div>
-->
