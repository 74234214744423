import { StoreService } from './store.service';
// Angular
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// Services
import { UrlService } from './url.service';
import { HttpService } from './http.service';
import { PointsService } from './points.service';
import { CustomerService } from './customer.service';
import { UserIdleService } from './user-inactivity.service';
import { RelationService } from './model.service';
import { LocalStorage } from './local_storage.service';
import { ProfileService } from './account/profile.service';
import { AuthenticationService } from './authentication.service';
// Models
import { CheckoutCart } from '../models/models.model';
import { MacroUser } from '../models/models.model';
//Components
import { UpdateProfileDialogComponent } from '../app/dialogs/updateprofile/updateprofile.dialog.component';
//Other
import { Observable, interval, Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { QualtricsDataService } from './third-party/qualtrics-data.service';
import { QualtricsService } from './third-party/qualtrics.service';

@Injectable()

export class MacroService extends CustomerService {
    checkoutCart: CheckoutCart;
    sessionExpires: number;
    userMacro: MacroUser;
    selecta = new Subject<boolean>();

    private _isUserSelecta: BehaviorSubject<boolean> = new BehaviorSubject(false)
    userSelecta$ = this._isUserSelecta.asObservable();

    constructor(http: HttpService,
                localstorage: LocalStorage,
                pointsService: PointsService,
                profileService: ProfileService,
                relationService: RelationService,
                private dialog: MatDialog,
                private urlService: UrlService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private _qualtricsService: QualtricsService,
                private _qualtricsDataService: QualtricsDataService,
                private userIdleService:UserIdleService,
                private _storeService: StoreService) {
                    super(http, localstorage, pointsService, profileService, relationService)
                    this.userMacro = new MacroUser();
                }

    getToken(macroToken) {
        this.http.post('sessions/macro_login', {macro_token: macroToken}).subscribe(
            (response: MacroUser) => {
                this.sessionExpires = new Date(response.valid_until).valueOf()
                if (response.success) {
                    this._qualtricsDataService.cleanUserData();
                    this._qualtricsDataService.setUserData(response.user_info)
                    this.setMacroCustomer(response);
                    this.authentication();

                    // Start Session Timer
                    this.userIdleService.initilizeSessionTimeout();
                    this.userIdleService.userIdlenessChecker.subscribe((status: string) => {
                        if (status == 'STOPPED_TIMER') {
                            this.router.navigate(['/session-expired'])
                        }
                    });

                      if (this.userMacro.is_select_user) {
                        //AÑADIR QUALTRICS ACA? NO LO SE
                        this.setBodySelectaId(true);
                        this.selecta.next(true);
                        this._isUserSelecta.next(true);
                    }
                    if (this.userMacro.temporary_email || !this.userMacro.doc_number) { this.updateProfile(); }
                    //Llamar de nuevo a currentStore para actualizar la data de la tienda - Pasar a otro lado
                    this._storeService.currentStore().subscribe();
                } else {
                    this.macroLogout();
                }
            },
            (error) => {
                console.log(error);
            }
        )
    }

  setMacroCustomer(macroCustomer: MacroUser) {
      this.userMacro.setMacroUser(macroCustomer);
      this.setPoints(this.userMacro.points);
      this.localstorage.set('cobis', this.userMacro.cobis);
      this.localstorage.set('name', this.userMacro.getFullName());
      this.localstorage.set('session_expires', this.sessionExpires);
      this.customerUpdate.next(true);
      this.router.navigate(['/']);
  }

    getMacroCustomer(): MacroUser {
        return this.userMacro;
    }

    isSelecta() {
        this.init().subscribe(
            (response) => {
                if (response.is_select_user) {
                    this.setBodySelectaId(true);
                    this.selecta.next(true);
                } else {
                    this.setBodySelectaId(false);
                    this.selecta.next(false);
                }
            })
    }

    getCobis() {
        return this.localstorage.get('cobis');
    }

  destroyMacroCustomer() {
      this.localstorage.remove('name');
      this.localstorage.remove('cobis');
      this.localstorage.remove('points');
      this.localstorage.remove('session_expires');
      delete this.customer;
      delete this.userMacro;
  }

  authentication() {
      this.localstorage.set('auth_token', this.userMacro.token);
      this.http.addHeader('auth-token', this.userMacro.token);
      this.authenticationService.broadcastLogin();
  }

  isValidSession(): boolean {

    const expires = this.localstorage.get('session_expires');
    return expires ? (expires > (new Date().valueOf())) : false;

  }

  sessionStatus(): Observable<boolean> {
    const expiresIn = this.localstorage.get('session_expires');
    const valid = interval(1000)
      .pipe(
        map((x) => !expiresIn ? false : (expiresIn > new Date().valueOf()))
    );

    return valid
  }

  redirectToLogin() {
      this.destroyMacroCustomer();
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Android")) {
          this.urlService.navigate('https://play.google.com/store/apps/details?id=ar.macro');
      } else if (userAgent.includes("iPhone")) {
          this.urlService.navigate('https://apps.apple.com/us/app/macro/id1173611617');
      } else {
          this.urlService.navigate("https://www.macro.com.ar/bancainternet/");
      }
  }

  updateProfile() {
      this.dialog.open(UpdateProfileDialogComponent, {
          disableClose: true,
          width: '500px'
      });
  }

  setBodySelectaId(param: Boolean) {
      const body = document.getElementsByTagName("body")[0];
      param ? body.setAttribute("id", "selecta") : body.setAttribute("id", "");
  }

    macroLogout() {
      this.selecta.next(false)
      this.setBodySelectaId(false);
      this.destroyMacroCustomer();
      this.authenticationService.logout();
  }

}
