import {Subject} from "rxjs";
import {LoaderState} from "../interfaces/loader_state.interface";
import { Injectable } from "@angular/core";

@Injectable()
export class LoaderService {
  private loaderSubject = new Subject<LoaderState>();

  constructor() { }

  show() {
    this.loaderSubject.next(<LoaderState>{show: true});
  }

  hide() {
    this.loaderSubject.next(<LoaderState>{show: false});
  }

  get() {
    return this.loaderSubject;
  }
}
