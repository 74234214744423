<div class="container" id="sitemap-container">
  <div class="row">
    <h3>Mapa de sitio.</h3>
  </div>
  <div class="row">
    <div class="col-md-4 bg-gray" *ngFor="let link_item of SitemapLink">
      <h2><a [href]="link_item.getUrl()">{{ link_item.getName() }}</a></h2>
      <ul *ngIf="link_item.childs !== undefined && link_item.childs.length > 0">
        <li *ngFor="let child of link_item.childs">
          <a [href]="child.getUrl()"> {{ child.getName() }}</a>
        </li>
      </ul>
      <div>
  </div>
</div>
