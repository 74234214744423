<div class="body">
  <div class="title">
    <h4>Venta Telefónica</h4>
    <button title="Close (Esc)" type="button" class="close" (click)="closeDialog()"><i class="fa fa-times"></i></button>
  </div>
  <hr>
  <div class="phone">
    <h4>{{ env.phone }}</h4>
  </div>
  <div class="description">
    Lunes a Viernes de 9:00 a 18:00 hs
  </div>
</div>
