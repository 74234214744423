import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrazzyEggService {
    addScript() {
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = '//script.crazyegg.com/pages/scripts/0109/1007.js';
        document.head.append(script);
    }
}
