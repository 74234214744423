import {Injectable} from '@angular/core';
import {HttpService} from '../http.service';
import {ModelService} from '../model.service';
import {RelationService} from '../model.service';
import {Coupon} from "../../models/models.model";

@Injectable()

export class CouponsService extends ModelService<Coupon> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'my_account/coupons', Coupon);
  }
}
