<div *ngIf="env.name === 'Rapiclub'; then technisys else normal"></div>


<ng-template #normal>

  <ng-container *ngIf="spinnerIsActivated; then showSpinner else showLogin"></ng-container>

  <ng-template #showLogin>
    <form [formGroup]="loginForm" (ngSubmit)="submit()" *ngIf="!showRecovery">
      <div class="form-group mt-3" >
          <label for="email">E-mail</label>
          <input type="email" class="form-control" (focus)="clearErrors()" [ngClass]="{'is-invalid':(loginForm.controls['email'].errors && !loginForm.controls['email'].pristine)}" formControlName="email"/>
          <div class="invalid-feedback" *ngIf="loginForm.controls['email'].errors">
            <div [hidden]="!loginForm.controls['email'].errors.required">El E-mail es <strong>requerido</strong>.</div>
            <div [hidden]="!loginForm.controls['email'].errors.email">El E-mail es inválido.</div>
          </div>
      </div>
      <div class="form-group" >
          <div class="form-group">
            <label>Contraseña</label>
            <div class="input-group" id="show_hide_password">
              <input
              [type]="passwordType"
              class="form-control "
              style="border-right: none;"
              (focus)="clearErrors()"
              [ngClass]="{'is-invalid':(loginForm.controls['password'].errors && !loginForm.controls['password'].pristine)}"
              formControlName="password"/>
              <div class="form-control col-2 text-center my-auto p-1" style="width:10px !important;cursor:pointer;background:white;border: 0px 0.2px 0.2px 0.2px solid grey" (click)="showPassword()">
                <a><i [ngClass]="passwordType === 'password' ? 'fa fa-eye-slash':'fa fa-eye'"></i></a>
              </div>
            </div>
          </div>
          <div class="invalid-feedback" *ngIf="loginForm.controls['password'].errors">
            <div [hidden]="!loginForm.controls['password'].errors.required">La contraseña es <strong>requerida</strong>.</div>
            <div [hidden]="!loginForm.controls['password'].errors.minlength">La contraseña es tiene que ser mayor a 6 caracteres</div>
          </div>
      </div>

      <div *ngIf="errors" class="alert alert-danger">
        <span>{{ errors }}</span>
      </div>

      <!--Recaptcha for BancoNacion-->
      <ng-container *ngIf="env.name === 'BancoNacion' ">
        <div class="mb-2">
          <recaptcha
            [size]="'normal'"
            [hide]="false"
            (captchaResponse)="onCaptchaResponse($event)"
            (captchaExpired)="onCaptchaExpired()">
          </recaptcha>
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block"
            [disabled]="loginForm.invalid || hasValidateRecaptcha"
            >
            Login
          </button>
        </div>

        <div class="form-group">
          <button class="btn btn-outline-primary btn-block"
            [routerLink]="'/register/'+env.name"
            >
            Registrarme</button>
        </div>

        <!-- <div class="form-group">
          <button class="btn btn-primary btn-block">
            Login
          </button>
        </div> -->

        <!-- <div class="form-group">
          <button class="btn btn-outline-primary btn-block"
            [routerLink]="'/register/'+env.name">
            Registrarme</button>
        </div> -->

      </ng-container>

      <div class="form-group" *ngIf="env.name !== 'BancoNacion'">
          <button class="btn btn-primary btn-block">Login</button>
      </div>

      <a class="btn btn-block btn-social btn-facebook" *ngIf="facebook_app_id" (click)="signInWithFB()">
        <span class="fa fa-facebook"></span>
        Ingresar con facebook
      </a>

      <a class="btn btn-block text-primary" *ngIf="env.recovery_password" (click)="toggleRecovery()">
        Recuperar contraseña
      </a>

    </form>

    <div *ngIf="showRecovery">
      <form *ngIf="!recoverySuccess && !errors">
        <div class="form-group mt-3" >
            <label for="email">Ingresa el email con el que te registraste</label>
            <input type="email" class="form-control" (focus)="clearErrors()" [ngClass]="{'is-invalid':(recovery_email.errors && !recovery_email.pristine)}" [formControl]="recovery_email"/>
            <div class="invalid-feedback" *ngIf="recovery_email.errors">
              <div [hidden]="!recovery_email.errors.required">El E-mail es <strong>requerido</strong>.</div>
              <div [hidden]="!recovery_email.errors.email">El E-mail es inválido.</div>
            </div>
            <br>
            <div class="form-group">
              <button class="btn btn-primary btn-block" (click)="recoveryPassword()">Recuperar</button>
          </div>
        </div>
      </form>
      <div *ngIf="recoverySuccess && !errors">
        <div class="form-group">
          <p class="btn btn-block text-primary">Te enviamos un correo para que puedas terminar el proceso</p>
        </div>
      </div>
      <div *ngIf="errors">
        <div class="form-group">
          <p class="btn btn-block text-danger">{{errors}}</p>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #showSpinner>
    <div class="d-flex flex-column justify-content-center align-items-center p-5 rounded spinner-modo">
      <div class="row my-2">
        <mat-spinner></mat-spinner>
      </div>

      <div class="row mt-4 text-center">
        <h6 class="mt-4">Estamos verificando sus datos, espere por favor.</h6>
      </div>
    </div>
  </ng-template>


</ng-template>

<ng-template #technisys>
    <form [formGroup]="loginTechnisysForm" (ngSubmit)="submitTechnisys()">
        <div class="form-group mt-3" >
          <label for="username">Usuario</label>
          <input type="text" class="form-control" (focus)="clearErrors()" [ngClass]="{'is-invalid': (loginTechnisysForm.controls['username'].errors && !loginTechnisysForm.controls['username'].pristine)}" formControlName="username"/>
          <div class="invalid-feedback" *ngIf="loginTechnisysForm.controls['username'].errors">
            <div [hidden]="!loginTechnisysForm.controls['username'].errors.required">El usuario es <strong>requerido</strong>.</div>
          </div>
        </div>
        <div class="form-group" >
          <label for="second_factor">Clave</label>
          <input type="password" class="form-control" (focus)="clearErrors()" [ngClass]="{'is-invalid': (loginTechnisysForm.controls['second_factor'].errors && !loginTechnisysForm.controls['second_factor'].pristine)}" formControlName="second_factor"/>
          <div class="invalid-feedback" *ngIf="loginTechnisysForm.controls['second_factor'].errors">
            <div [hidden]="!loginTechnisysForm.controls['second_factor'].errors.required">La clave es <strong>requerida</strong>.</div>
          </div>
        </div>
        <div class="form-group" >
          <label for="password">Contraseña</label>
          <input type="password" class="form-control" (focus)="clearErrors()" [ngClass]="{'is-invalid': (loginTechnisysForm.controls['password'].errors && !loginTechnisysForm.controls['password'].pristine)}" formControlName="password"/>
          <div class="invalid-feedback" *ngIf="loginTechnisysForm.controls['password'].errors">
            <div [hidden]="!loginTechnisysForm.controls['password'].errors.required">La contraseña es <strong>requerida</strong>.</div>
          </div>
        </div>

        <div *ngIf="errors" class="alert alert-danger">
          <span>{{ errors }}</span>
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block">Login</button>
        </div>
      </form>
</ng-template>

