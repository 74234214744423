<div class="gray-color override-with-white-color" id="catalog-aerolineas">
  <main>
    <div class="container pt-4 pb-5">

      <div class="row">
        <div class="col-md-12">
          <h3 style="font-weight: 600;">Millas Aerolíneas Plus</h3>
          <hr>
        </div>
      </div>

      <div class="row">

        <div class="col-lg-7">
          <div id="promo-aerolineas-argentinas">
            <img class="img-fluid mb-3" src="/assets/stores/current_store/images/macropremiaplus.png" alt="macropremiaplus"/>
          </div>
        </div>

        <div class="col-lg-5">
          <div class="card" *ngIf="ready">

            <div id="checkout-aerolineas-argentinas">
              <!--<div *ngIf="!isLoggedIn" class="alert alert-danger" role="alert">
                Inicia sesión para obtener este beneficio.
              </div>-->
              <mat-horizontal-stepper linear='true' #aerolineasArgentinasStepper>

                <!-- Points and user / First Step-->
                <mat-step [stepControl]="selectMilesGroup.valid">

                  <form [formGroup]="selectMilesGroup" *ngIf="close === false" class="sub-title-macro">
                    <div class="puntos-millas col-md-12 text-center py-3">
                      <span>{{ store.points_equivalent }} {{ plurals('PUNTO', store.points_equivalent) }} </span>
                      <img [src]="macroLogo" class="macro-logo" alt="macro-logo"/>
                      <span> = {{ store.miles_equivalent }} {{ plurals('MILLA', store.miles_equivalent) }}</span>
                      <img [src]="aerolineasArgentinasLogo" class="AA-logo" alt="AA-logo">
                    </div>
                    <div class="canje-millas col-md-12 text-left py-3">

                      <div class="row mx-0">
                        <p><strong>Ingresá los puntos Macro Premia que querés canjear:</strong></p>
                        <mat-form-field appearance="outline" class="col-md-6 text-left w-100">
                          <input  matInput type="number"
                                  formControlName="selectPoints"
                                  autocomplete="off"
                                  (change)="pointsValidator($event)"
                                  (blur)="pointsValidator($event)"
                                  (keypress)="cleanInvalidCharacter($event)"
                                  step="{{pointsEquivalence}}"
                                  placeholder="Ej. 1000"
                                  required
                                  >
                        </mat-form-field>

                        <span class="text-left ml-2 align-self-center">= {{ pointsToMiles() }} Millas Aerolíneas Plus</span>

                        <br>
                      </div>
                      <div class="mb-4 text-danger" *ngIf="selectMilesGroup.controls['selectPoints'].errors?.pattern">
                        Valor inválido.
                      </div>
                      <p class="mb-4 text-muted" *ngIf="!multiplo">Debe ingresar un múltiplo de {{ pointsEquivalence }}</p>

                      <div class="row mx-0">
                        <p><strong>Cargá los datos Aerolíneas Plus para acreditar las Millas:</strong></p>
                        <mat-form-field appearance="outline" class="col-md-6 text-left px-1">
                          <mat-select matSelect formControlName="selectDocType" required>
                            <mat-option *ngFor="let docType of docTypes" [value]="docType.value">{{ docType.slug }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-6 text-left px-1">
                          <input matInput formControlName="selectAerolineasPlus" placeholder="Ej. 123456789" required>
                        </mat-form-field>
                      </div>

                    </div>
                    <div *ngIf="error" class="error alert alert-danger mt-3 mb-4" role="alert">
                      <span class="alert-heading">{{ error }}</span>
                    </div>
                    <div>
                      <button class="btn btn-lg btn-primary btn-block" [disabled]="selectMilesGroup.controls['selectPoints'].errors?.pattern" (click)="userValidator(aerolineasArgentinasStepper)">
                        <mat-icon *ngIf="buttonLoader && selectMilesGroup.valid" style="padding-top: 6px;">
                          <mat-spinner color="light" diameter="20"></mat-spinner>
                        </mat-icon>
                        Continuar
                      </button>
                    </div>
                    <div class="my-3">
                      <p><small>Las Millas estarán disponibles en un máximo de 72 hs. hábiles. Podrás ver esta información ingresando con tu usuario en el Sitio de <a href="https://www.aerolineas.com.ar/es-ar/aerolineas_plus">Aerolíneas Plus.</a></small></p>
                    </div>
                    <div class="values my-3">
                      <ul>
                        <li class="mb-2">· Conocé la Tabla de Millas Aerolíneas Plus <strong><a href="http://www.aerolineas.com.ar/es-ar/viajerosfrecuentes/disfrutatusmillas" target="_blank">aquí</a></strong></li>
                        <!-- <li class="mb-2">· Y además descubrí la opción Millas + Pesos Argentina de Aerolíneas Plus <strong><a href="http://www.aerolineas.com.ar/es-ar/aerolineasplus/promociones/2" target="_blank">aquí</a></strong></li> -->
                        <li class="mb-2">· Si no sos cliente de Aerolíneas Plus asociate <strong><a href="http://www.aerolineas.com.ar/es-ar/aerolineasplus/acercade?Inscripcion_Plus=si" target="_blank">aquí</a></strong></li>
                      </ul>
                    </div>
                  </form>
                  <div class="puntos-millas col-md-12 text-center py-3" *ngIf="close === true">
                    <img src="/assets/stores/current_store/images/logo-macropremia.png" class="macro-logo" alt="macro-logo"/>
                    <img src="/assets/stores/current_store/images/aerolineas-plus.png" class="AA-logo" alt="AA-logo">
                    <br>
                    <br>
                    <br>
                    <span class="mb-3"> No es posible canjear puntos en este momento </span>
                  </div>
                </mat-step>

                <mat-step [stepControl]="confirmEmail.valid">
                  <div class="sub-title-macro">
                    <h5>Confirmar canje de Millas Aerolíneas Plus</h5>

                    <div class="values my-3">
                      <div class="mb-4" style="font-size: 17px;">
                        <label><b>{{ this.selectMilesGroup.get('selectPoints').value }} PUNTOS</b> MACRO PREMIA POR <b>{{ pointsToMiles() }} MILLAS</b> AEROLÍNEAS PLUS</label><br><br>
                        <span>Acreditados a la siguiente cuenta de Aerolíneas Plus:</span>
                      </div>
                      <div class="mb-2">
                        <label>NOMBRE Y APELLIDO:</label><br>
                        <strong>{{ nombre }}</strong>
                      </div>
                      <div class="mb-2">
                        <label>NÚMERO DE SOCIO</label><br>
                          <strong>{{ socio }}</strong>
                      </div>
                      <div class="mb-2">
                        <label>NÚMERO DE DOCUMENTO</label><br>
                        <strong>{{ documento }}</strong>
                      </div>
                  </div>

                    <div>
                      <span>Las millas estarán disponibles dentro de las proximas 72hs. hábiles</span><br>
                      <hr>
                      <span>Completá tu email para que te informemos en cuanto estén disponibles:</span>
                    </div>
                    <mat-form-field appearance="outline" class="w-100 text-left py-3">
                      <input matInput placeholder="E-MAIL" [formControl]="confirmEmail">
                    </mat-form-field>
                    <button class="btn btn-lg btn-primary btn-block" (click)="acreditMiles(aerolineasArgentinasStepper)">CONFIRMAR</button>
                    <button class="btn btn-sm btn-link btn-block" (click)="backToForm(aerolineasArgentinasStepper)">Volver</button>
                  </div>
                </mat-step>
                <mat-step>
                  <div class="col-lg-12 float-center text-center">
                    <div id="transaction-aerolineas-argentinas" class="row px-0">
                      <div class="w-100" *ngIf="!processData">
                        <div class="procesando col-md-12 text-center py-3">
                          <h4 class="my-2">Su operación está<br>siendo procesada</h4>
                          <h6 class="mx-auto mb-3"></h6>
                          <mat-spinner class="mx-auto my-5"></mat-spinner>
                        </div>
                      </div>
                      <div *ngIf="processData">
                        <div class="procesando col-md-12 text-center p-4 py-5 mb-3">
                          <h4 *ngIf="error">No fue posible completar la transacción</h4>
                          <h4 *ngIf="!error">Transacción exitosa</h4>

                          <div *ngIf="!error" class="values my-3 text-left">
                            <p class="pb-4 px-2">* Para su control, usted recibirá en instantes un e-mail con los datos de su compra.</p>

                            <table class="table table-sm text-left">
                              <tbody>
                                <tr>
                                  <th>Nombre y apellido:</th>
                                  <td>{{nombre}}</td>
                                </tr>
                                <tr>
                                  <th>E-mail:</th>
                                  <td>{{email}}</td>
                                </tr>
                                <tr>
                                  <th>Fecha / Hora:</th>
                                  <td>{{ fecha | amLocale:'es' | amDateFormat:'LL, h:mm:ss a' }}</td>
                                </tr>
                                <tr>
                                  <th>Código de operación:</th>
                                  <td>{{operacion}}</td>
                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>

                        <a *ngIf="!error" class="mt-3 btn btn-lg btn-primary btn-block" [routerLink]="['/']">CONTINUAR NAVEGANDO</a>

                        <div *ngIf="error">
                          <button class="btn btn-primary btn-lg btn-block" (click)="restart(aerolineasArgentinasStepper)">REINTENTAR</button>
                          <a class="btn btn-sm btn-lg btn-block" routerLink="/">VOLVER</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-step>
              </mat-horizontal-stepper>
            </div>

          </div>
        </div>
      </div>

      <!-- Legal Row -->
      <div class="row">
        <div class="col-12 py-4">
          <p class="text-justify format-p">
            <!--Legales viejos-->
            <small class="text-muted">
              Solicitud de Puntos Macro Premia por Millas Aerolíneas Plus válido del 01/07/2023 al 31/12/2023 inclusive. Sin mínimo o máximo exigido para la solicitud. La validación de la solicitud estará sujeta a la disponibilidad de puntos por parte del cliente que los quiere convertir y habilitación del socio Aerolíneas Plus para recibir las millas. El cliente podrá solicitar a favor de cualquier socio Aerolíneas Plus. La acreditación de las millas provenientes de la solicitud rige bajo las condiciones del programa Aerolíneas Plus. La transferencia de puntos Macro Premia a Aerolíneas Plus no extiende la vigencia de las millas Aerolíneas Plus. La solicitud de Puntos Macro Premia por Millas Aerolíneas Plus no podrá ser anulada luego de su aprobación por parte de Aerolíneas Argentinas. La no utilización de las millas o la asignación equívoca de las mismas por instrucción del cliente no habilita el reintegro de los puntos. El programa Aerolíneas Plus pertenece a Aerolíneas Argentinas, único responsable de la correcta administración de las millas acumuladas por el titular. El socio deberá ingresar a Aerolineas Argentinas para informarse acerca del proceso de reserva de pasajes, disponibilidad y condiciones de los mismos. Aerolíneas Argentinas S.A.: Rafael Obligado S/N, Terminal 4, 6to Piso, Aeroparque Jorge Newbery – CABA - CUIT 30-64140555-4. Los términos y condiciones del programa Aerolíneas Plus son propiedad de Aerolíneas Argentinas S.A. y se encuentran disponibles en www.aerolineas.com.ar o en el teléfono 0810-222-86527.
              Consulte más información, en <a href="https://macro.com.ar" class="" target="_blank">macro.com.ar</a>, llamando al 0810-555-2355 o en la Sucursal más cercana a su domicilio.
            </small>
          </p>
        </div>
      </div> <!-- End Legal Row -->

    </div>
  </main>
</div>
