import { Injectable } from "@angular/core";
@Injectable()
export class LocalStoragePolyfill {
  data = {};

  get<T>(key: string): T {
    return this.data[key] === undefined ? null : this.data[key];
  }

  set(key: string, value: any): boolean {
    this.data[key] = value;
    return true;
  }

  remove(...keys: Array<string>): boolean {
    keys.forEach((key: string) => {
      delete this.data[key];
    });

    return true;
  }
}
