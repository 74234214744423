<div *ngIf="offers" id="catalog-despegar">
  <main>
    <div class="catalog-title">
      <div class="row">
        <div class="col-md-6">
          <h1 class="title" itemprop="name">{{ catalog_name }}</h1>
        </div>
        <div class="col-md-6 text-right">
          <mat-form-field appearance="outline" class="w-70">
            <mat-label>Ordenar por:</mat-label>
            <mat-select>
              <mat-option (click)="sortOffers(true)" value="{{optionValue}}">Precio bajo al más alto</mat-option>
              <mat-option (click)="sortOffers(false)" value="{{optionValue}}">Precio alto al más bajo</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <aside>
        <div class="filter">
          <div class="filter-option">
            <h4>Categorías</h4>
            <ul>
              <li [routerLinkActive]="['active']">
                <a class="facet-item" (click)="filterOffers('all')">Todo</a>
              </li>
              <li *ngFor="let filter of offerType" >
                <a class="facet-item"  (click)="filterOffers(filter.value)">{{ filter.name }}</a>
              </li>
            </ul>
            <hr>
          </div>
        </div>
      </aside>
    </div>
    <section class="catalog-content">
      <div class="variants-list-despegar d-block despegar-offer">
        <div class="row">
          <article class="col-md-3 col-6 p-1 mb-2" *ngFor="let offer of offers" (click)="openOffer(offer)">  
            <div class="card mb-3">
              <div class="img-despegar" [style.background-image]="'url(' + offer.image + ')'"></div>
              <!--<img src="{{ offer.image }}" class="card-img-top" alt="{{ offer.name }}">-->
              <div class="card-body card-body d-flex flex-column text-center">
                <div class="title-panel d-block">
                  <h6 class="d-inline-block">{{ offer.name }}</h6>
                </div>
                <div class="info-panel mt-auto">
                  <!--<p class="offer_price">{{ floatParse(ceilPoints(offer.price_detail.total)) }} Puntos</p>-->
                  <p class="offer_price">${{ floatParse(offer.price_detail.total) }}</p>
                  <hr>
                  <p class="offer_price">{{ getAmountRelation(ceilPoints(offer.price_detail.total), offer.price_detail.total ) }}</p>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
    <!-- Legal Row -->
    <div class="row">
      <div class="col-12 py-4 mt-3 text-justify">
        <p><small class="text-muted">Oferta vigente en el día de la fecha. Sujeta a variación en función a la elección de las condiciones de vuelo, hotel, paquete, actividad, o alquiler de auto seleccionado. Aéreos, hoteles, paquetes, actividades y alquiler de autos son ofrecidos por “DESPEGAR.COM”. Con Tarjetas de Crédito de Banco Macro, únicamente a través de Macro Premia / Despegar. Para acceder a la oferta en modalidad Pesos deberá abonar la suma en pesos indicada con las tarjetas de crédito de Banco Macro, no siendo requisito contar con Puntos Macro Premia disponibles para concretar la compra. “DESPEGAR.COM”: CUIT 30701307115, Av. Jujuy 2013 CABA- Leg evt 10680 - Disp 0599/2000. No válido para Tarjetas de Crédito de Empresa y/o Agro. No acumula con otras promociones. Los establecimientos, los productos y/o servicios que comercializan o la calidad de los mismos no son promocionados ni garantizados por Banco Macro.<br> Consulte más información, en <a href="https://macro.com.ar">macro.com.ar</a>, llamando al 0810-555-2355 o en la Sucursal más cercana a su domicilio.</small></p>
      </div>
    </div> <!-- End Legal Row -->

  </main>
</div>
<app-skeleton-loader [type]="'catalog'" *ngIf="!offers"></app-skeleton-loader>