// BNA PIDE QUE SE PASE UN VALOR, ESTE VALOR SERIA EL INDICE DE LA NACIONALIDAD + 1
// EJEMPLO: 
// ARGENTINA DEBERIA TENER UN VALOR DE 1
// ALBANESA DEBERIA TENER UN VALOR DE 2
// PARA NO ESCRIBIR TODO EL ARREGLO OTRA VEZ, SE MAPEARA EL VALOR DONDE SE CONSUMA COMO SE EXPLICO EN LA LINEA 1 (INDICE + 1)
export const NACIONALIDADES = [ 
    'ARGENTINA',
    'ALBANESA',
    'ALEMANA',
    'ANDORRANA',
    'ARABE SAUDITA',
    'ARGELINA',
    'ARMENIA',
    'AUSTRALIANA',
    'AUSTRIACA',
    'BARBADENSE',
    'BELGA',
    'BIELORRUSA/BELARUSA',
    'BOLIVIANA',
    'BRASILEÑA',
    'BRITANICA',
    'BULGARA',
    'CAMERUNESA',
    'CANADIENSE',
    'CHECA',
    'CHILENA',
    'CHINA',
    'CHIPRIOTA',
    'COLOMBIANA',
    'CONGOLEÑA',
    'COSTARRICENSE',
    'CROATA',
    'CUBANA',
    'DANESA/DINAMARQUESA',
    'DOMINICANA',
    'ECUATORIANA',
    'EGIPCIA',
    'ESLOVACA',
    'ESLOVENA',
    'ESPAÑOLA',
    'ESTADOUNIDENSE',
    'ESTONIA',
    'FILIPINA',
    'FINLANDESA/FINESA',
    'FRANCESA',
    'GHANESA',
    'GRIEGA',
    'GUATEMALTECA',
    'GUYANESA',
    'HAITIANA',
    'HONDUREÑA',
    'HUNGARA',
    'INDIA',
    'INDONESIA',
    'IRANI',
    'IRAQUI',
    'IRLANDESA',
    'ISLANDESA',
    'ISRAELI',
    'ITALIANA',
    'JAMAIQUINA',
    'JAPONESA/NIPONA',
    'JORDANA',
    'KENIANA',
    'KUWAITI',
    'LIBANESA',
    'LIBERIANA',
    'LITUANA',
    'LUXEMBURGUESA',
    'MALAYA/MALASIA',
    'MARROQUI',
    'MEXICANA',
    'MONGOLA',
    'NEOCELANDESA',
    'NICARAGÜENSE',
    'NIGERIANA',
    'NORCOREANA',
    'NORUEGA',
    'PAKISTANI/PAQUISTANI',
    'PALESTINA',
    'PANAMEÑA',
    'PARAGUAYA',
    'PERUANA',
    'POLACA',
    'PORTUGUESA',
    'RUMANA',
    'RUSA',
    'SALVADOREÑA',
    'SAMOANA',
    'SINGAPURENSE',
    'SIRIA',
    'SUDAFRICANA',
    'SUECA',
    'SUIZA',
    'SURCOREANA',
    'TAILANDESA',
    'TAIWANESA',
    'TUNECINA',
    'TURCA',
    'UCRANIANA',
    'URUGUAYA',
    'VENEZOLANA',
    'VIETNAMITA',
    'YUGOSLAVA',
    'OTRAS',
]