import { Injectable } from "@angular/core";
import {Subject} from "rxjs";
import {TaxesDecorator} from "../decorators/taxes.decorator";

@Injectable({ providedIn: 'root', })
export class TaxesService {
  private taxesSubject = new Subject<TaxesDecorator>();
  private taxesDecorator = new TaxesDecorator(true, 0.00, 0.00, null, 0, 0, 0);

  constructor() { }

  fire(taxesDecorator: TaxesDecorator) {
    this.taxesDecorator = taxesDecorator;
    this.taxesSubject.next(taxesDecorator);
  }

  show() {
    this.taxesDecorator.show = true;
    this.taxesSubject.next(this.taxesDecorator);
  }

  hide() {
    this.taxesDecorator.show = false;
    this.taxesSubject.next(this.taxesDecorator);
  }

  get() {
    return this.taxesSubject;
  }
}
