import { Injectable } from '@angular/core';
import { HttpService } from '../../../../services/http.service';


import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError, Observable,  EMPTY } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { GatewayService } from '../../../../services/gateways/gateways.service';
import { LoaderService } from '../../../../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class GatewaysService extends GatewayService{

  protected headers: HttpHeaders;

  constructor(
          private http: HttpService,
          private loaderService: LoaderService
  ) {
    super();
  }

  generateToken(params: {} | any) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': this.apikey,
    });
    this.loaderService.show();
    return this.http.externalPost(this.endpoint + '/tokens', params, this.headers)
    .pipe(map((response: Response) => {
        this.loaderService.hide();
        return response;
      }))
    .pipe(catchError(
      (error: Response) => {
        this.loaderService.hide();
        return observableThrowError(['Revisar los datos ingresados']);
      }
    ));
  }

  toBin(creditCardNumber: any) {
    return +creditCardNumber.toString().substr(0, 6);
  }

  detectCreditBrand(number) {
    // Cards By Regex (Regula Expresion)
    const re = [
      { name: 'Visa Debit ', id: 31, reg: /^(4026|417500|4405|4844|4913|4917|483020|499859|402789|400276|417309|477053|405516|4517|451766|4894|491956|421738|446345|481550|446343|446344|400448|483002|446347|446346|408515|473719)\d+$/ },
      { name: 'Visa', id: 1, reg: /^4[0-9]{12}(?:[0-9]{3})?$/ },
      { name: 'noname', id: 15, reg: /^5[1-5][0-9]{14}$/ },
      { name: 'Amex', id: 65, reg: /^3[47][0-9]{13}$/ },
      { name: 'Diners', id: 8, reg: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/ },
      { name: 'Maestro', id: 106, reg: /^(5010|5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/ }, // BNA: 501041, 501080, 501081, 501068, 501082
      { name: 'Mastercard', id: 104, reg: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/ },



    ];

    for(let key in re) {
      if(re[key].reg.test(number)) {
        return re[key].id
      }
    }
  }
}
