<form [formGroup]="registerForm" (ngSubmit)="submit()" >
  <div class="form-group mt-3">
      <label for="email">E-mail</label>
      <input type="email" class="form-control" (focus)="clearErrors()" [ngClass]="registerForm.controls['email'].errors && !registerForm.controls['email'].pristine ? 'is-invalid' : ''" formControlName="email" />
      <div class="invalid-feedback" *ngIf="registerForm.controls['email'].errors">
        <div [hidden]="!registerForm.controls['email'].errors.required">El E-mail es <strong>requerida</strong>.</div>
        <div [hidden]="!registerForm.controls['email'].errors.email">El E-mail es inválido.</div>
      </div>
  </div>
  <div class="form-group">
      <label for="password">Contraseña</label>
      <input type="password" class="form-control" (focus)="clearErrors()" [ngClass]="registerForm.controls['password'].errors && !registerForm.controls['password'].pristine ? 'is-invalid' : ''" formControlName="password"/>
      <div class="invalid-feedback" *ngIf="registerForm.controls['password'].errors">
        <div [hidden]="!registerForm.controls['password'].errors.required">La contraseña es <strong>requerida</strong>.</div>
        <div [hidden]="!registerForm.controls['password'].errors.minlength">La contraseña es tiene que ser mayor a 6 caracteres</div>
      </div>
  </div>
  <div class="form-group">
      <label for="password_confirmation">Repetir Contraseña</label>
      <input type="password" class="form-control" (focus)="clearErrors()" [ngClass]="registerForm.controls['password_confirmation'].errors && !registerForm.controls['password_confirmation'].pristine ? 'is-invalid' : ''" formControlName="password_confirmation"/>
      <div class="invalid-feedback" *ngIf="registerForm.controls['password_confirmation'].errors">
        <div [hidden]="!registerForm.get('password_confirmation').hasError('required')">La contraseña es <strong>requerida</strong>.</div>
        <div [hidden]="!registerForm.get('password_confirmation').hasError('minlength')">Repetir Contraseña debe tener mínimo 6 caracteres.</div>
      </div>
  </div>

  <div *ngIf="errors" class="alert alert-danger">
    <span>{{ errors }}</span>
  </div>

  <div class="form-group">
      <button class="btn btn-primary btn-block">Registrarme</button>
  </div>

  <a class="btn btn-block btn-social btn-facebook" *ngIf="facebook_app_id" (click)="signInWithFB()">
    <span class="fa fa-facebook"></span>
    Registrar con facebook
  </a>
</form>
