import {Injectable} from '@angular/core';
import {HttpService} from './http.service';

@Injectable()

export class SuggestionsService {

  constructor(private http: HttpService) {
  }

  init(params: {}) {
    return this.http.get('search/suggestions', {query: params});
  }
}
