import {Injectable} from "@angular/core";
import { environment } from "../../environments/environment";
import { Product } from "../../models/models.model";
import { CheckoutCart } from "../../models/models.model";
import { HttpService } from '../http.service';
import { Installment } from "../../models/models.model";
import { Variant } from "../../models/models.model";

@Injectable()
export class InstallmentService {

  apiUrl = "checkout/installments"

  static build(installment) {
    if (installment === 0 || installment > environment.installments) {
      return environment.installments;
    } else {
      return installment;
    }
  }


  static buildText(max_installments_info: any): string {
    return max_installments_info.coef == 0 ? 'cuotas sin interés de' : 'cuotas fijas de'
  }

  static installmentAmout(amount: number,coef: number, installment: number){
    if (coef==0){
      if (installment==1) return amount;
      return amount/installment;
    } else {
      return amount * coef;
    }
  }

  static installmentTotal(amount:number, coef: number, installment: number){
    return this.installmentAmout(amount, coef, installment) * installment;
  }

  constructor(
    private httpService: HttpService,
  ) { }

  productInstallments(product: Product){
    if (product) return this.runInstallments(product);
  }

  maxInstallment(checkoutCart: CheckoutCart){
    let installments: number[] = [];
    let smallestInstallment: number;
    let difference = false;
    checkoutCart.checkout_items.forEach((item,index) => {
      this.runInstallments(item.product).forEach((element,index2) => {
        if(index2 == 0) installments[index] = element.installments;
        if(installments[index] < element.installments) installments[index] = element.installments;
      });
    });
    installments.forEach((el,index) => {
      if(index == 0) smallestInstallment = el;
      if(el != smallestInstallment) difference = true;
    });
    return difference;
  }

  runInstallments(product: Product){
    return product.promotions[0].brands[0].installments;
  }

  getProductMaxInstallment(variant) {
    const installments = variant.promotions[0].brands[0].installments;
    return installments[installments.length - 1]
  }

  getInstallments(params: any) {
    return this.httpService.post(this.apiUrl, params);
  }
}
