import { Injectable } from '@angular/core';

import { setZendeskChat } from './env-setters/zen-desk-chat';

@Injectable({
  providedIn: 'root'
})
export class GenerateEnvTagsService {

  public setTags(envName: string): void {
    if (envName === 'PaseoLibertad') this.setPaseoLibertadTags();
    if (envName === 'BancoCiudad') this.setBancoCiudadTags();
  }

  private setPaseoLibertadTags(): void {
    // document.head.append(setZendeskChat());
  }

  private setBancoCiudadTags(): void {
    // document.head.append(setZendeskChat());
  }
}
