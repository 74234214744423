import { Pipe, PipeTransform } from '@angular/core';
import { InstallmentService } from '../../services/views/installment.service';

@Pipe({
  name: 'buildInstallmentsText',
  pure: true,
})
export class BuildInstallmentsTextPipe implements PipeTransform {
  transform(value: any, arg: any): any {
    return InstallmentService.buildText( value.max_installments_info);
  }
}
