<div class="container py-5">
  <div class="row py-5">
    <div class="col-8 offset-md-2 text-center">
      <p style="font-size: 50px;" class="text-warning mb-1"><i class="fe fe-alert-triangle"></i></p>
      <h4 class="mb-2">Ups, estamos trabajando</h4>
      <p style="line-height: 25px;">
        Estimado usuario, a fin de mejorar su experiencia de compra, nos encontramos realizando mantenimiento en la plataforma. La ventana de tiempo será de 4 hrs. Disculpe la molestia.
      </p>
    </div>
  </div>
</div>
