import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Landing} from '../models/models.model';

@Injectable()

export class LandingsService extends ModelService<Landing> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'landings', Landing);
  }
}

