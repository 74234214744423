import { TaxesService } from './taxes.service';
import { Router } from '@angular/router';
import { InsuranceService } from './insurance.service';
// Angular
import { Injectable } from '@angular/core';
// Models
import { Variant, Installment } from '../models/models.model';
import { CheckoutCart } from '../models/models.model';
import { Card, CardTokenized } from '../app/new-core//checkout/models/card';
// Services
import { HttpService } from './http.service';
import { PointsService } from './points.service';
import { LocalStorage } from './local_storage.service';
import { GtmBuilderService } from './gtm_builder.service';
// Other
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Insurance } from '../interfaces/insurance.interface';
import { GatewaysService } from '../app/new-core/checkout/services/gateways.service';
import { map, tap } from 'rxjs/operators';
import { TaxesDecorator } from '../decorators/taxes.decorator';

@Injectable({ providedIn: 'root', })

export class CheckoutService {
  cart$ = new Subject<CheckoutCart>();
  cartUpdated = new Subject<boolean>();
  variantRemoved = new Subject<boolean>();
  checkoutStep = new Subject<number>();
  cart: CheckoutCart;
  pointsCart: CheckoutCart;
  finished = false;

  paymentAttributes = {
    gateway: '',
    valid: false,
  };

  cards = new BehaviorSubject<Card[]>([] as Card[]);
  cardsAmount: Card[];

  totalAmountSource = new BehaviorSubject<number>(0);
  totalAmount$ = this.totalAmountSource.asObservable();
  amountCardASource = new BehaviorSubject<number>(0);
  amountCardA$ = this.amountCardASource.asObservable();
  amountCardBSource = new BehaviorSubject<number>(0);
  amountCardB$ = this.amountCardBSource.asObservable();
  purchaseAmountWithInstallmentsInterestsSource = new BehaviorSubject<number[]>([0, 0]);
  purchaseAmountWithInstallmentsInterests$ = this.purchaseAmountWithInstallmentsInterestsSource.asObservable();
  private addressesSource = new BehaviorSubject<any[]>([]);
  addresses$ = this.addressesSource.asObservable();

  isButtonDisabledSource = new BehaviorSubject<boolean>(true);
  isButtonDisabled$ = this.isButtonDisabledSource.asObservable();

  shippingAddressSelected$ = new BehaviorSubject<boolean>(true);

  isPaymentButtonDisabledSource = new BehaviorSubject<boolean[]>([true, true]);
  isPaymentButtonDisabled$ = this.isPaymentButtonDisabledSource.asObservable();

  errorsArraySource = new BehaviorSubject<string[]>([]);
  errorsArray$ = this.errorsArraySource.asObservable();

  isAddressButtonDisabledContinueSourceBancoCiudad = new BehaviorSubject<boolean>(false);
  isAddressButtonDisabledContinue$ = this.isAddressButtonDisabledContinueSourceBancoCiudad.asObservable();

  paymentUpdated = new Subject<boolean>();
  paymentExecuted = new Subject<string>();
  processing = new Subject<boolean>();

  insurance: Insurance;

  deliveryOptionsII: BehaviorSubject<any> = new BehaviorSubject([]);

  newAmount = 0;
  cft = 0;
  coef = 0;
  tea = 0;
  percentage = 0;

  constructor(
    private _http: HttpService,
    private _localStorage: LocalStorage,
    private _gtmBuilderService: GtmBuilderService,
    private _gateway: GatewaysService,
    private insuranceService: InsuranceService,
    private _router: Router,
    private _taxesService: TaxesService
  ) {
    this.cart = new CheckoutCart();
    if (this.insuranceService.getValue != null) this.insurance = this.insuranceService.getValue();
  }

  addVariant(variant: any, quantity: number, points: number) {
    let variantPoints = 0;
    if (this._localStorage.getKeyFromArray('items', 'variant_id', variant.id)) {
      variantPoints = this._localStorage.getKeyFromArray('items', 'variant_id', variant.id).points;
      this._localStorage.removeKeyFromArray('items', 'variant_id', variant.id);
    }
    this._localStorage.pushArray('items', { variant_id: variant.id, quantity: quantity, points: points});
    return variantPoints;
  }

  init() {
    return this.initFromParams(
      this._localStorage.getObject('items'),
      this._localStorage.get('purchase_id')
    );
  }

  initFromParams(items, purchase_id) {
    if(this._localStorage.get('warranty')){
      return this.getPostWithParams$(items, purchase_id, 'warranty');
    }
    else if(this._localStorage.get('points_type')){
      return this.getPostWithParams$(items, purchase_id, 'points_type')
    }else {
      return this._http.post('checkout/init', {
        items,
        purchase_id,
      })
    }
  }

  getPostWithParams$(items, purchase_id, queryParamValue?): Observable<any>{
    return this._http.post('checkout/init', {
      items,
      purchase_id,
    },
    {
      params:
     this._localStorage.get(queryParamValue),
    }).pipe(
      tap(
        res => {},
        error => {
        }
    ))
  }

  getSummary() {
    return this._http.post('checkout/read_summary', {
      purchase_id: this._localStorage.get('purchase_id')
    });
  }

  updateSummary(installments_amounts: { installments_number: number, total_by_installment: number }[]) {
    return this._http.post('checkout/read_summary', {
      installments: installments_amounts,
      purchase_id: this._localStorage.get('purchase_id')
    });
  }

  cartQuantity() {
    return this._http.post('checkout/cart_quantity', {
      purchase_id: this._localStorage.get('purchase_id')
    });
  }

  coupon(code):Observable<any> {
    return this._http.post('checkout/coupon', {
      coupon_code: code,
      purchase_id: this._localStorage.get('purchase_id')
    },{
      params: this._localStorage.get('warranty')
    });
  }

  removeCoupon():Observable<any> {
    return this._http.post('checkout/remove_coupon', {
      purchase_id: this._localStorage.get('purchase_id')
    },{
      params: this._localStorage.get('warranty')
    });
  }

  removeVariant(variantID) {
    let variantPoints = 0;
    if (this.cart.checkout_items && this.cart.findCheckoutItemBy(variantID)){
      const checkoutItem = this.cart.findCheckoutItemBy(variantID);
      this._gtmBuilderService.builderRemoveCart(checkoutItem);
      variantPoints = this._localStorage.getKeyFromArray('items', 'variant_id', variantID).points;
      this._localStorage.removeKeyFromArray('items', 'variant_id', variantID);
      this.variantRemoved.next(false);
      // this logic ensures
    }
    return variantPoints;
  }

  authorizeTodopago() {
    return this._http.post('checkout/authorize_todopago', {
      purchase_id: this._localStorage.get('purchase_id')
    });
  }

  address(params: {}) {
    return this._http.post('checkout/address', params, {
      params:
      this._localStorage.get('warranty')
    });
  }

  setAddresses(addresses: any[]): void {
    this.addressesSource.next(addresses)
  }

  deliveryOptions() {
    return this._http.post('checkout/delivery_options', {
      delivery_option_ids: this.cart.getDeliveryOptionIds(),
      purchase_id: this._localStorage.get('purchase_id')
    },{
      params:
      this._localStorage.get('warranty')
    });
  }

  updateShippingCost(amount) {
    this.cart.updateShippingCost(amount);
  }

  setDeliveryOptionIds(optionIds) {
    this.cart.setDeliveryOptionIds(optionIds);
    //this.deliveryOptionsII.next(optionIds);
  }

  subscriptioNewsletter(newsletter: boolean) {
    return this._http.post('checkout/subscription_newsletter', {
      newsletter: newsletter,
      purchase_id: this._localStorage.get('purchase_id')
    });
  }

  payment(params: {}) {
    return this._http.post('checkout/payment', params, {params: this._localStorage.get('warranty')});
  }

  getCart(): CheckoutCart {
    if(!this.cart) this.setCart(new CheckoutCart)
    return this.cart
  }

  setCart(cart: CheckoutCart, options?: {}) {
    this.cart = cart;
    this._localStorage.set('purchase_id', this.cart.purchase_id);

    if (options === undefined || options['no_notify'] !== true) {
      this.cartUpdated.next(true);
    }

    this.totalAmountSource.next(Number(this.cart.total));
  }

  destroyCart() {
    this.cart = null;
    this.finished = false;
    this._localStorage.remove('purchase_id');
    this._localStorage.remove('items');
    this.variantRemoved.next(false);
  }

  setPaymentParams(paymentAttributes: {gateway: string, valid: boolean}) {
    this.paymentAttributes = paymentAttributes;
    this.paymentUpdated.next(true);
  }

  getPaymentParams() {
    return this.paymentAttributes;
  }

  executePayment() {
    if (this.paymentAttributes.valid) {
      this.paymentExecuted.next(this.paymentAttributes.gateway);
    }
  }

  updateSummaryValues(cards: Card[]) {

    if (cards.length > 0) {

      this.newAmount =0
      this.cft = 0
      this.coef = 0
      this.tea = 0
      this.percentage = 0

      cards.forEach(card => {

        this.newAmount = Number(this.newAmount) + Number(card.amount);
        this.cft = Number(this.cft) + Number(card.cft);
        this.coef = Number(this.coef) + Number(card.coef);
        this.tea = Number(this.tea) + Number(card.tea);
        this.percentage = Number(this.percentage) + Number(card.percentage);

      });

      this._taxesService.fire(new TaxesDecorator(true, this.cft, this.tea, this.newAmount, null, this.percentage, this.coef))
    }

    this.cards.next(cards);

  }

  async executePaymentCard() {

    let errorMessage: String;

    this.setPaymentInProcess(true);

    let cards = this.cards.value;

    if (cards.length > 0) {

      this.newAmount =0
      this.cft = 0
      this.coef = 0
      this.tea = 0
      this.percentage = 0

      this.cards.value.forEach(card => {

        this.newAmount = Number(this.newAmount) + Number(card.amount);
        this.cft = Number(this.cft) + Number(card.cft);
        this.coef = Number(this.coef) + Number(card.coef);
        this.tea = Number(this.tea) + Number(card.tea);
        this.percentage = Number(this.percentage) + Number(card.percentage);

      });

      this._taxesService.fire(new TaxesDecorator(true, this.cft, this.tea, this.newAmount, null, this.percentage, this.coef))


      let cardsTokenized = [];
      let totalCardsAmount = 0;


      /////////////////Nuevo


      /////////////////////////Viejooooo////////////

      cards.forEach((card, index) => {

        const body = {
          card_number: card.cc_number,
          card_expiration_month: card.cc_expiration_month,
          card_expiration_year: card.cc_expiration_year,
          security_code: card.cc_cvv,
          card_holder_name: card.cc_name,
          card_holder_identification: {
            type: card.cc_doc_type ? card.cc_doc_type.toLowerCase() : '',
            number: card.cc_doc_number
          }
        };

        this.tokenizedDicidir(body)
          .toPromise()
          .then(
            (res) =>{
              let tokenizedCard = res as unknown as CardTokenized;

              let newCard = {
                ...tokenizedCard,
                amount: card.amount,
                installments: card.cc_installments,
                coef: card.coef,
                card_brand: card.cc_card_brand,
                payment_method_id: this._gateway.detectCreditBrand(card.cc_number),
                identity_validation_token : card.identity_validation_token ? card.identity_validation_token : ''
              }

              totalCardsAmount = totalCardsAmount + Number(card.amount);

              cardsTokenized.push(newCard);

              if( cards.length === cardsTokenized.length){

                this.setParamsPayment(cardsTokenized, totalCardsAmount);

              }

            }
          )
          .catch(
            (error) => {
              this.setPaymentInProcess(false);
              this.errorsArraySource.next(error)
              console.error('TOKEN ERROR', error);
            }
          )


      });



      // const params = {

      //   // Visa Puntos
      //   domicilio_completo: token.cc_address,
      //   telefono: body.cc_phone,
      //   codigo_postal: body.cc_postal_code,
      //   cod_provincia: body.cc_state,
      //   localidad: body.cc_city,
      //   document_number: body.cc_document,
      //   // Seguros BNA
      //   insurance_data: this.insurance,
      //   identity_validation_token: body.cc_identity_token,
      //   // Decidir
      //   purchase_id: this.getCart().purchase_id,
      //   response: response,
      //   gateway: this.paymentAttributes.gateway,
      //   installments: body.cc_installments,
      //   card_brand: body.cc_card_brand,
      //   //payment_method_id: this._decidirParsable.detectCreditBrand(body.cc_number),
      //   doc_number: body.cc_doc_number,
      //   doc_type: body.cc_doc_type,
      //   coef: body.coef
      // };

      //
      // Use FirstData BNA Whitelist based
      //

      if (this.paymentAttributes.gateway === "firstdata_distributed") {
        /*const body = {
          // Visa Puntos
          domicilio_completo: card.cc_address,
          telefono: card.cc_phone,
          codigo_postal: card.cc_postal_code,
          cod_provincia: card.cc_state,
          localidad: card.cc_city,
          document_number: card.cc_document,
          // Seguros BNA
          insurance_data: this.insurance,
          identity_validation_token: card.cc_identity_token,
          // First Data
          purchase_id: this.checkoutService.getCart().purchase_id,
          gateway: this.gateway_name,
          card_number: card.cc_number,
          card_brand: card.cc_card_brand,
          card_expiration_month: card.cc_expiration_month,
          card_expiration_year: card.cc_expiration_year,
          security_code: card.cc_cvv,
          card_holder_name: card.cc_name,
          card_holder_identification: {
            type: card.cc_doc_type ? card.cc_doc_type.toLowerCase() : '',
            number: card.cc_doc_number
          },
          installments: card.cc_installments,
          coef: this.coef
        };

        this.checkoutService.payment(body).subscribe(
          (response) => {
            if (response.error) {
              this.errors = response.errors;
              this.checkoutService.setPaymentInProcess(false);
            } else {
              this.checkoutService.setPaymentInProcess(false);
              this.checkoutService.finished = true;
              this.router.navigate(['/checkout/done'], {queryParams: {order: response.order}});
            }
          }, (error) => {
              this.checkoutService.setPaymentInProcess(false);
          }
        );*/
      } else {
        /*

          */
      }


    } else { // SOLO PUNTOS
      /*const params = {

        // Visa Puntos
        document_number: this.checkoutCart.address.doc_number,
        domicilio_completo: card.cc_address,
        telefono: card.cc_phone,
        codigo_postal: card.cc_postal_code,
        cod_provincia: card.cc_state,
        localidad: card.cc_city,
        gateway: this.gateway_name,
        // Seguros BNA
        insurance_data: this.insurance,
        identity_validation_token: card.cc_identity_token
      }

      this.checkoutService.payment(params).subscribe(
        (response2) => {
          if (response2.error) {
            this.errors = response2.errors;
            this.checkoutService.setPaymentInProcess(false);
          } else {
            this.checkoutService.finished = true;
            this.checkoutService.setPaymentInProcess(false);
            this.router.navigate(['/checkout/done'], {queryParams: {order: response2.order}});
          }
        }, (err) => {
          this.checkoutService.setPaymentInProcess(false);
          this.errors = err;
        }
      );*/
    }
  }

  setParamsPayment(cardsTokenized: CardTokenized[], totalAmount: number){


    this.setCart(this.cart);



    if (this.insuranceService.getValue !== null){
      this.insurance = this.insuranceService.getValue();
      //this.insuranceService.insurance.card_number = this.cards.value[0].cc_number
    }
    if ( this.insurance !== null){
      this.insurance.card_number = this.cards.value[0].cc_number;
    }

    const params = {
      insurance_data: (this.insuranceService.getValue != null) ? this.insuranceService.getValue() : null,
      purchase_id: this.getCart().purchase_id,
      gateway: this.paymentAttributes.gateway,
      //payment_method_id: this._decidirParsable.detectCreditBrand(body.cc_number),
      doc_number: this.cart.customer.doc_number,
      doc_type: this.cart.customer.doc_type,
      total_amount: totalAmount,
      cards: cardsTokenized
    }

    this.payment(params).subscribe(
      res => {
        if (res.error) {
          this.errorsArraySource.next([res.errors]);
        } else {
          this.finished = true;
          this._router.navigate(['/checkout/done'], {queryParams: {order: res.order}});
        }
        this.setPaymentInProcess(false);
      }, error =>{
        this.errorsArraySource.next([error.error.message]);
      }
    )

  }

  tokenizedDicidir( body: any): Observable<Response>{

    return this._gateway.generateToken(body);

  }

  isPaymentProcessing(): Observable<any> {
    return this.processing;
  }

  setPaymentInProcess(params: boolean) {
    this.processing.next(params);
  }

  //REVISAR
  getReservableItems() {
    return this.cart.checkout_items.filter(item => {
        return item.product.reservable === true;
    });
  }

  getPurchasableItems() {
    return this.cart.checkout_items.filter(item => {
        return item.product.purchasable === true;
    });
  }

  getTotalAmount(): void{
    if(this.cart) { this.totalAmountSource.next(Number(this.cart.total)) }
  }

  modifyCardBAmount(amount: number): void {
    let max = this.totalAmountSource.getValue();
    this.amountCardBSource.next(max - amount)
    this.purchaseAmountWithInstallmentsInterestsSource.next(
      this.updatePurchaseAmountWithInstallmentsInterests(this.purchaseAmountWithInstallmentsInterestsSource.getValue(), (amount), 0)
    )
    this.purchaseAmountWithInstallmentsInterestsSource.next(
      this.updatePurchaseAmountWithInstallmentsInterests(this.purchaseAmountWithInstallmentsInterestsSource.getValue(), (max - amount), 1)
    )
  }

  updatePurchaseAmountWithInstallmentsInterests(purchaseAmount: number[], newAmount: number, index: number): number[] {
    purchaseAmount[index] = newAmount;
    return purchaseAmount
  }

}
