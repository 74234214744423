<div class="container">
  <div class="row" id="subscription-component">
    <div class="col-sm-12 col-md-6">
      <div class="lead">
        <i class="fa fa-envelope-o" aria-hidden="true"></i>
        <h3>Suscribete a nuestro newsletter.</h3>
      </div>
      <p class="d-none d-sm-inline">¡Dejanos tu email y te mantendremos al tanto de las ultimas novedades, promociones y las mejores ofertas!</p>
    </div>
    <div class="col-sm-12 col-md-6">
      <br>
      <form class="input-group u-shadow-v19 rounded" (ngSubmit)="newsLetter()">
        <input class="form-control" type="email" placeholder="Ingresa tu email" #emailId>
        <button class="btn btn-primary" type="submit">
          SUSCRIBIRME
        </button>
      </form>
      <p class="response" *ngIf="response">{{response}}</p>
    </div>
  </div>
</div>
