import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.sass']
})
export class FaqsComponent implements OnInit {

  env = environment;
  option: number = 0;
  faqs: Array<{question: string, answer: string, collapse: boolean}> = [];

  constructor(
    private router:Router
  ) { }

  ngOnInit() {
    if ( ["BancoNacion", "MiMoto", "EspecialesBNA"].includes(environment.name) ) {
      this.option = 0;
      return
    }

    if (environment.name == "Macro") {
      this.option = 1;
      this.faqs = [
        {
          question: "¿Qué es Macro Premia?",
          answer: "Es una propuesta exclusiva para los clientes de Banco Macro, en la que podrán adquirir una amplia gama de productos, servicios y/o beneficios, mediante el canje de puntos Macro Premia o mediante la adquisición de los mismos bajo la modalidad Sólo Pesos, o un mix entre estas, denominado como Puntos más Pesos variable.",
          collapse: true
        },
        {
          question: "¿Cómo ingreso a Macro Premia?",
          answer: "Para acceder a Macro Premia será requisito ingresar a través de Banca Internet o App Macro. Para ello los clientes deberán contar con un usuario y una clave para poder ingresar a la Web.",
          collapse: true
        },
        {
          question: "¿Quiénes pueden participar de Macro Premia?",
          answer: "Todos los clientes con acceso a canales digitales pueden acceder a Macro Premia.",
          collapse: true
        },
        {
          question: "¿Cómo acumulo puntos?",
          answer: "Todos los consumos con Tarjetas de Crédito Macro acumulan puntos.",
          collapse: true
        },
        {
          question: "¿Cuantos puntos se acumulan por consumo?",
          answer: "Forma de cálculo de Puntos Macro Premia: Por cada $ 600 (seiscientos pesos) de consumo acumula un Punto Macro Premia. Por cada US$ 600 (seiscientos dólares estadounidenses) de consumo se acumula un Punto Macro Premia. En ningún caso se computarán fracciones de Puntos Macro Premia. Los gastos de Débitos Automáticos serán transformados a Puntos Macro Premia por el total de su importe.",
          collapse: true
        },
        {
          question: "¿Dónde puedo ver los puntos que tengo?",
          answer: "Para consultar los puntos disponibles es necesario ingresar a Banca Internet o App Macro con su usuario y contraseña.",
          collapse: true
        },
        {
          question: "¿Los puntos tienen vencimiento?",
          answer: "La vigencia de los Puntos Macro Premia es de dos (2) años móviles contados a partir del mes de consumo. Adicionalmente cumplidos los 6 meses de inactividad en el programa Macro Premia, se vencerá la totalidad de puntos acumulados, considerando como inactividad tanto la no acreditación de puntos (por consumos o acciones especiales), como la no redención (canje de puntos o compra en modalidad &ldquo;Solo Pesos&rdquo;).",
          collapse: true
        },
        {
          question: "¿Cómo suman las tarjetas adicionales?",
          answer: "Las tarjetas adicionales sumarán Puntos Macro Premia en la cuenta del titular de la Tarjeta de Crédito que se está utilizando. Los productos, beneficios y/o servicios sólo podrán ser solicitados por los titulares y serán entregados en el domicilio informado por el titular.",
          collapse: true
        },
        {
          question: "¿Puedo transferir los puntos a otra cuenta Macro?",
          answer: "Los Puntos Macro Premia no son transferibles a ninguna otra cuenta del programa, ni a ninguna persona o entidad bajo ningún título o causa (fallecimiento, incapacidad, disolución de la sociedad conyugal, etc.). Los Puntos Macro Premia tampoco podrán ser canjeados por dinero.",
          collapse: true
        },
        {
          question: "¿Qué productos, servicios o beneficios ofrece Macro Premia?",
          answer: "El programa ofrece una gran variedad de propuestas, entre las que se destacan Productos, Ahorros en compras con Tarjeta de Crédito, Recargas para Celulares, Recargas en tarjeta SUBE, Millas, Viajes en Despegar.com,, Gift Cards, entre otras opciones.",
          collapse: true
        },
        {
          question: "¿Puedo adquirir productos si no tengo puntos? (modalidades de canje)",
          answer: "Los clientes del banco que tengan acceso a Banca Internet o App Macro podrán adquirir los productos, servicios y/o beneficios que estén disponibles bajo la modalidad Solo Pesos.",
          collapse: true
        },
        {
          question: "¿Cuáles son las modalidades para adquirir productos, servicios o beneficios de Macro Premia?",
          answer: "Los productos, beneficios y/o servicios de Macro Premia pueden adquirirse bajo las siguientes modalidades: – Solo Puntos: Es necesario contar con la totalidad de puntos requeridos para poder adquirir el producto, servicio o beneficio seleccionado. – Puntos más Pesos Variable: Productos, servicios y/o beneficios que se pueden adquirir mediante el canje de puntos y el pago de una parte del mismo en pesos, con las tarjetas de crédito y o débito emitidas por el banco, permitiendo elegir, con tope en sus puntos disponibles, qué proporción del producto abonar con puntos, y qué proporción abonar en pesos. – Solo Pesos: No es necesario contar con puntos. La totalidad de pesos requerida para la adquisición del producto, servicio o beneficio se abona con las tarjetas de crédito y o débito emitidas por el banco.",
          collapse: true
        },
        {
          question: "¿Qué medios de pago puedo utilizar para adquirir productos y servicios de Macro Premia?",
          answer: "Todos los productos, servicios y/o beneficios que estén disponibles bajo la modalidad Puntos más Pesos variable y /o Solo Pesos podrán ser abonados con las tarjetas de crédito y o débito emitidas por el Banco Macro.",
          collapse: true
        },
        {
          question: "¿Cuánto demora la entrega de los Productos solicitados?",
          answer: "El producto solicitado será entregado por la empresa OCASA SOLUCIONES LOGISTICAS en el domicilio del cliente o en el que indique oportunamente dentro de los 15 días hábiles posteriores a la fecha en que ha realizado la misma. En el caso de que la empresa no pudiera entregar el premio, realizará hasta 2 visitas en días correlativos, en distintos horarios cada uno. Luego de las mismas, ingresará el producto no entregado al stock, a través del sistema correspondiente, sobre tal situación con el objeto de realizar la devolución de Puntos Macro Premia y/o pesos en el medio de pago seleccionado por el cliente. La recepción de los productos la podrá realizar el titular u otra persona mayor a 14 años, la cual deberá firmar el acuse de recibo correspondiente. El cliente podrá ser contactado vía mail por OCASA para coordinar fecha y horario de entrega o para dar aviso de la devolución del producto. También se puede contactar telefónicamente al 0810-555-7736 (PREMIA). Los Servicios que consisten en recarga celular / millas / viajes / premios instantáneos / entradas de cine / gift cards / experiencias / entradas a espectáculos / ahorros / cargas SUBE, serán enviados por mail o retirados personalmente según corresponda, tal se detallará debidamente en el sitio Macro Premia.",
          collapse: true
        },
        {
          question: "¿Los Productos tienen costo de envío?",
          answer: "No hay costo de envío.",
          collapse: true
        },
        {
          question: "¿Cómo debo proceder en caso de tener algún inconveniente con el producto recibido?",
          answer: "El cliente podrá contactarse con el Centro de Atención Telefónica al (0810-555-2355) o por Banca Internet con clave y usuario, dentro de los 10 (diez) días siguientes de haber recibido el premio si el mismo presentara fallas, roturas, errores, desperfectos o falencias para que el Banco gestione la reposición. Si el reclamo corresponde a fallas, mal funcionamiento, roturas, errores, desperfectos o falencias de productos de Electrónica o Electrodomésticos con posterioridad a los primeros 10 (diez) días de haber recibido el producto, el cliente podrá acudir a la garantía que corresponda según el fabricante/ marca del producto presentando su factura. La misma será enviada por mail luego de realizar la solicitud.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje de Ahorros?",
          answer: "Los Puntos Macro Premia pueden canjearse por Ahorros para ser utilizados en los comercios adheridos al programa. Para acceder al Ahorro, una vez canjeado, el cliente deberá acercarse al comercio en los días indicados y efectuar la compra con la tarjeta de crédito seleccionada. Podrá ver la bonificación en el resumen posterior a la transacción o bien en el siguiente. El Ahorro es válido sólo para una transacción, y no podrá acumularse más de un Ahorro canjeado en la misma transacción. El Ahorro se recibirá en la primera transacción que se realice, ya sea con la tarjeta Titular o con cualquiera de sus adicionales de la tarjeta seleccionada, en el comercio adherido contando con una vigencia de 30 días desde el momento del canje. El Ahorro aplicará independientemente de la cantidad de cuotas en que se abone la compra y será acumulable con cualquier promoción vigente al momento del consumo del comercio o del Banco. Una vez realizado el canje, recibirá la confirmación por mail. Los Ahorros no admiten cambios ni devoluciones. - ¿Tengo que esperar un voucher o confirmación? Llegará la confirmación de la solicitud vía email. - ¿Tengo que avisar al comercio que tengo un ahorro Macro premia para obtener mi descuento? No, no es necesario dar aviso al comercio.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje de Recargas?",
          answer: "El canje de recargas disponibles en Macro Premia son exclusivamente para acreditar saldo en líneas de celulares prepagas. En caso de querer canjear una recarga de celular, se completarán los datos de la línea en la cual se desea realizar la recarga, y unos minutos después de realizado el canje, recibirá un SMS con la confirmación de la misma. Si se desea recargar DirecTV Prepago, se deberá informar el N° de abonado al cual se desea realizar la recarga, y en los próximos 30 minutos de realizado el canje se acreditará la misma. - Puedo recargar el celular de otra persona? Sí. Se establece el número de línea al cual aplica el beneficio al momento de generar la solicitud e ingresar allí los datos del mismo. - Que sucede si no recibo la recarga? Es posible que la se hayan ingresado datos incorrectos al momento de generar la solicitud. En caso de que el inconveniente persista, comunicarse al 0810 555 2355.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje de Millas Aerolíneas Plus?",
          answer: "Los Puntos Macro Premia pueden canjearse por Millas de Aerolíneas Plus, Air Europa, Smiles o Latam que pueden ser acreditadas a la cuenta del cliente en la aerolínea seleccionada. Para realizar el canje, se debe definir la cantidad de Puntos Macro Premia a canjear a partir de la relación Puntos Macro Premia / Millas vigente, y completar el DNI o Número de Pasajero Frecuente a quien se desean acreditar las millas. Luego de realizar el canje, recibirá la confirmación del mismo por mail, y en las próximas 72hs., recibirá la confirmación de acreditación de las millas en la cuenta seleccionada, que ya estarán disponibles para ser utilizadas. - ¿Dónde puedo ver las millas que solicité? Para ver la totalidad de millas canjeadas ya impactadas es necesario ingresar al programa Millas Aerolíneas Plus, colocando allí los datos correspondientes. Esta información estará disponible luego de 72 hs de realizado el canje. - ¿Puedo cargar millas a otra cuenta aerolíneas plus? Si, al momento de generar la solicitud se puede ingresar el DNI o número de pasajero frecuente al cual queremos imputar las Millas canjeadas, este puede ser distinto del usuario Macro Premia.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje en Despegar.com?",
          answer: "Los Puntos Macro Premia pueden utilizarse para canjear Paquetes, Vuelos, Hoteles, Alquiler de autos o Actividades en Despegar.com. Además, estos premios pueden canjearse con la modalidad Puntos + Pesos en cuotas según la promoción vigente. - ¿Cómo ingreso a la plataforma? Para ingresar a la propuesta vigente de Despegar.com – Macro Premia es necesario ingresar a Banca Internet o App Macro y seleccionar la opción: Macro Premia Despegar. - ¿Cómo puedo abonar los productos de Despegar? Los productos de la propuesta Macro Premia – Despegar podrán ser adquiridos mediante las modalidades de Full Puntos, Puntos + Pesos o Full Pesos. Abonando la parte de Pesos con las tarjetas de crédito de Banco Macro, en cuotas según la promoción vigente.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje de Gift Cards?",
          answer: "Los Puntos Macro Premia se pueden canjear por Gift Cards válidas en una gran variedad de comercios. Una vez realizado el canje, el cliente recibirá un mail de confirmación dentro de las 24 horas de gestionada la solicitud para utilizar la Gift Card. La misma tendrá una validez de 180 días corridos desde la fecha de canje. Se deberá consultar previo al canje cuáles son los comercios adheridos para la Gift Card seleccionada en el link incorporado al pie de cada uno de los beneficios. Es necesario recordar que estas solicitudes no admiten cambios ni devolución.",
          collapse: true
        },
        {
          question: "¿Cómo realizo un canje de Experiencias?",
          answer: "En Macro Premia hay disponible una gran variedad de experiencias gastronómicas: belleza, escapadas, gastronomía y muchas opciones para disfrutar un gran momento. Una vez realizado el canje el cliente recibirá un mail de confirmación dentro de las 24hs de realizada la solicitud.",
          collapse: true
        },
        {
          question: "¿Cómo convertir puntos Macro Premia en Carga SUBE?",
          answer: "El cliente podrá utilizar sus puntos para recargar su tarjeta SUBE. Es necesario ingresar a Macro Premia y seleccionar el menú SUBE. Una vez allí debe seleccionar el monto que desee cargar y colocar los datos del número de su tarjeta SUBE. El cliente recibirá un mail con la confirmación de la carga minutos después de realizado el canje. Luego de recibir el mail de confirmación, será necesario acreditar el saldo en una terminal Automática o con el dispositivo de Conexión Móvil SUBE.",
          collapse: true
        },
        {
          question: "¿Cómo debo proceder en caso de tener inconvenientes con las solicitudes de premios virtuales?",
          answer: "El cliente podrá contactarse con el Centro de Atención Telefónica al (0-810-555-2355) o por Banca internet con clave y usuario dentro de los 10(diez) días siguientes de haber generado la solicitud.",
          collapse: true
        }
      ]
      return
    }

    if (environment.name == "Sport Club") {
      this.option = 2;
      return;
    }

    this.router.navigate(["/"])
  }

  toggleCollapse(index: number) {
    this.faqs[index].collapse = !this.faqs[index].collapse;
  }
}
