export class DomService {
  static show(selector) {
    DomService.fetch(selector).style.display = 'block';
  }

  static hide(selector) {
    DomService.fetch(selector).style.display = 'block';
  }

  static fetch(selector) {
    return document.querySelector(selector) as HTMLElement;
  }
}
