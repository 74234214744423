<form *ngIf="isLoaded" [formGroup]="newCard">
  
    <div class="col-md-12 newCard-title">
      <h3>Agregar tarjeta</h3>
    </div>
    <hr>

    <!-- Error Feedback -->
    <div *ngIf="errors" class="col-12 alert alert-danger">
      <span>{{ errors }}</span>
    </div>
    
    <!-- Input Alias Tarjeta SUBE -->
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Alias de la tarjeta SUBE</mat-label>
        <input matInput formControlName="alias" placeholder="Ej. Tarjeta de Matias" required>
      </mat-form-field>
    </div>

    <!-- Input Número Tarjeta SUBE -->
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Numero de tarjeta SUBE</mat-label>
        <input  [dropSpecialCharacters]="true"
                mask="0000 0000 0000 0000"
                matInput formControlName="number"
                placeholder="Ingresa el número de tu tarjeta"
                required>
      </mat-form-field>
      <p *ngIf="!validCard" class="pl-2 mb-3 ml-0 text-danger"><small><b>El número de tarjeta es incorrecto</b></small></p>
    </div>

    <!-- Input Reingreso Tarjeta SUBE -->
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Confirma la tarjeta SUBE</mat-label>
        <input  [dropSpecialCharacters]="true"
                mask="0000 0000 0000 0000"
                matInput formControlName="confirm"
                placeholder="Reingresá el número de tarjeta SUBE"
                required>
      </mat-form-field>
    </div>

    <hr>
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-seccondary btn-block" (click)="closeDialog()">CANCELAR</button>
      </div>
      <div class="col-md-6">
        <button [disabled]="!validCard" class="btn btn-primary btn-block" (click)="addNewCard()">ACEPTAR</button>
      </div>
    </div>

</form>
<mat-progress-spinner
  *ngIf="!isLoaded"
  color="accent"
  mode="indeterminate">
</mat-progress-spinner>