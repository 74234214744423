import {Component, Input, OnInit} from '@angular/core';
import {UrlService} from "../../../../../services/url.service";
import { MktService } from './../../../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {
  @Input('item') item: any;

  constructor(
    private urlService: UrlService,
    private _mktService: MktService) { }

  ngOnInit() { }

  hasTitle(): boolean {
    return (this.item.title !== undefined)  && (this.item.title.length > 0);
  }

  navigate(link) {
    this._mktService.collect('CLICK-IN-OFFER', link)
    this.urlService.navigate(this.urlService.linkSelector(this.item));
  }
}
