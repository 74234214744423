import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-provider-form',
  templateUrl: './provider-form.component.html',
  styleUrls: ['./provider-form.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderFormComponent implements OnInit {
  newProvider: FormGroup;
  isLoaded = false;

  constructor(private dialogRef: MatDialogRef<ProviderFormComponent>,
              private _formBuilder: FormBuilder,
              ) { }

  ngOnInit() {
    this.isLoaded = true;
    this.formsGenerator();
  }

  formsGenerator() {
    this.newProvider = this._formBuilder.group({
      'brand': new FormControl(null, [
        Validators.required
      ]),
      'product_type': new FormControl(null, [
        Validators.required,
        Validators.min(4)
      ]),
      'email': new FormControl(null, [
        Validators.required,
      ]),
      'name': new FormControl(null, [
        Validators.required,
        Validators.min(4)
      ]),
      'phone': new FormControl(null, [
        Validators.required,
        Validators.pattern(/^[0-9]*$/i),
        Validators.min(16)
      ]),
    });
  }

  
  closeDialog() {
    this.dialogRef.close();
  }
}
