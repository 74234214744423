import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";

@Component({
  selector: 'app-cols4-posts-v1',
  templateUrl: './cols4-posts-v1.component.html'
})
export class Cols4PostsV1Component implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols4_posts_v1';
  }

  constructor() { }

  ngOnInit() { }
}
