import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Brand} from "../models/models.model";

@Injectable()

export class BrandService extends ModelService<Brand> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'brands', Brand);
  }
}
