import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Product} from '../models/models.model';

@Injectable()

export class ProductsService extends ModelService<Product> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'products', Product);
  }
}
