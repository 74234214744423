<div class="main-attachment">
  <div class="close-me-maybe">
    <a (click)="closeDialog()">
      X
    </a>
  </div>
  <div class="tije-background">
    <a href="https://avenida.tije.travel">
      <img src="/assets/images/popup-tije.png" alt="popup-tije">
    </a>
  </div>
</div>
