import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ComponentLanding } from "../../../../../models/models.model";
import { NewsletterService } from "../../../../../services/newsletter.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html'
})
export class SubscriptionComponent implements OnInit {
  @ViewChild('emailId', { static: true }) emailId: ElementRef;
  component: ComponentLanding;
  email: string;
  response: string;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'subscription';
  }

  constructor(private router: Router,
              private newsletterService: NewsletterService) {}

  ngOnInit() { }

  newsLetter() {
    this.email = this.emailId.nativeElement.value;

    if (this.email === "") {
      return false;
    }

    this.newsletterService.subscription(this.email).subscribe(
      (response) => {
        this.response = response.message
      },
      (error) => {
        this.router.navigate(['/'])
      }
    );
  }
}
