<div *ngIf="menu.childs.length > 0; then haschilds else nochilds"></div>

<ng-template #haschilds>
  <a class="menu-item collapsed" data-toggle="collapse" data-parent="#accordion" [href]="'#collapse' + position" aria-expanded="false" [attr.aria-controls]="'collapse' + position">
    <div class="px-3 py-2 text-uppercase border-right-0 border-left-0 border-secondary" role="tab" id="headingTwo">
        {{ menu.getName() }}
    </div>
  </a>

  <div [id]="'collapse' + position" class="collapse" role="tabpanel" aria-labelledby="headingTwo">
    <div class="card-block">
      <ul *ngIf="menu.childs !== undefined && menu.childs.length > 0" class="list-group">
        <div *ngFor="let child of menu.childs; let i = index">
          <li class="list-group-item rounded-0 d-flex justify-content-between align-items-center font-weight-bold">
            <a *ngIf="child.childs !== undefined && child.childs.length > 0" class="w-100" data-toggle="collapse" aria-expanded="false" href="#menu-{{ menu.order }}-{{ i }}" (click)="changeIcon('icon-'+menu.order+'-'+i)">{{ child.name }}<span><i id="icon-{{ menu.order }}-{{ i }}" class="fe fe-chevron-down ml-2"></i></span></a>
            <a *ngIf="child.childs.length < 1" class="w-100" (click)="navigate(child.url)">{{ child.name }}</a>
          </li>
          
          <div class="collapse" id="menu-{{ menu.order }}-{{ i }}">
            <ul *ngIf="child.childs !== undefined && child.childs.length > 0" class="list-group mb-3 mt-2">
              <li *ngFor="let submenu of child.childs" class="list-group-item border-0 rounded-0 d-flex border-0 text-muted" style="padding-left:35px !important;">
                <a class="w-100" (click)="navigate(submenu.url)">{{ submenu.name }}</a>
              </li>
            </ul>
          </div>

        </div>
      </ul>
      
    </div>
  </div>
</ng-template>

<ng-template #nochilds>
  <div class="px-3 py-2 text-uppercase border-bottom" [ngClass]="{'text-danger': menu.onSale()}">
    <app-button-link [menu]="menu" class="last-child" (click)="closeSide()"></app-button-link>
  </div>
</ng-template>

<ng-template>

</ng-template>
