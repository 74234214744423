import { Component, OnInit } from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";

@Component({
  selector: 'app-cols2-brand-v2',
  templateUrl: './cols2-brand-v2.component.html'
})
export class Cols2BrandV2Component implements OnInit {
  component: ComponentLanding;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols2_brand_v2';
  }

  constructor() { }

  ngOnInit() { }
}
