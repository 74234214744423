<div *ngIf="isLogged; then account else login"></div>

<ng-template #login>
  <div *ngIf="isCheckout(); then login_checkout else login_simple"></div>
</ng-template>

<ng-template #account>
  <div *ngIf="isCheckout(); then simple else with_options"></div>
</ng-template>

<ng-template #with_options>
    <div *ngIf="isMobile; then options_mobile else options_desktop"></div>
</ng-template>

<ng-template #login_simple>
  <div class="login-container" [attr.data-tracking-id]="env.name === 'BancoNacion' ? 'Boton-Registro' : null" (click)="navigate()" role="button">
    <i *ngIf="env.name !== 'Macro'"
    class="fe fe-user fe-2x mr-1"
    style=" vertical-align: sub;"
    [ngStyle]="{color: (env.name !== 'BancoNacion' || isMobile) ? null : 'white'}" aria-hidden="true"></i>
    <span *ngIf="env.name === 'BancoNacion';"> {{isMobile ? 'Ingresar': ''}} </span>
    <span *ngIf="env.header_version != 1 && env.name !== 'BancoNacion';" [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : '' ">Ingresar</span>
    <i *ngIf="env.name === 'Macro'" class="fa fa-user-o user-icon ml-1" aria-hidden="true"></i>
  </div>
  <div class="get-card-container" (click)="navigateGetYourCard()">
    <span *ngIf="env.name === 'Macro'"> {{isMobile ? 'Sacá tu tarjeta': ''}} </span>
  </div>
  <div class="add-points-container" (click)="navigateHowToAddPoints()">
    <span *ngIf="env.name === 'Macro'"> {{isMobile ? 'Cómo sumar puntos?': ''}} </span>
  </div>
</ng-template>

<ng-template #login_checkout>
  <button class="btn btn-outline-primary" (click)="openDialog()">
    <i class="fa fa-user-o user-icon mr-2" aria-hidden="true"></i>
    <span *ngIf="env.header_version != 1;">Ingresar</span>
  </button>
</ng-template>

<ng-template #simple>
  <div class="pull-right mr-4" id="macro-user">
    <i  *ngIf="env.name != 'Macro'"
        class="fa fa-user-o user-icon mr-2"
        aria-hidden="true"></i>
    <span *ngIf="env.name != 'Macro' && (customer_name || customer)">
      {{ customer_name || customer.getFullName() }}
    </span>
    <span *ngIf="env.name === 'Macro' && env.header_version === 0;" class="sub-title-product">
      <p class="name">{{ customer_name || customer.getFullName() }}</p>
      <p class="points">Puntos: {{ floatParse(points) }}</p>
    </span>
  </div>
</ng-template>

<ng-template #options_desktop>
  <span [attr.data-tracking-id]="env.name === 'BancoNacion' ? 'boton-login' : null" #menuTrigger="matMenuTrigger" (click)="menuTrigger.openMenu()" role="button" [matMenuTriggerFor]="aMenu">
    <span *ngIf="env.name != 'Macro' && (customer_name || customer)">
      <i  class="fa fa-user-o user-icon mr-2"
          aria-hidden="true"
          [ngStyle]="{color: (env.name === 'BancoNacion') ? 'white': null}"
          >
      </i>
      <p  class="slimName"
          [ngStyle]="{color: (env.name === 'BancoNacion') ? 'white': null}"
          >Hola, {{ customer_name || customer.getFullName() }}
      </p>
    </span>
    <span *ngIf="env.name == 'Macro' && env.header_version === 0;" class="d-flex align-items-center">
      <small class="text-right sub-title-macro">
        <p class="name pl-2 text-end">{{ customer_name || customer.getFullName() | titlecase }}</p>
        <p class="points pl-2 text-end">Puntos: {{ floatParse(points) }}</p>
      </small>
      <i *ngIf="env.name === 'Macro'" class="fa fa-user-o user-icon ml-2" aria-hidden="true"></i>
    </span>
  </span>
  <mat-menu #aMenu="matMenu" xPosition="after" yPosition="below" [hidden]="isCheckout()">
    <span (mouseleave)="menuTrigger.closeMenu()">
      <button [routerLink]="['/account/profile']" mat-menu-item [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">Mi cuenta</button>
      <button [routerLink]="['/account/orders']" mat-menu-item [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">Mis Pedidos</button>
      <button [routerLink]="['/account/addresses']" mat-menu-item [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">Mis Direcciones</button>
      <button [routerLink]="['/account/wishlist']" mat-menu-item [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">Mis Favoritos</button>
      <button [routerLink]="['/logout']" mat-menu-item [ngClass]="env.name === 'Macro' ? 'sub-title-macro' : ''">Salir</button>
    </span>
  </mat-menu>
</ng-template>

<ng-template #options_mobile>
  <div class="m-3"  *ngIf="customer_name || customer.getFullName()">
    <span *ngIf="env.name != 'Macro' && (customer_name || customer)"><i class="fa fa-user-o user-icon mr-2" aria-hidden="true"></i><p class="slimName">Hola, {{ customer_name || customer.getFullName() }}</p></span>
    <span *ngIf="env.name == 'Macro'"><p class="name">{{ customer_name || customer.getFullName() }}</p><p class="points">Puntos: {{ floatParse(points) }}</p></span>
  </div>
  <div class="card-block">
    <ul>
      <li><a [routerLink]="['/account/profile']">Mi cuenta</a></li>
      <li><a [routerLink]="['/account/orders']">Mis Pedidos</a></li>
      <li><a [routerLink]="['/account/addresses']">Mis Direcciones</a></li>
      <li><a [routerLink]="['/account/wishlist']">Mis Favoritos</a></li>
      <li><a [routerLink]="['/logout']">Salir</a></li>
    </ul>
  </div>
</ng-template>
