import { AfterViewInit, Component, OnInit, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { ComponentLanding } from "../../../../../models/models.model";
import { UrlService } from "../../../../../services/url.service";

// import Swiper core and required modules
import SwiperCore, { Autoplay, Pagination, Navigation, Swiper } from "swiper/core";
import { MktService } from '../../../../new-core/core/services/mkt.service';

@Component({
  selector: 'app-mini-logo',
  templateUrl: './mini-logo.component.html',
  styleUrls: ['./mini-logo.component.sass']
})
export class MiniLogoComponent implements OnInit {
  component: ComponentLanding;
  mobile:boolean = false

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'mini_logo';
  }

  constructor(
    private _urlService: UrlService,
    private element: ElementRef,
    private _mktService: MktService
              ) {}

  ngOnInit() {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth >= 425 ? false : true;
  }

  getImages(){
    let logos: any[] = []
    this.component.data.mini_logo.forEach((element) => {
      if (element.image) logos.push(element);
    });
    return logos
  }

  navigate(item) {
    this._mktService.collect('CLICK-IN-LOGO', item);
    this._urlService.navigate(this._urlService.linkSelector(item));
  }

}
