import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {LoaderService} from "../../../services/loader.service";
import {Subscription} from "rxjs";
import {LoaderState} from "../../../interfaces/loader_state.interface";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  loaderServiceObs: Subscription;

  constructor(private loaderService: LoaderService,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.loaderServiceObs = this.loaderService.get().subscribe(
      (state: LoaderState) => {
        this.show = state.show;

        // https://stackoverflow.com/questions/39787038/how-to-manage-angular2-expression-has-changed-after-it-was-checked-exception-w
        this.changeDetector.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    if (this.loaderServiceObs) {
      this.loaderServiceObs.unsubscribe();
    }
    
  }
}
