<div class="body">
  <div class="title">
    <h4>Vende con nosotros</h4>
    <button title="Close (Esc)" type="button" class="close" (click)="closeDialog()"><i class="fa fa-times"></i></button>
  </div>
  <hr>

  <form *ngIf="isLoaded" [formGroup]="newProvider">
    <!-- Error Feedback -->
    <div *ngIf="errors" class="col-12 alert alert-danger">
      <span>{{ errors }}</span>
    </div>
    
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Marca</mat-label>
        <input matInput formControlName="brand" placeholder="Nombre de la marca" required>
      </mat-form-field>
    </div>

    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Típo de productos</mat-label>
        <input matInput class="w-100" formControlName="product_type" placeholder="Típo de productos" required>
      </mat-form-field>
    </div>

    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Nombre del contacto</mat-label>
        <input matInput formControlName="name" placeholder="Nombre del contacto" required>
      </mat-form-field>
    </div>
 
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Telefono de contacto</mat-label>
        <input matInput formControlName="confirm" placeholder="Telefono de contacto" required>
      </mat-form-field>
    </div>

    <hr>
    <div class="row">
      <div class="col-md-6">
        <button class="btn btn-seccondary btn-block" (click)="closeDialog()">CANCELAR</button>
      </div>
      <div class="col-md-6">
        <button [disabled]="!validCard" class="btn btn-primary btn-block" (click)="sendMail()">ENVIAR</button>
      </div>
    </div>

</form>
<mat-progress-spinner
  *ngIf="!isLoaded"
  color="accent"
  mode="indeterminate">
</mat-progress-spinner>



</div>