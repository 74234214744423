import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SubeService } from '../../../../services/sube.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.sass']
})
export class SelectCardComponent implements OnInit {
  selectACard: FormGroup;
  isLoaded = false;
  cards: object;
  selectedCard: any;
  newCard: boolean;
  errors: string;
  type: string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private subeService: SubeService,
              private _formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<SelectCardComponent>) { }

  ngOnInit() {
    this.type = this.data ? this.data : '';
    this.getCards();
    this.formsGenerator();
  }

  formsGenerator() {
    this.selectACard = this._formBuilder.group({
      'selectionCard': new FormControl(null, [
        Validators.required
      ]),
    });
  }

  async getCards() {
    await this.subeService.getCards().toPromise()
      .then((response)=>{
        this.isLoaded = true;
        this.cards = response;
        (response.length > 0) ? this.newCard = false : this.newCard = true;
      })
      .catch((error)=>{
        this.isLoaded = false;
      })
  }

  selectCard() {
    if (this.selectACard.valid)  {
      this.errors = null;
      this.selectedCard = this.selectACard.value.selectionCard;
      this.closeDialog();
    } else {
      this.errors = "Seleccione una tarjeta";
    }
  }

  addNewCard() {
    this.newCard = true;
    this.closeDialog();
  }

  deleteCard() {
    const card_id = this.selectACard.value.selectionCard;

    if (this.selectACard.valid)  {
      this.errors = null;
      this.subeService.deleteCard(card_id).subscribe(
        (response) => {
          this.closeDialog();
        }, (error) => {

      });
    } else {
      this.errors = "Seleccione una tarjeta";
    }

  }

  closeDialog() {
    this.dialogRef.close();
  }

}
