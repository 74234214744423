<div #termsOfUse>
  <div class="title">
    <h1>Terminos y condiciones</h1>
  </div>
  <form [formGroup]="termsOfUseForm">
    <mat-form-field>
      <textarea matInput formControlName="termsText" matTextareaAutosize readonly></textarea>
      <hr>
      <mat-checkbox formControlName="doAccept">
        <span>He leido y <strong>acepto</strong> los terminos y condiciones de uso</span>
      </mat-checkbox>
    </mat-form-field>
    <div *ngIf="error" class="alert alert-danger">
      <span>{{ error }}</span>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button class="btn btn-secondary col-5 mr-1" (click)="closeDialog()">Cancelar</button>    
      <button class="btn btn-primary col-5 ml-1" (click)="submit()">Aceptar</button>    
    </div>
  </form>
</div>