import { AuthenticationService } from '../../../services/authentication.service';
import { Component, OnInit } from '@angular/core';
import { Customer } from "../../../models/models.model";
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../../services/customer.service';
import { LoaderService } from '../../../services/loader.service';
import { LocalStorage } from '../../../services/local_storage.service';

@Component({
    selector: '[app-register]',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.sass'],
})

export class RegisterComponent implements OnInit  {
  model = new Customer();
  errors: string;
  facebook_app_id: any;
  registerForm: FormGroup;
  register: any;

  constructor(
          private router: Router,
          private authenticationService: AuthenticationService,
          private customerService: CustomerService,
          private loaderService: LoaderService,
          private localStorage: LocalStorage) {}

  ngOnInit() {
    if (this.authenticationService.isExternalLogin()) {this.router.navigate(['/'])}
    this.buildForm();
    this.errors = '';
    this.facebook_app_id = environment.facebook_app_id;
  }

  submit() {
    this.clearErrors();
    this.loaderService.show();

    if (this.registerForm.valid) {
      this.register = this.registerForm.value;
      this.authenticationService.register(this.register).subscribe(
        (response: any) => {
          this.authenticationService.login( this.register, null).subscribe(
            (loginResponse: any) => {
              this.loaderService.hide();
              if (loginResponse.success) {
                if (this.isCheckout()) {
                  this.authenticationService.broadcastLogin();
                } else {

                  this.redirectUser()

                }
              } else {
                this.errors = "Hubo un error, intente nuevamente."
              }
            },
            (error) => {
              this.router.navigate(['/login']);
            }
          );
        },
        (error) => {
          for(let e in error.error.errors) {
            this.errors = this.errors.concat("\n"+error.error.errors[e]);
          }
          this.loaderService.hide();
          this.registerForm.enable();
        }
      );
    } else {
      this.loaderService.hide();
    }
  }

  redirectUser() {

    // Check if user has been trying to add or purchase a product
    if(this.localStorage.get('lastProductUrl')) {
      // Get Product URL and redirect
      const productUrl = this.localStorage.get('lastProductUrl')

      this.localStorage.remove('lastProductUrl')
      this.router.navigate([`/products/${productUrl}`]);

    } else {

      this.router.navigate(['/account/profile']);

    }

  }

  isCheckout() {
    return this.router.url === '/checkout';
  }

  buildForm() {
    this.registerForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(null, [
        Validators.required,
        Validators.minLength(6)
      ]),
      'password_confirmation': new FormControl(null, [
        Validators.required,
        Validators.minLength(6)
      ])
    });
  }

  clearErrors() {
    this.errors = '';
  }
}
