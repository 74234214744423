import {Injectable, Inject, PLATFORM_ID} from "@angular/core";
import {Router} from "@angular/router";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class UrlService {



  static getUrl(link: string, base?) {
    if (link === undefined || link === null) return null;

    let output = [];

    if (base !== undefined) typeof base === "string" ? output.push(base) : output = base;

    const left = link.split('?')[0];

    if (left !== '') {
      output.push(left);
    }

    return output.length === 0 ? ['/'] : output;
  }

  static getQueryParams(link: string, separator?) {

    if (link === undefined || link === null) return null;

    separator = separator || "&";

    const splitter = link.split('?');
    const rightSide = {};

    if (splitter[1] !== undefined) {
      for (let par of splitter[1].split(separator)) {
        par = par.replace("%5B%5D", "[]");

        if (rightSide[par.split('=')[0]] === undefined) {
          rightSide[par.split('=')[0]] = []
        }

        rightSide[par.split('=')[0]].push(par.split('=')[1]);
      }
    }

    return rightSide;
  }

  static isExternal(link: string) {
    return (link.indexOf("http://") === 0 || link.indexOf("https://") === 0)
  }

  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object
            ) { }

  navigate(link: string, base?, isNewTab = false) {
    if (UrlService.isExternal(link) && isPlatformBrowser(this.platformId)) {
      if( isNewTab ){
        window.open(link, '_blank');
      }else {
        window.location.href = link;
      }
      return false;
    }
    return this.router.navigate(
      UrlService.getUrl(link, base),
      {queryParams: UrlService.getQueryParams(link)}
    )
  }

  linkSelector(item) {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("Android") && item.link_android) { return item.link_android };
    if (userAgent.includes("iPhone") && item.link_ios) { return item.link_ios };
    return item.link;
  }

}
