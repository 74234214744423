<form *ngIf="isLoaded" [formGroup]="selectEditCard">
  <div class="col-md-12 selectCard-title">
    <h3>Editar tarjeta</h3>
  </div>
  <hr>

  <div class="col-12 fields">
    <mat-form-field appearance="outline" *ngIf="!newCard">
      <mat-label>Seleccioná la tarjeta SUBE</mat-label>
      <mat-select formControlName="selectionCard" [(value)]="subeCard" required>
        <mat-option *ngFor="let card of cards" [value]="card" (click)="updateForm(card.alias, card.number)">
            {{ card.alias }} <small class="text-muted"><span class="badge badge-secondary">{{ card.number }}</span></small>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="newCard">
      <h6 class="mb-2">No tenés tarjetas registradas</h6>
      <p>Registra tus tarjetas SUBE para que puedas cargarlas cuando lo desees.</p>
    </div>
  </div>
  
  <ng-container *ngIf="subeCard">
    <hr>
    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Nuevo alias de la tarjeta</mat-label>
        <input  matInput
                formControlName="alias"
                placeholder="Alias de tarjeta SUBE"
                value="{{subeCard?.alias}}" 
                required>
      </mat-form-field>
    </div>

    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Número de la tarjeta SUBE</mat-label>
        <input  matInput
                formControlName="number"
                placeholder="Ingresá número de tarjeta SUBE"
                [dropSpecialCharacters]="true"
                mask="0000 0000 0000 0000"
                required>
      </mat-form-field>
    </div>

    <div class="col-12 fields">
      <mat-form-field appearance="outline">
        <mat-label>Confrima la tarjeta</mat-label>
        <input  matInput
                formControlName="confirm"
                placeholder="Reingresá el número de tarjeta SUBE"
                [dropSpecialCharacters]="true"
                mask="0000 0000 0000 0000"
                required>
      </mat-form-field>
    </div>
  </ng-container>

  <hr>
  <div *ngIf="errors" class="alert alert-danger">
    <span>{{ errors }}</span>
  </div>
  <div class="row">
    <div class="col-md-6">
      <button class="btn btn-seccondary btn-block" (click)="closeDialog()">CANCELAR</button>
    </div>
    <div class="col-md-6">
      <button *ngIf="!newCard" class="btn btn-primary btn-block" (click)="editCard()">ACEPTAR</button>
      <button *ngIf="newCard" class="btn btn-primary btn-block" (click)="addNewCard()">AGREGAR TARJETA</button>
    </div>
  </div>
</form>
<mat-progress-spinner
  *ngIf="!isLoaded"
  color="accent"
  mode="indeterminate">
</mat-progress-spinner>