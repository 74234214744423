<div class="container">
  <div *ngIf="message; then invalid else valid">
</div>

<ng-template #valid>
  <mat-horizontal-stepper linear='true' #insuranceStepper>

    <mat-step>
      <h6 class="mb-2 font-weight-bold">Asegurá tu {{ item }} por ${{ floatParse(insuranceCost) }} por mes. No incluye sellos.</h6>
      <p style="max-width: 600px; line-height: 24px;">Ingresá los datos siguientes para activar el servicio de seguro sobre tu producto, el monto del seguro lo verás debitado mensualmente en tu tarjeta.</p>

      <div class="my-3">

        <div class="alert alert-danger text-left" role="alert" *ngIf="menorEdad">
          <p class="alert-heading mb-2 font-weight-bold">Fecha de nacimiento inválida.</p>
          <small>Seguro válido únicamente para mayores de 18 años</small>
        </div>

      </div>

      <form [formGroup]="insuranceForm">

        <hr>
        <p class="font-weight-bold mb-2">Datos personales</p>
        <div class="form-row">
          <!-- INPUT NAME -->
          <div class="col-md-6">
            <div class="form-label-group">
              <label>Apellido y Nombre</label>
              <input formControlName="name" type="text" class="form-control" placeholder="Apellido y Nombre" [ngClass]="insuranceForm.controls['name'].errors && insuranceForm.controls['name'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['name'].errors?.required">
                Ingresá Apellido y Nombre.
              </div>
            </div>
          </div>
          <!-- END INPUT NAME -->

          <!-- INPUT EMAIL -->
          <div class="col-md-6">
            <div class="form-label-group">
              <label>Email:</label>
              <input formControlName="email" type="email" class="form-control" placeholder="usuario@correo.com" [ngClass]="insuranceForm.controls['email'].errors && insuranceForm.controls['email'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['email'].errors?.required">
                Ingresá un email.
              </div>
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['email'].errors?.pattern">
                El email es incorrecto.
              </div>
            </div>
          </div>
          <!-- END INPUT EMAIL -->
        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="form-label-group">
              <label>Sexo:</label>
              <select formControlName="gender" class="custom-select" placeholder="Sexo:">
                <option *ngFor="let gender of genders" value="{{gender}}">{{ gender }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-label-group">
              <label>Fecha de nacimiento:</label>
              <input formControlName="birthday" type="date" class="form-control"  placeholder="Fecha de nacimiento" [ngClass]="insuranceForm.controls['birthday'].errors && insuranceForm.controls['birthday'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['birthday'].errors?.required">
                Campo requerido.
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-label-group">
              <label>Lugar de nacimiento:</label>
              <input formControlName="birth_location" type="text" class="form-control"  placeholder="Lugar de nacimiento" [ngClass]="insuranceForm.controls['birth_location'].errors && insuranceForm.controls['birth_location'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['birth_location'].errors?.required">
                Campo requerido.
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-label-group">
                <label>Nacionalidad:</label>
                <select formControlName="nationality" class="custom-select">
                  <option *ngFor="let nacionalidad of nacionalidades" value="{{ nacionalidad }}"> {{ nacionalidad | titlecase}} </option>
                </select>
                <div class="invalid-feedback" *ngIf="insuranceForm.controls['nationality'].errors?.required">
                  Campo requerido.
                </div>
            </div>
          </div>

        </div>

        <div class="form-row">
          <div class="col-md-3">
            <div class="form-label-group">
              <label>Estado civil:</label>
              <select formControlName="civil_status" class="custom-select" placeholder="Estado Civil">
                <option *ngFor="let status of civil_status" value="{{status}}">{{ status }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-4">
            <div class="form-label-group">
              <label>Tipo Doc.:</label>
              <select formControlName="doc_type" class="custom-select" placeholder="Tipo Doc.">
                <option *ngFor="let doc of docTypes" value="{{doc}}">{{ doc }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-8">
            <div class="form-label-group">
              <label>Número de DNI/LC/LE:</label>
              <input formControlName="doc_number" type="text" class="form-control"  placeholder="Número de DNI/LC/LE" maxlength="10" [ngClass]="insuranceForm.controls['doc_number'].errors && insuranceForm.controls['doc_number'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['doc_number'].errors?.required">
                Campo requerido.
              </div>
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['doc_number'].errors?.maxlength">
                Número de documento inválido
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-label-group">
              <label>Teléfono:</label>
              <input formControlName="phone" type="text" mask="0000000099999" [dropSpecialCharacters]="true" class="form-control"  placeholder="Teléfono" [ngClass]="insuranceForm.controls['phone'].errors && insuranceForm.controls['phone'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" [hidden]="!insuranceForm.controls['phone'].errors">
                Campo requerido.
              </div>
            </div>
          </div>

        </div>

        <div class="form-row">
          <div class="col-md-8">
            <div class="form-label-group">
                <label>Profesión:</label>
                <input formControlName="profession" type="text" class="form-control"  placeholder="Profesión" [ngClass]="insuranceForm.controls['profession'].errors && insuranceForm.controls['profession'].touched ? 'is-invalid' : ''">
                <div class="invalid-feedback" *ngIf="insuranceForm.controls['profession'].errors?.required">
                  Campo requerido.
                </div>
            </div>
          </div>
          <div class="political_exposure mt-auto col-md-4">
            <label class="form-check-label" for="political_exposure">
              <input type="checkbox" class="form-check form-check-inline mr-1" id="political_exposure" value="false" formControlName="political_exposure">
              Persona politicamente expuesta.
            </label>
          </div>
        </div>

        <hr>
        <p class="font-weight-bold mb-2">Datos del domicilio</p>

        <div class="form-row">

          <div class="col-md-6">
            <div class="form-label-group">
              <label>Calle:</label>
              <input formControlName="address" type="text" class="form-control" placeholder="Calle" [ngClass]="insuranceForm.controls['address'].errors && insuranceForm.controls['address'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['address'].errors?.required">
                Ingresá la calle.
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-label-group">
              <label>Número:</label>
              <input formControlName="street_number" mask="09999" [dropSpecialCharacters]="true" type="text" class="form-control" placeholder="Número" [ngClass]="insuranceForm.controls['street_number'].errors && insuranceForm.controls['street_number'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['street_number'].errors?.required">
                Ingresá el número.
              </div>
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-label-group">
              <label>Piso:</label>
              <input formControlName="floor" type="text" maxlength="3" class="form-control" placeholder="Piso">
            </div>
          </div>

          <div class="col-md-2">
            <div class="form-label-group">
              <label>Dpto:</label>
              <input formControlName="depth" type="text" maxlength="20" class="form-control" placeholder="Departamento">
            </div>
          </div>

        </div>

        <div class="form-row">

          <div class="col-md-3 col-6">
            <div class="form-label-group">
              <label for="validationDefault01">País:</label>
              <select formControlName="country" class="custom-select" placeholder="País">
                <option value="AR">Argentina</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="form-label-group">
              <label>Provincia</label>
              <select formControlName="state" class="custom-select">
                <option *ngFor="let provincia of provincias" value="{{ provincia.value }}">{{ provincia.display }}</option>
              </select>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="form-label-group">
              <label>Ciudad:</label>
              <input formControlName="city" type="text" class="form-control" placeholder="Ciudad" [ngClass]="insuranceForm.controls['city'].errors && insuranceForm.controls['city'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['city'].errors?.required">
                Ingresá la ciudad.
              </div>
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['city'].errors?.minlength">
                La ciudad es incorrecta.
              </div>
            </div>
          </div>

          <div class="col-md-3 col-6">
            <div class="form-label-group">
              <label>Código Postal:</label>
              <input  mask="000099" [dropSpecialCharacters]="true" formControlName="postal_code" type="text" class="form-control" placeholder="Código Postal" [ngClass]="insuranceForm.controls['postal_code'].errors && insuranceForm.controls['postal_code'].touched ? 'is-invalid' : ''">
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['postal_code'].errors?.required">
                Ingresá el número.
              </div>
              <div class="invalid-feedback" *ngIf="insuranceForm.controls['postal_code'].errors?.minlength">
                Código postal no válido.
              </div>
            </div>
          </div>

        </div>

        <hr>
        <p class="font-weight-bold mb-2">Detalles del pago</p>

        <div class="form-row">

          <div class="alert alert-info text-left" role="alert">
            <small><b>Aviso:</b> si pagas con débito esta póliza no se emitirá a menos que cargues CBU como medio de pago</small>
          </div>

          <div class="col-md-8">

            <mat-radio-group aria-label="Seleccione una opcion" formControlName='option'>
              <mat-radio-button value="card_number">Usar mi tarjeta</mat-radio-button>
              <mat-radio-button value="cbu"class="ml-4">CBU</mat-radio-button>
            </mat-radio-group>

            <div class="form-label-group">
              <ng-container *ngIf="insuranceForm.controls['option'].value === 'cbu'">
                <label>Número de CBU</label>
                <input formControlName="cbu"
                       type="text"
                       class="form-control"
                       placeholder="Ingresa tu número de cbu"
                       mask="0000000000000000000000"
                       [dropSpecialCharacters]="true"
                       [ngClass]="insuranceForm.controls['cbu'].errors ? 'is-invalid' : ''">

                <div class="invalid-feedback" *ngIf="insuranceForm.controls['cbu'].errors?.minlength">
                  Ingresá el cbu correcto.
                </div>

                <div class="invalid-feedback" *ngIf="insuranceForm.controls['cbu'].errors?.required">
                  El cbu es requerido.
                </div>

              </ng-container>
            </div>
          </div>

        </div>

      <hr>

      <div class="form-row py-2 mt-2">
        <div class="col-md-3 text-center my-2">
          <h6 class="font-weight-bold small">N° de Agente Institorio del Banco Nación:</h6>
          <p class="mt-1">N° 27</p>
        </div>

        <div class="col-md-3 text-center my-2">
          <h6 class="font-weight-bold small">Atención al asegurado</h6>
          <p class="mt-1">0800-666-8400</p>
        </div>

        <div class="col-md-3 text-center my-2">
          <h6 class="font-weight-bold"><a href="www.argentina.gob.ar/ssn">www.argentina.gob.ar/ssn</a></h6>
        </div>

        <div class="col-md-3 my-2">
          <img class="d-block mx-auto px-3 w-100" src="/assets/stores/current_store/images/SSN-logo.png" alt="ssn-logo">
        </div>
      </div>



      </form>

      <hr>
      <!--Submit Button-->
      <div class="mt-4">
          <div class="alert alert-danger text-left" role="alert" *ngIf="invalidForm">
            <p class="alert-heading mb-2 font-weight-bold">Revisa los datos ingresados.</p>
            <small>El formulario de solicitud no es válido por favor revisa los datos ingresados para continuar con el proceso.</small>
          </div>
          <button class="btn btn-sm btn-primary px-5" (click)="submitInsuranceForm(insuranceStepper)" [disabled]="menorEdad">Asegurar mi producto</button>
          <!--<button class="btn btn-sm btn-outline-primary ml-2 px-5" (click)="closeDialog()">Cancelar</button>-->
      </div>

    </mat-step>

    <mat-step>
      <div id="procesando col-12">
        <div class="procesando" *ngIf="processData">
          <div class="col-12 py-4 px-4 text-center">
            <h4 class="my-2">Su operación está<br>siendo procesada</h4>
            <mat-spinner class="my-5 m-auto"></mat-spinner>
          </div>
        </div>

        <div *ngIf="success && !processData">
          <div class="col-12 p-4 text-center procesando">
            <h4 class="my-2">Transacción exitosa</h4>
            <table class="table table-sm text-left">
                <tbody>
                  <tr>
                    <th>Id de Orden Asociada:</th>
                    <td>{{ order_id }}</td>
                  </tr>
                  <tr>
                    <th>Producto Asegurado:</th>
                    <td>{{ item }}</td>
                  </tr>
                  <tr>
                    <th>Monto:</th>
                    <td>${{ floatParse(insuranceCost) }} por mes. No incluye sellos.</td>
                  </tr>
                </tbody>
            </table>
          </div>
            <!-- Button -->
          <button class="btn btn-sm m-auto btn-primary px-5"  [routerLink]="['/']">CONTINUAR NAVEGANDO</button>
        </div>

        <div *ngIf="!success && !processData">
          <div class="col-12 py-4 px-4 text-center procesando">
            <h4 class="mx-auto py-4">{{error}}</h4>
          </div>

          <!-- Button -->
          <button class="btn btn-sm m-auto btn-primary px-5" matStepperPrevious>Reintentar</button>
        </div>
      </div>
    </mat-step>

  </mat-horizontal-stepper>
</ng-template>
<ng-template #invalid>
  <div class="message">
    <h6>{{ message }}</h6>
  </div>
</ng-template>
