import { Injectable, OnInit } from '@angular/core';
//Services
import { HttpService } from './http.service';
import { Observable } from 'rxjs';

@Injectable()
export class ProgramaVolarService implements OnInit {

  constructor(
    private http: HttpService) { }

  ngOnInit(): void {}

  getAirlines(): Observable<any> {
    return this.http.get('stores/airlines_equivalences');
  }

  getClient(params): Observable<any> {
    console.log('parametro', params.airline_id);
    return this.http.post(`integration/airlines_frequent_travelers/${params.airline_id}`, {
      document_type: params.docType,
      document_number: params.docNumber,
    });
  }

  acredit(params): Observable<any> {
    let ariline_object = {
      document_type: params.docType,
      document_number: params.docNumber,
      membership_number: params.membership_number,
      points: params.points,
      email: params.email,
    }
    return this.http.post(`integration/airlines_points_exchange/${params.airline_id}`, {exchange: ariline_object});
  }


}
