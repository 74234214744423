<div class="content" *ngIf="store == 'BancoNacion'">
  <!-- Accodion FAQs -->
  <div class="title">
    <p>
      AGENTE INSTITORIO: BANCO DE LA NACIÓN ARGENTINA - RAI N° 27
    </p>
  </div>

  <p>&nbsp;</p>

  <div class="row">
    <img src="/assets/stores/current_store/images/seguros.png" alt="seguros">
  </div>

  <div class="card">
      <div class="card-body p-0">
        <p>
          SEGURO DISPONIBLE ÚNICAMENTE PARA CELULARES COMPRADOS A TRAVÉS DE TIENDABNA.COM.AR. LAS CONDICIONES AQUÍ REFERIDAS SON UNA BREVE DESCRIPCIÓN DEL PRODUCTO  Y ESTÁN SUJETAS A LAS CONDICIONES, LIMITACIONES, MODALIDADES, ACEPTACIÓN, EXCLUSIONES Y TÉRMINOS DE LA PÓLIZA DE SEGURO QUE SE CONTRATE.  AGENTE INSTITORIO: BANCO DE LA NACIÓN ARGENTINA - RAI N° 27. ​COMPAÑÍA ASEGURADORA: NACIÓN SEGUROS S.A. CUIT 30-67856116-5. SAN MARTÍN 913 CABA – N° SSN: 0515. SUPERINTENDENCIA DE SEGUROS DE LA NACIÓN WWW.SSN.GOB.AR / 0800-666-8400 Ó 4338-4000.
        </p>
      </div>
  </div>
</div>
