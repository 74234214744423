import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BigLoaderComponent} from "../app/layout/big-loader/big-loader.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    CommonModule,
    MatProgressSpinnerModule,
    BigLoaderComponent,
  ],
  declarations: [
    BigLoaderComponent
  ]
})

export class BigloaderModule { }
