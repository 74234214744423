import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LazyLoadImageModule } from "ng-lazyload-image";
import { QuicklinkModule } from 'ngx-quicklink';
import { ShareModule } from 'ngx-sharebuttons';
import { NgxGalleryModule } from "gallery";



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LazyLoadImageModule,
    QuicklinkModule,
    ShareModule,
    NgxGalleryModule
  ],
  exports: [
    LazyLoadImageModule,
    QuicklinkModule,
    ShareModule,
    NgxGalleryModule
  ]
})
export class CoreModule { }
