import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QualtricsService  {
    setQualtricsScriptTag() {
        const div = document.createElement('div');
        div.id = 'ZN_aWCT3cj1JMSnjLw';
        document.head.append(div);

        const script = document.createElement('script');
        script.src = '../assets/javascript/qualtrics.js';
        document.head.append(script);
    };

    runInterceptor() {
        console.log('corriendo interceptor');
        if (window['QSI'].API) {
            window['QSI'].API.unload();
            window['QSI'].API.load().done(window['QSI'].API.run);
        }
    }
}
