import { Pipe, PipeTransform } from '@angular/core';
import { PriceService } from '../../services/price.service';

@Pipe({
  name: 'floatparse',
  pure: true,
})
export class FloatParsePipe implements PipeTransform {
  transform(value: any, arg: any): any {
    if (value < 0) value = 0;
    return PriceService.humanize(value);
  }

}
