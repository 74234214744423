export class TaxesDecorator {
  constructor(public show?: boolean,
              public cft?: number,
              public tea?: number,
              public amount?: number,
              public installments?: number,
              public percentage_fee?: number,
              public coef?: number) { }

  realDiscount(total: number) {
    if (this.amount === null) {
      return "0.00";
    }
    return Math.abs( Math.ceil(this.amount) - total);
  }

  discountCashPayment(total: number){
    if (this.amount === null) {
      return false;
    }
    let realDiscount = this.amount - total;

    if (realDiscount > 0){
      return false;
    }
    return Math.abs(realDiscount);
  }

  cashPayment(total: number){
    if (this.amount === null) {
      return false;
    }
    return this.amount;
  }

  parsedAmount() {
    if (this.amount === null) {
      return null;
    }

    return Math.ceil(this.amount);
  }

}
