<div class="container">
  <div class="links-cmpt" *ngIf="component">
    <section>
      <ul *ngFor="let item of component.data.items">
        <li>
          <h2>{{ item.title }}</h2>
        </li>
        <li *ngFor="let subItem of item.sub_items">
          <a class="pointer" (click)="navigate(subItem.link)">{{ subItem.text }}</a>
        </li>
      </ul>
    </section>
  </div>
</div>
