import { Injectable } from '@angular/core';
import {HttpService} from './http.service';
import {ModelService} from './model.service';
import {RelationService} from './model.service';
import {Menu} from '../models/models.model';
import { Observable } from 'rxjs';

@Injectable()

export class MenusService extends ModelService<Menu> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'menus', Menu);
  }

  getMenuTopbar(): Observable<any>{
    return this.injHttp.get('menus/sticky_navbars');
  }

}
