import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Sitemap } from '../../models/models.model';
import { UrlService } from "../../services/url.service";
import { SitemapsService } from '../../services/sitemaps.service'
import { MktService } from '../new-core/core/services/mkt.service';

@Component({
  selector: 'sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.sass']
})

export class SitemapComponent implements OnInit {
  SitemapLink: Sitemap[] = [];

  constructor(
    private sitemapsService: SitemapsService,
    private urlService: UrlService,
    private _mktService: MktService
  ) { }

  ngOnInit() {
    this._mktService.setMetaTags('Mapa del sitio', {name: "description", content: "Mapa del sitio"});
    this.sitemapsService.index().subscribe(
      (all) => {
        this.SitemapLink = all;
      }
    );
  }
}
