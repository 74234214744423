import { Component, Input, OnInit } from '@angular/core';
import { PriceService } from '../../../../../services/price.service';
import { Router } from "@angular/router";
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-suggestion-bar',
  templateUrl: './suggestion.component.html',
  providers: [PriceService]
})
export class SuggestionComponent implements OnInit  {
  @Input('suggestions') suggestions: any;
  @Input('searchText') searchText: string;
  store = environment.name;
  env = environment

  constructor ( private router: Router ,
                private priceService: PriceService
              ) { }

  ngOnInit() {}

  clearSuggestion() {
    this.suggestions = null;
  }

  limitTo(data, count){
    return data.filter((item, index) => index <= count )
  }

  floatParse(attr) {
    return PriceService.humanize(attr);
  }

  searchCatalog() {
    this.router.navigate(['/catalog'], {queryParams: {query: this.suggestions.query}});
    this.clearSuggestion();
  }

  showPrice(option) {
    switch(option.product.transaction_type) {
      case 'voucher':
      case 'points':
      case 'cashback':
      case 'other': {
        return false;
        break;
      }
      default: {
        return true;
      }
    }
  }
}
