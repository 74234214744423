import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../services/http.service";
import {ActivatedRoute, Params, Router} from "@angular/router";

@Component({
  selector: 'app-legacy',
  templateUrl: './legacy.component.html',
})
export class LegacyComponent implements OnInit {

  constructor(private http: HttpService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        let path = params['legacy'];

        if (params['old'] !== undefined) {
          path += '/' + params['old'];
        }

        this.http.post('legacy', {path: path}).subscribe(
          (response) => {

            if (response['nothing_to_do'] || (response['controller'] === "mkp/catalog" && response['slug'] === "products") ) {
              this.router.navigate(['/catalog']);
            }

            let to = this.controllable(response['controller']);
            this.router.navigate([to.replace(":replace", response['slug'])]);
          },
          (error) => {
            this.router.navigate(["/"])
          }
        )
      }
    );
  }

  controllable(controller) {
    const constants = {
      'mkp/catalog': '/catalog/:replace',
      'mkp/products': '/products/:replace',
      'mkp/brands': '/brands/:replace'
    };

    if (constants[controller]) {
      return constants[controller];
    }

    return '/catalog';
  }
}
