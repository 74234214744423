import {Injectable} from '@angular/core';
import {HttpService} from '../http.service';
import {ModelService} from '../model.service';
import {RelationService} from '../model.service';
import {Address} from "../../models/models.model";

@Injectable()

export class AddressesService extends ModelService<Address> {
  constructor(private injHttp: HttpService, private injRelations: RelationService) {
    super(injHttp, injRelations, 'my_account/addresses', Address);
  }
}
