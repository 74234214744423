



<main>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6">
        <img class="d-block mx-auto" src="{{ offer.image }}" alt="offer image">
      </div>
      <div class="col-12 col-md-6 col-lg-6">
        <h3 class="pb-3 offer-title">{{ offer.name }}</h3>

        <p class="pb-3" id="description">{{ description ? description : '' }}</p>

        <div class="row">
          <div class="col-12 col-md-8 pb-3">
            <h4 class="offer-points" *ngIf="pointsEquivalence">Puntos: {{ floatParse(ceilPoints(offer.price_detail.total)) }}</h4>
          </div>
          <div class="col-12 col-md-4 pb-3">
            <button class="btn btn-primary btn-block btn-sm"(click)="goDespegar()">Acceder</button>
          </div>
        </div>

        <hr>
        <h6 class="mb-3">¡VIAJÁ CON MACRO PREMIA!</h6>
        <p class="mb-3" style="font-size:0.8em; line-height: 22px;">
          Elegí el premio que querés y canjéalo por Puntos o Puntos + Pesos con tus tarjetas de crédito Visa, MasterCard y American Express.
        </p>

        <h5 class="mb-3">¿Cómo canjear?</h5>

        <p style="font-size:0.8em; line-height: 22px;">
          Ingresá a <b><a href="https://www.macro.com.ar/bancainternet/#" style="color:darkslateblue">Banca Internet</a></b>
          <br>
          Accedé a “Macro Premia” y seleccioná “Canjeá tus Puntos en <img id="despegar-logo" src="/assets/stores/current_store/images/despegar.jpg" alt="Despegar.com"/>”
          <br>
          Canjeá tu premio por Puntos o Puntos + Pesos con tus tarjetas de crédito Visa, MasterCard y American Express.
        </p>
      </div>

      <!-- Legal Row -->
      <div class="col-12 text-justify">
        <hr>
        <p><small class="text-muted">Oferta vigente en el día de la fecha. Sujeta a variación en función a la elección de las condiciones de vuelo, hotel, paquete, actividad, o alquiler de auto seleccionado. Aéreos, hoteles, paquetes, actividades y alquiler de autos son ofrecidos por “DESPEGAR.COM”. Con Tarjetas de Crédito de Banco Macro, únicamente a través de Macro Premia / Despegar. Para acceder a la oferta en modalidad Pesos deberá abonar la suma en pesos indicada con las tarjetas de crédito de Banco Macro, no siendo requisito contar con Puntos Macro Premia disponibles para concretar la compra. “DESPEGAR.COM”: CUIT 30701307115, Av. Jujuy 2013 CABA- Leg evt 10680 - Disp 0599/2000. No válido para Tarjetas de Crédito de Empresa y/o Agro. No acumula con otras promociones. Los establecimientos, los productos y/o servicios que comercializan o la calidad de los mismos no son promocionados ni garantizados por Banco Macro.<br> Consulte más información, en <a href="https://macro.com.ar">macro.com.ar</a>, llamando al 0810-555-2355 o en la Sucursal más cercana a su domicilio.</small></p>
      </div>
      <!-- End Legal Row -->
    </div>
  </div>

  <mat-progress-spinner
    *ngIf="!isLoaded"
    color="accent"
    mode="indeterminate">
  </mat-progress-spinner>
</main>
