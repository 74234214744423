import { Component, OnInit, OnDestroy} from '@angular/core';
import {ComponentLanding} from "../../../../../models/models.model";
import {UrlService} from "../../../../../services/url.service";
import { Subscription } from 'rxjs';
import { WindowService } from '../../../../../services/window.service';

@Component({
  selector: 'app-cols5-generic-v1',
  templateUrl: './cols5-generic-v1.component.html'
})
export class Cols5GenericV1Component implements OnInit, OnDestroy {
  component: ComponentLanding;
  windowWidth: number;
  uniqueId: any;

  private resizeSubscription: Subscription;

  static acceptsComponent(cmp: ComponentLanding ): boolean {
    return cmp.type === 'cols5_generic_v1';
  }

  constructor(private urlService: UrlService,
              private windowService: WindowService) { }

  ngOnInit() {
    this.uniqueId = 'cols5-carousel-' + Math.random().toString(36).substring(2);
    this.resizeSubscription = this.windowService.onResize$.subscribe((value: any) => {
      this.windowWidth = value;
    }); 
  }

  ngOnDestroy() {
    if (this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
    }
  }

  navigate(link) {
    this.urlService.navigate(link);
  }
}
