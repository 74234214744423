<div #searcherContainer id="container" itemprop="potentialAction" itemscope="" itemtype="http://schema.org/SearchAction" method="get" (mouseleave)="hideSuggestions()">
  <input
    itemprop="query-input"
    name="query"
    placeholder="Buscar..."
    required=""
    type="search"
    class="tt-input"
    autocomplete="off"
    spellcheck="false"
    dir="auto"
    #searcher
    (keyup.enter)="searchCatalog()"><!--
    (input)="searchSuggestions()"
    (mouseup)="searchSuggestions()">-->
  <button title="Buscar" type="submit" (click)="searchCatalog()"><i class="fe fe-search" aria-hidden="true"></i></button>
  <!--<app-suggestion-bar *ngIf="suggestions" [suggestions]="suggestions" ></app-suggestion-bar>-->
</div>
