<div class="gray-color override-with-white-color" id="celulares-component">
  <div class="container pt-4 pb-5">

    <div class="row">
      <div class="col-md-12">
        <h3>Recarga de celular</h3>
        <hr>
      </div>
    </div>

    <div class="row">

      <div class="col-md-7">
        <img src="/assets/stores/current_store/images/img-recarg.png" class="img-fluid mb-3 mx-auto d-block" alt="recarga-celulares">
      </div>

      <div class="col-md-5">

        <div id="celularesCard">

            <!-- Error Feedback -->
            <div *ngIf="formInvalid" class="alert alert-danger mt-3 mb-2" role="alert">
              <h6 class="alert-heading">Datos incorrectos</h6>
              <small>Verifica los datos ingresados e intentalo nuevamente.</small>
            </div>

            <!-- Instruction Steps -->
            <div class="steps" id="recarga-steps" *ngIf="stepsRecargas">

              <h4 class="mb-3">¿Cómo recargar el celular?</h4>

              <div class="media mb-3">
                <div class="step">1.</div>
                <div class="media-body">
                  <p>Seleccioná la compañia telefónica.</p>
                </div>
              </div>

              <div class="media mb-3">
                <div class="step">2.</div>
                <div class="media-body">
                  <p>Elegí el monto a cargar y canjeá tus puntos.</p>
                </div>
              </div>

              <div class="media mb-3">
                <div class="step">3.</div>
                <div class="media-body">
                  <p>Completá tu número de celular con el código de área y sin el 15.</p>
                </div>
              </div>

              <!-- SUBE Points / Value -->
              <div class="celulares-values mb-1 my-3" id="celulares-values" *ngIf="showPoints">
                <ul>
                  <li *ngFor="let item of amounts" class="mb-2">
                    <span class="celulares_amount">
                      <p class="amount_number">${{ item.amount | floatparse }}</p>
                      <p class="points-text">{{ math.ceil(item.amount / celularesEquivalencia) | floatparse }} Puntos</p>
                    </span>
                  </li>
                </ul>
              </div>

              <button class="btn btn-lg btn-primary btn-block mt-4" (click)="comenzarRecarga()">Continuar</button>

            </div>

            <!-- Recarga Celulares Form -->
            <form *ngIf="formRecarga" class="mt-3" [formGroup]="recargaCelularesForm" (ngSubmit)="canjearPuntos()">
              <!-- Form Header -->
              <h4 class="mb-3" *ngIf="!processData">{{ cardTitle }}</h4>
              <!--<div *ngIf="!isLoggedIn" class="alert alert-danger" role="alert">
                Inicia sesión para obtener este beneficio.
              </div>-->
              <!-- Input Company -->
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Selecciona tu compañía de teléfono</mat-label>
                <mat-select placeholder="Seleccionar compañia" formControlName="company">
                  <mat-option disabled>Seleccionar compañia</mat-option>
                  <mat-option *ngFor="let company of companies" [value]="company.value">
                    {{company.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Input Amount -->
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Elegí el monto a cargar</mat-label>
                <mat-select placeholder="Seleccionar monto" formControlName="amount">
                  <mat-option disabled>Selecciona el monto</mat-option>
                  <mat-option *ngFor="let amount of amounts" [value]="amount.amount" [disabled]="esCanjeable(amount.points)">
                    <b>${{ amount.amount | floatparse }}</b> = {{ math.ceil(amount.amount / celularesEquivalencia) | floatparse }} Puntos
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Input Mail -->
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Correo Electrónico</mat-label>
                <input  matInput formControlName="email"
                        placeholder="usuario@dominio.com">
              </mat-form-field>

              <p class="mb-2 pl-1">
                <small>Completa tu número de celular con el código de área y sin el 15.</small>
              </p>

              <!-- Input Phone Code -->
              <mat-form-field appearance="outline" class="mr-2" style="width: 80px;">
                <mat-label>Área</mat-label>
                <input  type="text" matInput placeholder="(11)"
                        mask="(0099" suffix=")" [dropSpecialCharacters]="true"
                        formControlName="phonecode">
              </mat-form-field>

              <!-- Input Phone -->
              <mat-form-field appearance="outline">
                <mat-label>Número de teléfono</mat-label>
                <input  type="text" matInput placeholder="5555-9898"
                        [dropSpecialCharacters]="true" mask="0000-009999"
                        formControlName="phone">
              </mat-form-field>

              <!-- Submt Button -->
              <button class="btn btn-lg btn-primary btn-block float-right" type="submit">Confirmar</button>

            </form>
            <!-- End Form -->


            <!-- Confirmar Datos -->
            <div id="confirmarDatos" *ngIf="confirmData">

              <div class="col-12 py-4">
                <h5>{{ pointsSpent | floatparse }} puntos Macro Premia por recarga de ${{ recargaData.amount | floatparse }}</h5>
                <h6>Acreditados al siguiente número:</h6>

                <h5>Nombre y Apellido</h5>
                <p>{{ recargaData.name }}</p>

                <h5>Número de celular</h5>
                <p>{{ recargaData.company }} ({{ recargaData.phone_code }}) {{ recargaData.phone | mask: '0000-0000' }}</p>

                <h5>E-mail</h5>
                <p>{{ recargaData.email }}</p>
              </div>

              <button class="btn btn-lg btn-primary btn-block" (click)="confirmarCanje()">Confirmar</button>
              <button class="btn btn-sm btn-link btn-block" (click)="cancelarCanje(false)">Volver</button>
            </div>

            <!-- Procesar Canje -->
            <div id="procesoCanje" *ngIf="processData">
                <div class="col-12 p-4 text-center procesando">
                  <h4 class="my-2">{{ statusRecarga.msg }}</h4>
                  <h6 *ngIf="statusRecarga.details" class="mx-auto mb-3">{{ statusRecarga.details }}</h6>

                  <table *ngIf="statusRecarga.status === 'success'" class="table table-sm text-left">
                    <tbody>
                      <tr>
                        <th>Nombre y apellido:</th>
                        <td>{{recargaData.name}}</td>
                      </tr>
                      <tr>
                        <th>E-mail:</th>
                        <td>{{recargaData.email}}</td>
                      </tr>
                      <tr>
                        <th>Número de Celular:</th>
                        <td>{{recargaData.phone_code}} - {{recargaData.phone}}</td>
                      </tr>
                      <tr>
                        <th>Fecha / Hora:</th>
                        <td>{{ fechaOperacion | amLocale:'es' | amDateFormat:'LL, h:mm:ss a' }}</td>
                      </tr>
                      <tr>
                        <th>Código de operación:</th>
                        <td>{{codigoOperacion}}</td>
                      </tr>
                    </tbody>
                  </table>

                  <mat-spinner class="mx-auto my-5" *ngIf="statusRecarga.loader"></mat-spinner>
                </div>
                <div class="mt-3">
                  <a *ngIf="statusRecarga.status === 'success'" class="btn btn-lg btn-primary btn-block" [routerLink]="['/']">CONTINUAR NAVEGANDO</a>
                  <button *ngIf="statusRecarga.status === 'failed'" class="btn btn-lg btn-primary btn-block" (click)="cancelarCanje(false)">Reintentar</button>
                  <button *ngIf="statusRecarga.status === 'failed'" class="btn btn-sm btn-link btn-seccondary btn-block" (click)="cancelarCanje(true)">Cancelar recarga</button>
                </div>
            </div>

          <!-- Card Footer -->
          <div class="card-footer" *ngIf="statusRecarga.status === 'success'">
            <small class="text-muted mt-5">
              Recibirás un SMS de confirmación en los próximos 30 minutos de realizada la recarga.
            </small>
          </div>

        </div> <!-- End Card -->

      </div><!-- End Col -->
    </div> <!-- End Row -->
    <!-- Legal Row -->
      <div class="mt-3">
        <p class="text-justify format-p"><small class="text-muted">Al momento de generar la solicitud, deberás ingresar tu email, el prefijo sin 0 y tu número de celular sin 15. Recibirás un SMS de confirmación en los próximos 30 minutos de realizada la recarga. Tu recarga tendrá una vigencia de 30 días corridos desde el momento que la realices. Exclusivo para planes con tarjeta o abono fijo. La solicitud de este premio no admite cambios ni devolución de puntos.<br>Consulte más información, en macro.com.ar, llamando al 0810-555-2355 o en la Sucursal más cercana a su domicilio.</small></p>
      </div>
    <!-- End Legal Row -->
  </div> <!-- End Container -->
</div>
