import {Injectable} from '@angular/core';
import {HttpService} from '../http.service';
import {Order} from "../../models/models.model";
import {RelationService} from '../model.service';
import * as lodash from 'lodash-es';
import { map } from 'rxjs/operators';

@Injectable()

export class AccountOrdersService {
  orders: Order[] = [];

  constructor(private http: HttpService,
              private relationService: RelationService) {}

  index(params?) {
    return this.http.get('my_account/orders', params).pipe(
      map(
        (response) => {
            this.orders = [];
            lodash.each(response.orders, (order) => {
              const otherOrder = this.relationService.parse(order, Order);
              this.orders.push(otherOrder);
            });
            const res = {
              orders: this.orders,
              pagination: response.pagination
            }
            return res;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  show(order: Order) {
    return this.http.get('my_account/orders', { id: order.id });
  }
}
