<!-- Recarga Celulares Form -->
<form class="mt-3" [formGroup]="geolocationForm">
  <!-- Form Header -->
  <h6 class="mb-4">Tu ubicación nos ayudará a darte una mejor experiencia de compra.</h6>
  <p class="mb-4 text-info" *ngIf="data.error"><i class="fe fe-alert-triangle align-bottom mr-2"></i>No hemos podido encontrar tu ubicación, por favor selecciona una de la lista.</p>

  <!-- Input Provincia -->
  <div>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Selecciona la provincia</mat-label>
    <mat-select placeholder="Seleccionar provincia" formControlName="provincia"
                (selectionChange)="setLocadidades($event.value)">
      <mat-option disabled>Seleccionar compañia</mat-option>
      <mat-option *ngFor="let provincia of provincias" [value]="provincia">
        {{provincia}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>
  
  <!-- Input Localidad -->
  <div>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Selecciona la localidad</mat-label>
    <mat-select placeholder="Seleccionar localidad" formControlName="localidad">
      <mat-option disabled>Seleccionar localidad</mat-option>
      <mat-option *ngFor="let localidad of localidades" [value]="localidad">
        {{localidad}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  </div>

  <!-- Input Mail -->
  <div class="mb-4" *ngIf="!data.error">
    <mat-slide-toggle formControlName="browserLocate">
      Usar mi ubicación actual
    </mat-slide-toggle>
  </div>
  
  <!-- Submt Button -->
  <button class="btn btn-primary btn-block float-right" (click)="confirmGeolocation()">Seleccionar ubicación</button>

</form> 
<!-- End Form -->
